import pkg from '../../../package.json'
export const state = () => ({
  version: pkg['version'],
  majorversion: pkg['major-version'],
  minorversion: pkg['minor-version'],
  patchversion: pkg['patch-version'],
})

export const getters = {
  getAppVersion: (state) => state.version,
  getMajorVersion: (state) => state.majorversion,
  getMinorVersion: (state) => state.minorversion,
  getPatchVersion: (state) => state.patchversion
}
