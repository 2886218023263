import axios from '../../client/axios'
import { BASE_URL_API_CHECKIN } from '../../settings'

/**
 * This method changes the trip status to Stoping checkin
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.params - The request query params {
 *  itineraryId: integer
 *  fromCode: string
 * }
 * @property { String } params.token - The access token for authorization
 */
export const apiStopCheckin = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/StopCheckin',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)
