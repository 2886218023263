import axios from '../../client/axios'
import { BASE_URL_API_B2B_SETTINGS, NODE_URL , RELEASE_NOTES } from '../../settings'


export const configurations = ({ token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_B2B_SETTINGS,
    url: '/agency-user-interface-setting',
    headers: token
  }).then(res => res.data)
}

export const releaseNote = () => {
  return axios({
    method: 'GET',
    baseURL: RELEASE_NOTES,
  }).then(res => {
    return res
  })
}


export const storeSettings = ({ data, token }) => {
  return axios({
    method: 'PUT',
    baseURL: BASE_URL_API_B2B_SETTINGS,
    url: '/agency-user-interface-setting',
    headers: token,
    data
  }).then(res => res.data)
}


export const companyRedirections = ({ data, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_B2B_SETTINGS,
    url: '/company-redirection',
    headers: token,
    data
  }).then(res => res.data)
}
