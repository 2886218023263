import {token} from '../helpers/book-ticket'
import {
  // updateOrCreateListItemBy,
  // updateOrCreateListItemByIndex,
  // addToList,
  // removeFromListBy,
  // get,
  getOr,
  // existInListBy,
  set
  // updateOrSet,
  // findOneBy //,
  // setListItemPath
} from '../helpers/vuex'
import {
  apiRetrieveBookings,
  apiRetrieveBookingsWithParams,
  apiRetrieveGroupBookings,
  apiRetrieveOptionalBookings,
  apiConvertBookingToOpen,
  apiBookingCancel,
  issueBooking,
  apiRetrieveBookingsWithCriteria,
  accommodationRequestAnalysises,
  apiReplaceOpenTicketsByOptionalBooking,
} from '../api/requests/b2b-service/booking'
import {
  validateManCode,
} from '../api/requests/b2b-service/trips'
import { pipeP } from '../helpers/general'
import { log } from '~/helpers/log'

const initialState = () => ({
  searchOptions: {},
  reservations: [],
  searhedTicket: ''
})

export const state = () => initialState()

export const getters = {
  getSearchedTicket: getOr('searhedTicket', []),
  getReservations: getOr('reservations', [])
}

export const mutations = {
  SET_RESERVATIONS: set('reservations'),
  SET_SEARCH_OPTIONS: set('searchOptions'),
  SET_SEARCHED_TICKET: set('searhedTicket'),
  RESET_STATE(state, payload) {
    Object.assign(state, payload || initialState())
  }
}

export const actions = {
  resetRecallState({ commit }) {
    commit('RESET_STATE')
  },
  getBookings({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookings(data)
    const tap = data => {
      // commit('SET_RESERVATIONS', data)
      commit('booking-recall/SET_BOOKING_RECALL', data[0], { root: true })
      // commit('SET_SEARCHED_TICKET', payload.ticketNumber)
      return data
    }
    const handleError = er => {
      console.log('error getting bookings: ', er)
      return []
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload).catch(handleError)
  },
  getBookingsWithoutRecall({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookings(data)
    const tap = data => data
    const handleError = er => {
      console.log('error getting bookings: ', er)
      return []
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload).catch(handleError)
  },
  getOptionalBookings({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveOptionalBookings(data)
    const tap = data => {
      commit('SET_RESERVATIONS', data)
      commit('SET_SEARCHED_TICKET', payload.ticketNumber)
      return data
    }
    const handleError = er => {
      console.log('error getting bookings: ', er)
      return []
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload).catch(handleError)
  },
  issueBooking({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const apiCall = data => issueBooking(data)
    const tap = data => {
      commit('booking-recall/SET_BOOKING_RECALL', data, { root: true })
      return data
    }
    const handleError = er => {
      console.log('error getting bookings: ', er)
      return []
    }
    const handleAction = pipeP(createRequestParams, apiCall, tap)
    return handleAction(payload).catch(handleError)
  },
  getSpecificBookingById({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookings(data)
    const tap = data => {
      commit('booking-recall/SET_BOOKING_RECALL', data[0], { root: true })
      return data
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload)
  },

  getSpecificBookingByParams({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookingsWithParams(data)
    const tap = data => {
      if(data.length) commit('booking-recall/SET_BOOKING_RECALL', data[0], { root: true })
      return data
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload)
  },

  getSpecificBookingByCriteria({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookingsWithCriteria(data)
    const tap = data => {
      if(data.length) commit('booking-recall/SET_BOOKING_RECALL', Array.isArray(data) ? data[0] : data, { root: true })
      return data
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload)
  },

  getSpecificGroupBooking({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveGroupBookings(data)
    const tap = data => {
      // commit('booking-recall/SET_BOOKING_RECALL', data[0], { root: true })
      return data
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload)
  },
  getSpecificBookingByIdOnMerge({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const getDataFromApi = data => apiRetrieveBookings(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, getDataFromApi, tap)
    return handleAction(payload)
  },
  convertBookingToOpen({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiConvertBookingToOpen(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },
  bookingCancel({ commit, rootGetters }, payload) {
    console.log(payload)
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiBookingCancel(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },
  validateMan({ rootGetters, state, commit, getters, dispatch }, payload) {
    const { printOnlyReceipt, openReturn, params, data, residentIdOrCode, tripIndex, index } = payload || {}
    const trips = JSON.parse(JSON.stringify(data.trips))
    trips.forEach(trip => trip.accommodationRequests = trip.passengers.map((p, pI)=> {
      console.log(index, pI)
      if(pI === index){
        return {
          idOrCode: p.accommodation.idOrCode,
          // accommodationRequestType: passIncludedInGroup(selectedPassenger) ? 'COMPLETE' : accommodationType(selectedPassenger),
          bedType: 'NO_PREFERENCE',
          quantity: 1,
          accommodationRequestAnalysises: trip.passengers.map((selectedPassenger, i) => {
            if(index === i) {
              return {
                index: i + 1,
                // discountIdOrCode: discountIdOrCode(selectedPassenger),
                // loyaltyCard: loyaltyCardAttr(selectedPassenger),
                passengerData: {
                  residentIdOrCode
                }
              }
            }
          }).filter(a => a)
        }
      }
    }).filter(a => a))
    const requestData = () => {
      const d = {
        // fareIdOrCode: data.fareIdOrCode,
        trips: trips.map((trip, i) => {
          if(i === tripIndex){
            return {
              origin: trip.origin,
              destination: trip.destination,
              vessel: trip.vessel,
              accommodationRequests: trip.accommodationRequests,
              departureDateTime: trip.departureDateTime,
              arrivalDateTime: trip.arrivalDateTime,
              duration: trip.durationduration,
              companyReservationCode: trip.companyReservationCode,
              optionDateTime: trip.optionDateTime
            }
          }
        }),
        // printOnlyReceipt,
      }
      return {
        params,
        data: d,
        token: token(rootGetters),
      }
    }
    const handle = pipeP(requestData, validateManCode)
    return handle()
  },
  replaceOpenTickets({ rootGetters, state, commit, getters, dispatch }, payload) {
    const accommodationRequestAnalysises = pass => {
      const passengers = Array.isArray(pass) ? pass : [pass]
      return passengers.map((selectedPassenger, index) => {
        const {sex, ssr, discount,loyaltyNumber,price,accommodation,ticketLetter,ticketNumber, ...rest} = selectedPassenger
        return {
          index: index + 1,
          // discountIdOrCode: discountIdOrCode(selectedPassenger),
          passengerData: {
            ...rest,
            sexType: selectedPassenger.sex,
            documentType: "I",
            accommodationNumber: accommodation.number,
            ticket: {
              letter: ticketLetter,
              number: ticketNumber?.toString()
            }
          }
        }
      })
    }

    const accommodationRequestAnalysisesVehicles = veh => {
      const vehicles = Array.isArray(veh) ? veh : [veh]
      return vehicles.map((selectedVehicle, index) => {
        const {ticketLetter,ticketNumber, ...rest} = selectedVehicle
        return {
          index: index + 1,
          // discountIdOrCode: discountIdOrCode(selectedVehicle),
          vehicleData: {
            ...rest,
            ticket: {
              letter: ticketLetter,
              number: ticketNumber?.toString()
            }
          }
        }
      })
    }
    const accommodationType = (selectedPassenger) => {
      if(selectedPassenger.sex) return `${selectedPassenger.sex}_BERTH`
    }
    const { printOnlyReceipt, openReturn, params, data } = payload || {}
    const trips = JSON.parse(JSON.stringify(data.trips))
    const requestData = () => {
      const d = {
        fareIdOrCode: data.fareIdOrCode,
        leader: data.leader,
        trips: trips.map((trip) => {
          const passengerAcc = trip.passengers.map(p => {
            return {
              idOrCode: p.accommodation.idOrCode,
              accommodationRequestType: accommodationType(p),
              bedType: 'NO_PREFERENCE',
              quantity: 1,
              accommodationRequestAnalysises: accommodationRequestAnalysises(p)
            }
          })
          const vehicleAcc = trip.vehicles.map(v => {
            return {
              idOrCode: v.accommodation.idOrCode,
              accommodationRequestType: 'VEHICLE',
              quantity: 1,
              accommodationRequestAnalysises: accommodationRequestAnalysisesVehicles(v)
            }
          })
          return {
            origin: trip.origin,
            destination: trip.destination,
            vessel: trip.vessel,
            accommodationRequests: [...passengerAcc, ...vehicleAcc],
            departureDateTime: trip.departureDateTime,
            arrivalDateTime: trip.arrivalDateTime,
            duration: trip.durationduration,
            companyReservationCode: trip.companyReservationCode,
            optionDateTime: trip.optionDateTime
          }
        }),
        printOnlyReceipt,
      }
      return {
        params,
        data: d,
        token: token(rootGetters),
      }
    }
    const handle = pipeP(requestData, apiReplaceOpenTicketsByOptionalBooking,/*  log('replace-open-tickets'), saveData */)
    return handle()
  },
}