import path from 'ramda/src/path'
import curry from 'ramda/src/curry'
import ifElse from 'ramda/src/ifElse'

export const isNumeric = v => /^\d+$/.test(v)

export const isObject = v => v && typeof v === 'object'

export const tripsAreTheSame = curry((trip1, trip2) => {
  const arrivalDateTime = path(['arrivalDateTime'])
  const departureDateTime = path(['departureDateTime'])
  const destination = path(['destination', 'idOrCode'])
  const origin = path(['origin', 'idOrCode'])
  const vessel = path(['vessel', 'idOrCode'])

  return (
    arrivalDateTime(trip1) === arrivalDateTime(trip2) &&
    departureDateTime(trip1) === departureDateTime(trip2) &&
    destination(trip1) === destination(trip2) &&
    origin(trip1) === origin(trip2) &&
    vessel(trip1) === vessel(trip2)
  )
})

/**
 * General helper to handle error on api calls
 *
 * onSuccess :: (context: Vue, ifSuccess: Function, ifError: Function) -> any -> any
 *
 * Usage:
 * apiCall()
 *  .then(onSuccess(
 *    this,
 *    res => {
 *      // do something on success
 *    },
 *    res => {
 *      // do something on error
 *      // by default show a notification with message
 *  }))
 * @param { Vue } context
 * @param { Function | undefined } ifSuccess
 * @param { Function | undefined } ifError
 * @returns { Function }
 */
export const onSuccess = (context, ifSuccess, ifError) =>
  ifElse(
    res => Boolean(res?.message || res?.Message || res?.success === false), // if has error
    typeof ifError === 'function'
      ? ifError
      : res => context.$notification.error({
        message: 'ERROR: ' + (res.code || res.Code || ''),
        description: ' ' + (res.message || res.Message),
        duration: context.getNotificationCloseStatus ? 0 : 4.5,
      }),
    typeof ifSuccess === 'function' ? ifSuccess : res => null // callback or do nothing
  )
