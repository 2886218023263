import pathOr from 'ramda/src/pathOr'
import either from 'ramda/src/either'
import compose from 'ramda/src/compose'
import filter from 'ramda/src/filter'
// import map from 'ramda/src/map'
import propEq from 'ramda/src/propEq'
import _get from 'lodash/get'
import { uuid } from '../helpers/general'

export const pricingAttrKey = attribute => `pricing_attribute_${attribute.fieldName.toLowerCase()}`

export const bookingAttrKey = attribute => `booking_attribute_${attribute.fieldName.toLowerCase()}`

// used for finding selected passenger
const selectedPassengerId = ['uuid']

// used for finding selected vehicle
const selectedVehcleId = ['uuid']

const selectedPackId = ['uuid']

export const idName = id => id.join()

// export const token = rootGetters => rootGetters['user/getAccessToken']

export const token = rootGetters => rootGetters['user/getUserCredentials']

export const createAccomodation = id => ({
  [idName(id)]: uuid(),
  accommodationType: null,
  discountType: null
})

export const createPassenger = () => createAccomodation(selectedPassengerId)
export const createVehicle = () => createAccomodation(selectedVehcleId)
export const createPackage = () => createAccomodation(selectedPackId)

export const getAttrSelectedValueId = (attr, passengerData) => {
  const selValue = pathOr('', [pricingAttrKey(attr), 'selectedValue'], passengerData)
  const sel = pathOr([], [pricingAttrKey(attr), 'values'], passengerData).find(v => v.value === selValue)
  return pathOr('', ['id'], sel)
}

// Create combinations of attributes according to type provided ex: createVehicle, Passenger, Truck
// export const createCombinations = (pricingAttributes, type) => {
//   const passengerAttributes = pricingAttributes?.filter(pr => pr.type === type)
//   const childAttributes = passengerAttributes?.filter(atr => atr.availableForValues && atr.availableForValues.length)
//   const parentAttributes = passengerAttributes?.filter(atr => !atr.availableForValues)
//   const findChildAttrs = (parentAttr, childAttributes) =>
//     childAttributes?.filter(ch => ch.availableForValues.some(v => v.attribute === parentAttr.id))
//   const assignChildAttrs = (parentAttributes, childAttributes) =>
//     parentAttributes.map(attr => ({
//       parentAttr: attr,
//       childAttrs: findChildAttrs(attr, childAttributes)
//     }))
//   const valueExist = (pChildAttr, pAttrValue) => pChildAttr.availableForValues.some(c => c.value === pAttrValue.id)
//   const assignAvailableChildsToParentAttrValue = (parentAttribute, parentChildAttributes) => {
//     const values = parentAttribute.values.map(pAttrValue => {
//       const parentValues = [
//         {
//           parentAttribute,
//           parentAttributeValue: pAttrValue,
//           childAttribute: null,
//           childAttributeValue: null
//         }
//       ]

//       const allValues = parentChildAttributes.reduce(
//         (_arr, pChildAttr) =>
//           valueExist(pChildAttr, pAttrValue)
//             ? _arr.concat(
//                 pChildAttr.values.map(childVal => ({
//                   parentAttribute,
//                   parentAttributeValue: pAttrValue,
//                   childAttribute: pChildAttr,
//                   childAttributeValue: childVal
//                 }))
//               )
//             : _arr,
//         parentValues
//       )

//       if (allValues.length === 1) {
//         if (!parentChildAttributes.length) allValues[0].hasNoChildsToFilter = true
//         allValues[0].hasNoChilds = true
//       }
//       return {
//         ...pAttrValue,
//         availableChildAttributes: parentChildAttributes.filter(pChildAttr =>
//           pChildAttr.availableForValues.some(c => c.value === pAttrValue.id)
//         ),
//         allValues
//       }
//     })
//     return {
//       ...parentAttribute,
//       values
//     }
//   }
//   return assignChildAttrs(parentAttributes, childAttributes)
//     .map(pAttr => assignAvailableChildsToParentAttrValue(pAttr.parentAttr, pAttr.childAttrs))
//     .map(attr => attr.values.map(v => v.allValues))
//     .flat(5)
//     .map(p => ({ ...p, number: 0 }))
// }

export const getFilteredAccommodationTypes = (trip, attributesForFiltering, passengerData) => {
  // filter seats based on filtering attributes
  // get all seats available
  const getTripAvailablePassengerSeats = compose(
    filter(either(propEq('availabilityType', 'SEAT'), propEq('availabilityType', 'CABIN'))),
    pathOr([], ['accommodationAvailabilities'])
  )
  const seats = getTripAvailablePassengerSeats(trip)

  // filter those that is set a value for each attribute in passenger

  // Replace
  // const attrHasValue = ctx => attr => !!ctx.passengerData[ctx.pricingAttrKey(attr)]
  const attrHasValue = passengerData => attr => !!passengerData[pricingAttrKey(attr)]

  const attrHasAllValuesAvailable = attr => attr.availableValues.length === 0

  const attrSelectedValueId = passengerData => attr =>
    attrHasValue(passengerData)(attr) &&
    _get(
      passengerData[pricingAttrKey(attr)].values.find(
        v => v.value === passengerData[pricingAttrKey(attr)].selectedValue
      ),
      'id'
    )
  const attrToUseForFiltering = attributesForFiltering.filter(attrHasValue(passengerData))

  const filterAccommodation = passengerData =>
    seats.filter(seat =>
      seat.pricingAttributes.every(
        pAttr =>
          !attrHasValue(pAttr) ||
          attrHasAllValuesAvailable(pAttr) ||
          pAttr.availableValues.some(avValueId => avValueId === attrSelectedValueId(passengerData)(pAttr))
      )
    )
  // fitlter seats that allow those values on pricingAttributes
  if (attrToUseForFiltering.length) {
    return filterAccommodation(passengerData)
  }

  return seats
}

export const applyAccommodationType = (passenger, trip) => {
  const attrForFiltering = Object.entries(passenger)
    .filter(([key, attr]) => key.includes('attribute') && attr.selectedValue)
    .map(([key, attr]) => attr)
  const accomTypes = getFilteredAccommodationTypes(trip, attrForFiltering, passenger)
  if (accomTypes.length === 1) {
    passenger.accommodationType = accomTypes[0]
  }
  return passenger
}

export const applyAccTypeFromCombination = (passenger, combination) => {
  const accomodation = combination.accommodationType
  if (accomodation) {
    passenger.accommodationType = accomodation
  }
  return passenger
}

export const applyAttributes = (passenger, combination) => {
  const pass = {
    ...passenger,
    [pricingAttrKey(combination.parentAttribute)]: {
      ...combination.parentAttribute,
      selectedValue: combination.parentAttributeValue.value
    }
  }
  if (combination.childAttribute) {
    pass[pricingAttrKey(combination.childAttribute)] = {
      ...combination.childAttribute,
      selectedValue: combination.childAttributeValue.value
    }
  }
  return pass
}

export const createSelectedPassengerVehicles = (trip, getters) => {
  // Not used
  // const pasNumber = _get(getters, ['getSelectedRoutes', trip.routeId, 'travellers'], 0)
  // const vehNumber = _get(getters, ['getSelectedRoutes', trip.routeId, 'vehicles'], 0)
  const hasGarage = _get(trip, ['accommodationAvailabilities'], []).some(
    a => a.availabilityType === 'GARAGE' || a.availabilityType === 'GARAGE_TRUCK'
  )
  const selectedPassengersCombinations = _get(getters, ['getCombinationsPassengers'], [])
  const selectedVehiclesCombinations = hasGarage ? _get(getters, ['getCombinationsVehicles'], []) : []
  const selectedTrucksCombinations = hasGarage ? _get(getters, ['getCombinationsTrucks'], []) : []
  const vehiclesCombinations = _get(getters, 'searchWithTrucks')
    ? selectedTrucksCombinations.slice(0, 1).map(c => ({ ...c, number: 1 })) // TODO: keep trucks combinations
    : selectedVehiclesCombinations
  const createPassNumber = combination => {
    const combinations = combination.accNumber
      ? Object.values(combination.accNumber || {})
          .map(accommodation =>
            [...Array(accommodation.number).keys()].map(() => ({
              ...accommodation,
              ...combination,
              accommodationType: accommodation.accommodationType
            }))
          )
          .flat()
      : [...Array(combination.number).keys()].map(() => ({
          ...combination
        }))

    return combinations.map(comb =>
      applyAccommodationType(applyAccTypeFromCombination(applyAttributes(createPassenger(), comb), comb), trip)
    )
  }
  const createVehNumber = combination => {
    const combinations = combination.accNumber
      ? Object.values(combination.accNumber || {})
          .map(accommodation =>
            [...Array(accommodation.number).keys()].map(() => ({
              ...accommodation,
              ...combination,
              accommodationType: accommodation.accommodationType
            }))
          )
          .flat()
      : [...Array(combination.number).keys()].map(() => ({
          ...combination
        }))

    return combinations.map(comb =>
      applyAccommodationType(applyAccTypeFromCombination(applyAttributes(createVehicle(), comb), comb), trip)
    )
  }

  const selectedPassengers = selectedPassengersCombinations
    .map(comb => createPassNumber(comb))
    .flat()
    .reduce((_obj, item) => ({ ..._obj, [item.uuid]: item }), {})
  const selectedVehicles = vehiclesCombinations
    .map(comb => createVehNumber(comb))
    .flat()
    .reduce((_obj, item) => ({ ..._obj, [item.uuid]: item }), {})
  // const selectedVehicles = [...Array(vehNumber).keys()]
  //   .map(() => createVehicle())
  //   .reduce((_obj, item) => ({ ..._obj, [item.uuid]: item }), {})
  return { ...trip, selectedPassengers, selectedVehicles }
}

export const accommodationTitle = (selectedAccommodation, join = ', ') =>
  Object.entries(selectedAccommodation || {})
    .filter(([key, value]) => key.includes('pricing_attribute') && value && typeof value.selectedValue !== 'undefined')
    .map(([key, value]) => value.selectedValue)
    .concat(selectedAccommodation?.accommodationType?.accommodation?.abbreviation || '')
    .join(join)
