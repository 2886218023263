export const BASE_URL = process.browser ? process.env.API_BASE_URL_ON_BROWSER : process.env.API_BASE_URL // 'http://apps.develop.zappdev.com/Seaonline_5112_dev'

export const RELEASE_NOTES =  process.env.RELEASE_NOTES 

export const BASE_URL_API_B2B = process.browser ? process.env.API_B2B_BASE_URL_ON_BROWSER : process.env.API_B2B_BASE_URL // 'http://apps.develop.zappdev.com/Seaonline_5112_dev/api/B2BService'

export const NODE_URL = process.browser ? process.env.NODE_BASE_URL_ON_BROWSER : process.env.NODE_BASE_URL

export const BASE_URL_API_CHECKIN = process.browser
  ? process.env.API_CHECKIN_BASE_URL_ON_BROWSER
  : process.env.API_CHECKIN_BASE_URL

export const BASE_URL_API_TRUCKS = process.browser
  ? process.env.API_TRUCKS_BASE_URL_ON_BROWSER
  : process.env.API_TRUCKS_BASE_URL

export const BASE_URL_API_LOYALTY = BASE_URL + '/api/LoyaltyService'
export const BASE_URL_API_B2B_SETTINGS = process.browser ? process.env.API_BASE_URL_ON_BROWSER_SETTINGS : process.env.API_BASE_PATH_SETTINGS
export const API_PRINTER = process.browser ? process.env.API_PRINTER : process.env.API_PRINTER