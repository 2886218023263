import _get from 'lodash/get'
import { pipeP } from '../helpers/general'
import { token } from '../helpers/book-ticket'
import { storeSettings } from '../api/requests/settings/configurations'
import { getAccommdations } from '../api/requests/b2b-service/booking'
import { set, get, getOr } from '@/helpers/vuex'

const ipcRenderer = process.env.ENVIRONMENT === 'electron' ? require('electron').ipcRenderer : null

export const state = () => ({
  settings: {
    chargeAccount: false,
    showDialog: false,
    showCancelledTickets: false,
    selectedFromPort: {},
    selectedToPort: {},
    selectedPrinter: {},
    selectedPrinterModel: {},
    defaultDayToday: false,
    selectedCompany: '',
    defaultLanguage: 'el',
    defaultNumberOfPassengers: 0,
    defaultNumberOfVehicles: 0,
    datapcikerPositionFront: false,
    defaultColor: '#00a1e4',
    lightTextColor: false,
    defaultPrintPackage: false,
    shortcutRoutes: [],
    groupedAttributes: false,
    shortcuts: [],
    currency: "EUR",
    hideSettings: [],
    preferredDate: 'dd/MM/yyyy',
    defaultPassengerAccommodation: '',
    defaultVehicleAccommodation: '',
    currentYear: false,
    showCompany: true,
    showShortCuts:true,
    accommodations: {},
    routeFrequencyFlag: true,
    systemPrinters: [],
    selectedSystemPrinter: '',
    defaultShortcuts: [
      { name: 'Continue', keys: 'f1', category: ["step1", "step2"] },
      { name: 'Availability', keys: 'f3', category: ["step1"] },
      { name: 'Clear', keys: 'f12', category: ["step1"] },
      { name: 'Alter_Agency', keys: 'f6', category: ["step1"] },
      { name: 'Pricing', keys: 'f3', category: ["step2"] },
      { name: 'Copy_tripInfo', keys: 'f10', category: ["step2"] },
      { name: 'Fast_Pricing', keys: 'f5', category: ["step1"] },
      { name: 'Save_Booking', keys: 'ctrl,s', category: ["step2"] },
      { name: 'Add_Route', keys: 'shift,f6', category: ["step1"] },
      { name: 'Close_Seatplan', keys: 'esc', category: ["step2"] },
      { name: 'Next_Trip', keys: 'pageup', category: ["step2"] },
      { name: 'Prev_Trip', keys: 'pagedown', category: ["step2"] },
      { name: 'Issue', keys: 'f1', category: ["step2", "recall"] },
      { name: 'Proceed', keys: 'f1', category: ["step1"] },
      { name: 'Optional', keys: 'f6', category: ["step2", "recall"] },
      { name: 'Add_Product', keys: 'f2', category: ["step2", "recall"] },
      { name: 'Date_Focus', keys: 'shift,f7', category: ["step1"] },
      { name: 'Select_Passenger', keys: 'del', category: ["recall"] },
      { name: 'Cancel_Ticket', keys: 'shift,f11', category: ["recall"] },
      { name: 'Fill_Phone', keys: 'ctrl,shift,f1', category: ["step2"] },
      { name: 'Latest_Booking', keys: 'alt,f9', category: ["step1", "step2", "recall"] },
      { name: 'Search_By_Reservation', keys: 'f9', category: ["step1", "step2", "recall"] },
      { name: 'Search_By_Ticket', keys: 'shift,f9', category: ["step1","step2", "recall"] },
      { name: 'Update_Reservation_Data', keys: 'shift,f12', category: ["recall"] },
      { name: 'Open_Shortcuts_Modal', keys: 'ctrl,f1', category: ["step1", "step2", "recall"] },
      { name: 'New_Search', keys: 'f12', category: ["step2"] },
      { name: 'Copy_Input_Value', keys: 'f4', category: ["step2", "recall"] }
    ]
  },
  tcpIp: '',
  tcpPort: '',
  showTipOfTheDay: true,
  notificationCloseStatus: false,
})

export const mutations = {
  SET_DEFAULT_PASSENGER_ACCOMMODATION: set('settings.defaultPassengerAccommodation'),
  SET_DEFAULT_VEHICLE_ACCOMMODATION: set('settings.defaultVehicleAccommodation'),
  SET_DEFAULT_FROM_PORT: set('settings.selectedFromPort'),
  SET_DEFAULT_COMPANY: set('settings.selectedCompany'),
  SET_DEFAULT_TO_PORT: set('settings.selectedToPort'),
  SET_DEFAULT_PRINTER: set('settings.selectedPrinter'),
  SET_DEFAULT_PRINTER_MODAL: set('settings.selectedPrinterModel'),
  SET_DEFAULT_DATE: set('settings.defaultDayToday'),

  SET_CHARGE_ACCOUNT: set('settings.chargeAccount'),
  SET_SHOW_DIALOG: set('settings.showDialog'),
  SET_SHOW_CANCELLED_TICKETS: set('settings.showCancelledTickets'),

  SET_TCP_IP: set('tcpIp'),
  SET_TCP_PORT: set('tcpPort'),
  SET_SHOW_TIP_OF_THE_DAY: set('showTipOfTheDay'),

  SET_DEFAULT_PRINT_PACKAGE: set('settings.defaultPrintPackage'),
  SET_ROUTE_FREQUENCY_FLAG: set('settings.routeFrequencyFlag'),
  SET_SHOW_COMPANY: set('settings.showCompany'),
  SET_NOTIFICATION_CLOSE_STATUS: set('notificationCloseStatus'),
  SET_SHOW_SHORTCUTS: set('settings.showShortCuts'),
  SET_IF_GROUPED_ATTRIBUTES: set('settings.groupedAttributes'),
  SET_DEFAULT_LANGUAGE: set('settings.defaultLanguage'),
  SET_DEFAULT_NUMBER_OF_PASSENGERS: set('settings.defaultNumberOfPassengers'),
  SET_DEFAULT_NUMBER_OF_VEHICLES: set('settings.defaultNumberOfVehicles'),
  SET_DEFAULT_DATEPICKER_POSITION_FRONT: set('settings.datapcikerPositionFront'),
  SET_DEFAULT_COLOR: set('settings.defaultColor'),
  SET_COLOR_VARIANT: set('settings.lightTextColor'),
  SET_SHORTCUTS_ROUTES: set('settings.shortcutRoutes'),
  SET_SETTINGS_FROM_API: set('settings'),
  SET_CURRENT_YEAR: set('settings.currentYear'),
  SET_PREFERRED_DATE: set('settings.preferredDate'),
  SET_SETTINGS_ACCOMMODATIONS: set('settings.accommodations'),
  SET_SYSTEM_PRINTERS: set('settings.systemPrinters'),
  SET_SELECTED_SYSTEM_PRINTER: set('settings.selectedSystemPrinter'),
  SET_SHORTCUTS(state, payload) {
    // if (!state.settings.shortcuts.some(s => s.name === payload.name)) {
    state.settings.shortcuts = payload
    // }
  },
  SET_HIDE_SETTINGS(state, payload) {
    state.settings.hideSettings = payload
  },
}

const parseJson = (str, defValue) => {
  try {
    return JSON.parse(str)
  } catch (er) {
    return defValue
  }
}

export const actions = {
  // getAvailableAccommodations({ rootGetters, commit }, payload) {
  //   const createParams = data => ({ parms: data, token: token(rootGetters) })
  //   const setAccommodations = data => commit('SET_SETTINGS_ACCOMMODATIONS', data)
  //   const handleAction = pipeP(createParams, getAccommdations, setAccommodations)
  //   return handleAction(payload).then(res => console.log('accommodations ', res))
  // },
  setSettingsFromApi({ commit, state }, payload) {
  
    commit('SET_SETTINGS_FROM_API', {
      ...state.settings,
      ...parseJson(payload, {}),
      shortcuts: !parseJson(payload, {})?.shortcuts?.length ? state.settings.defaultShortcuts : parseJson(payload, {}).shortcuts,
      currency: payload.currency
    })
  },
  setSettingsHide({ commit, state }, payload) {
    commit('SET_SETTINGS_FROM_API', {
      ...state.settings,
      hideSettings: payload,
    })
  },
  setSettings({ commit, state }, { value, mutation }) {
    commit(mutation, value)
  },
  setShortcuts({ commit }, payload) {

    commit('SET_SHORTCUTS', payload)
  },
  setHideSettings({ commit }, payload) {
    commit('SET_HIDE_SETTINGS', payload)
  },
  saveSettingsToApi({ rootGetters, state }, payload) {

    const createRequestParams = () => ({
      data: {interfaceSetting: JSON.stringify(_get(state, 'settings', {}))},
      token: token(rootGetters)
    })
    const actionToApi = data => storeSettings(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },
  setPreferredDate({ commit }, payload) {
    commit('SET_PREFERRED_DATE', payload)
  },
  setCurrentYear({ commit }, payload) {
    commit('SET_CURRENT_YEAR', payload)
  },
  async getSystemPrinters({ rootGetters, commit }, payload) {
    // The code below is only for Electron
    const printers = ipcRenderer ? await ipcRenderer.invoke('get-system-printers') : []
    commit('SET_SYSTEM_PRINTERS', printers)
    return printers
  },
  setSelectedSystemPrinter({ commit }, payload) {
    commit('SET_SELECTED_SYSTEM_PRINTER', payload)
  },
}

export const getters = {
  getDefaultPassengerAccommodation: getOr('settings.defaultPassengerAccommodation', ''),
  getDefaultVehicleAccommodation: getOr('settings.defaultVehicleAccommodation', ''),
  getPassengerAccommodations: getOr('settings.accommodations.passengers', ''),
  getVehicleAccommodations: getOr('settings.accommodations.vehicles', ''),
  getDefaultFromPort: getOr('settings.selectedFromPort', ''),
  getDefaultToPort: getOr('settings.selectedToPort', ''),
  getDefaultPrinterName: getOr('settings.selectedPrinter.name', ''),
  getDefaultPrinterId: getOr('settings.selectedPrinter.id', ''),
  getDefaultCompany: getOr('settings.selectedCompany', ''),
  getDefaultPrinterModel: getOr('settings.selectedPrinter.model', ''),
  getDefaultDayToday: getOr('settings.defaultDayToday', false),

  getChargeAccount: getOr('settings.chargeAccount', false),
  getShowDialog: getOr('settings.showDialog', false),
  getSendCancelledTickets: getOr('settings.showCancelledTickets', false),

  getTcpIp: getOr('tcpIp', ''),
  getTcpPort: getOr('tcpPort', ''),
  getShowTipOfTheDay: getOr('showTipOfTheDay', true),

  getDefaultPrintPackage: getOr('settings.defaultPrintPackage', false),
  getShowCompany: getOr('settings.showCompany', false),
  getNotificationCloseStatus: getOr('notificationCloseStatus', false),
  getShowShortCuts: getOr('settings.showShortCuts', false),

  getDefaultLanguage: getOr('settings.defaultLanguage', {}),
  getIfGroupedAttributes: getOr('settings.groupedAttributes', {}),
  getDefaultNumberOfPassengers: getOr('settings.defaultNumberOfPassengers', 0),
  getDefaultNumberOfVehicles: getOr('settings.defaultNumberOfVehicles', 0),
  getRouteFrequencyFlag: getOr('settings.routeFrequencyFlag', true),
  getDefaultDatepickerPositionFront: getOr('settings.datapcikerPositionFront', false),
  getDefaultColor: getOr('settings.defaultColor', ''),
  getLightTextColor: getOr('settings.lightTextColor', false),
  getShortcutRoutes: getOr('settings.shortcutRoutes', []),
  getShortcuts: getOr('settings.shortcuts', []),
  getCurrency(state) {
    const currency = _get(state, 'settings.currency', 'EUR');
    if (currency === 'EUR') {
      return '€';
    } else if (currency === 'CZK') {
      return 'Kč';
    } else {
      return currency;
    }
  },
  getHideSettings: getOr('settings.hideSettings', []),
  getDefaultShortcuts: getOr('settings.defaultShortcuts'),
  getShortcutsFormatted(state) {
    const formattedShortcuts = {};
    state.settings.shortcuts.forEach((shortcut) => {
      formattedShortcuts[shortcut.name] = {
        keys: shortcut.keys,
        category: shortcut.category,
      };
    });
    
    return formattedShortcuts;
  },
  
  getPreferredDate: getOr('settings.preferredDate', ''),
  getCurrentYear: getOr('settings.currentYear', false),
  systemPrinters: getOr('settings.systemPrinters', []),
  defaultSystemPrinter: (state) => state.settings.systemPrinters?.find(printer => printer.isDefault),
  selectedSystemPrinter: getOr('settings.selectedSystemPrinter', ''),
}
