import Vue from 'vue'
import _groupBy from 'lodash/groupBy'
import _get from 'lodash/get'
import { pipeP } from '../helpers/general'
import { configurations, companyRedirections } from '../api/requests/settings/configurations'
import { createCombinations, token } from '../helpers/book-ticket'
import { financialData, cancellationFees, availableAttributes } from '../api/requests/b2b-service/booking'
import { set, getOr, get, servicesTotalNumber } from '@/helpers/vuex'


// const vehicleConfigurations = attributes => {
//   const attributesToShow = attributes.filter(pr => pr.type === 'Vehicle')
//   console.log(attributesToShow)
//   return attributesToShow.map(p => ({ ...p, values: p.values.map(v => ({ ...v, number: 0 })) }))
// }
export const state = () => ({
  bookingConfigurations: {},
  browserInfo: {},
  credits: null,
  macAddress: '',
  companyRedirections: [],
})

export const mutations = {
  SET_BOOKING_CONFIGURATIONS: set('bookingConfigurations'),

  UPDATE_SERVICES(state, { value, sercviceIndex, itemIndex }) {
    const itemToUpdate = _get(state.bookingConfigurations, `additionalServices.${sercviceIndex}.items.${itemIndex}`)
    if (itemToUpdate) Vue.set(itemToUpdate, 'availableUnits', value)
  },

  SET_BROWSER_INFO: set('browserInfo'),

  SET_MAC_ADDRESS: set('macAddress'),

  SET_CREDITS: set('credits'),

  SET_COMPANY_REDIRECTIONS: set('companyRedirections'),
}

export const actions = {
  // getAvailableAddress({ rootGetters, commit, state, dispatch }) {
  //   const createParams = data => ({
  //     token: token(rootGetters)
  //   })
  //   const handleAction = pipeP(createParams, getMacAddress)
  //   return handleAction().then(res => commit('SET_MAC_ADDRESS', res))
  // },
  setAvailableAttributes({ rootGetters, commit }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, availableAttributes)
    return handleAction().then(res => {
      const formattedAttrs = res.reduce(
        (_obj, attr) => ({
          ..._obj,
          [attr.id]: { ...attr }
        }),
        {}
      )
      commit('book-ticket/SET_AVAILABLE_ATTRIBUTES', formattedAttrs, {
        root: true
      })
    })
  },
  setCompanyRedirections({ rootGetters, commit }, payload) {
    const createParams = data => ({ params: payload, token: token(rootGetters) })
    const handleAction = pipeP(createParams, companyRedirections)
    return handleAction().then(res => commit('SET_COMPANY_REDIRECTIONS', res.companyRedirections))
  },
  getAvailableConfigurations({ rootGetters, commit, state, dispatch }) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, configurations)
    return handleAction()
      .then(data => {
          dispatch('settings/setSettingsFromApi', { settings: data?.interfaceSetting, currency: data?.currency, }, {
            root: true
          })
        dispatch('settings/setSettingsHide', data?.hideSetting, {
          root: true
        })

      })
      .then(() => {
        dispatch('book-ticket/setDefaultPassengersOrVehiclesFromSettings', null, { root: true })
      })
  },

  updateServices({ commit }, payload) {
    commit('UPDATE_SERVICES', payload)
  },

  setBrowserInfo({ commit }, payload) {
    commit('SET_BROWSER_INFO', payload)
  },

  getFinancialData({ rootGetters, commit }, payload) {
    const createParams = data => ({
      token: token(rootGetters),
      params: {companies: payload.toString()}
    })
    const setCredits = credits => {
      commit('SET_CREDITS', credits)
      return credits
    }
    const handleAction = pipeP(createParams, financialData, setCredits)
    return handleAction()
  },
  getCancellationFees({ rootGetters, commit }, payload) {
    const createParams = data => ({
      token: token(rootGetters),
      params:payload
    })
    
    const handleAction = pipeP(createParams, cancellationFees)
    return handleAction()
  },
}

export const getters = {
  getCredits: get('credits'),

  getAvailableConfigurations: getOr('bookingConfigurations', {}),

  getServicesNumber: servicesTotalNumber('bookingConfigurations.additionalServices'),

  getServices: getOr('bookingConfigurations.additionalServices', []),

  getBrowserInfo: getOr('browserInfo', {}),

  getAdditionalServices: getOr('bookingConfigurations.additionalServices'),

  getNationality: getOr('bookingConfigurations.user.nationality'),

  getUser: getOr('bookingConfigurations.user'),

  redirectionLinks: state => state?.companyRedirections
    ?.filter(r => r.methodType === 'GET')
    .map(r => ({ description: r.company.description, link: `${r.redirectionUrl}?${r.urlParameters}` }))
}
