<template>
    <div 
        v-if="this.getIsIdle"
        class="idle-popup-overlay"
    >
        <div class="idle-popup">
            <h2>{{ $t('idle_title_warning') }}</h2>
            <p>{{ $t('idle_user') }}</p>
            <p>{{ time }} {{ $t('seconds_left') }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    data() {
        return { time: 60,}
    },
    onIdle() { this.setIdle(true); },
    onActive() { this.setIdle(false); },
    created() { this.startIdleTimer(); },
    computed: { ...mapGetters({ getIsIdle: 'user/getIsIdle' }) },
    methods: {
        ...mapMutations({ setIdle: 'user/SET_IDLE' }),
        ...mapActions({ logout: 'user/logout' }),
        startIdleTimer() {
            this.interval = setInterval(() => {
                if (this.getIsIdle) {
                    this.time -= 1;
                    if (this.time <= 0) this.handleLogout();

                } else { this.time = 60; }
            }, 1000);
        },
        handleLogout() {
            this.logout();
            this.setIdle(false);
            clearInterval(this.interval);
        }
    }
}
</script>

<style scoped>
.idle-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.idle-popup {
    background-color: #fff;
    padding: 20px;
    width: 500px;
    border-radius: 8px;
    text-align: center;
}
</style>
