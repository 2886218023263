<template>
  <div>

    <span
      v-shortkey="['esc']"
      class="ant-dropdown-link"
      style="color: white; cursor: pointer;"
      @shortkey="closePopup"
      @click="onClickDropDown"
    >
      <img src="@/assets/img/icons/save_header.svg" alt="" />
    </span>
    <a-modal v-if="savedBookings.length" v-model="visible" footer="">
      <div slot="title" style="color: black; font-size: 2rem">{{ $t('saved_bookings') }}</div>
      <div class="bookingName">{{ $t('booking_name') }}</div>
      <div v-for="(booking, i) in filteredSavedBookings" :key="i" class="savedBooking">
        <span class="bookingCont">
          <div
            @click="
              () => {
                visible = false            
                onClickSaved({ name: booking })
              }
            "
          >
            {{ booking }}
          </div>
          <div class="delete" @click="deleteState(booking)">
            <a-icon type="close" />
          </div>
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from "@/plugins/event-bus";
export default {
  data() {
    return {
      savedBookings: [],
      showDropDown: false,
      visible: false
    }
  },
  computed: {
    filteredSavedBookings() {
      return this.savedBookings.filter(b => b !== 'addroute')
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      resetState: 'book-ticket/resetState',
      saveCurrentStateToStorage: 'book-ticket/saveCurrentStateToStorage',
      loadSavedState: 'book-ticket/loadSavedState',
      deleteSavedState: 'book-ticket/deleteSavedState',
      getStorageSavedBookings: 'book-ticket/getStorageSavedBookings',
      getValidateCoupon :'book-ticket/getValidateCoupon',
      setValidateCoupon:'book-ticket/setValidateCoupon',
      unlockToSave: 'book-ticket/unlockToSave',
    }),
    closePopup() {
      // close popup with shortcut if popups
      this.visible = false
    },
    resetBooking() {
      this.resetState()
    },
    async onClickSaved({name: booking}) {
      if (this.getValidateCoupon) {
        this.setValidateCoupon(false)
        await this.loadSavedState({ name: booking })
        this.unlockToSave({ keepSeats: true, checkIfMadePricingOnGoToStep4: true })
        this.showDropDown = false
      } else {
        await this.loadSavedState({ name: booking })
        this.unlockToSave({ keepSeats: true, checkIfMadePricingOnGoToStep4: true })
        this.showDropDown = false
      }
    },
    onClickDropDown() {
      this.getStorageSavedBookings().then(savedBookings => {
        this.savedBookings = savedBookings
      })
      this.visible = true
    },
    onChangeVisible() {
      this.getStorageSavedBookings().then(savedBookings => {
        this.savedBookings = savedBookings
      })
    },
    deleteState(name) {
      this.deleteSavedState({ name })
      const index = this.savedBookings.indexOf(name)
      this.savedBookings.splice(index, 1)
      if (!this.savedBookings.length) {
        this.visible = false
      }
    }
  }
}
</script>

<style lang="scss">
.shortkeysBox-btn{
  top: -3px;
  img{
    height: 29px;
  }
}
.bookingName {
  font-size: 1.5rem;
  border-bottom: solid 1px #dce0e6;
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: black;
}
.savedBooking {
  cursor: pointer;
}
.bookingCont {
  margin-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  .delete {
    cursor: pointer;
  }
}
</style>
