import _uniq from 'lodash/uniq'
// import get from 'lodash/get's
import {
  token,
  // updateOrCreateListItemBy,
  // updateOrCreateListItemByIndex,
  // addToList,
  // removeFromListBy,
  get,
  // getOr,
  // existInListBy,
  set,
  findOneBy
  // updateOrSet,
  // findOneBy //,
  // setListItemPath
} from '../helpers/vuex'
import { apiCheckinAvailableInstances } from '../api/requests/check-in-service/available-instances'
import { apiCheckinAvailablePorts } from '../api/requests/check-in-service/available-ports'
import { apiTicketsByReservationCode } from '../api/requests/check-in-service/tickets-by-reservation-code'
import { apiCheckinPassengerList } from '../api/requests/check-in-service/passenger-list'
import { apiCheckinPassengerListPaged } from '../api/requests/check-in-service/passenger-list-paged'
import { apiCheckinGetStats } from '../api/requests/check-in-service/get-stats'
import { apiCheckinGetVessels } from '../api/requests/check-in-service/get-vessels'
import { apiCheckinHistory } from '../api/requests/check-in-service/history'
import {
  apiCheckin,
  apiBulkCheckout,
  apiForceCheckin,
  apiValidateTickets
} from '../api/requests/check-in-service/checkin'
import { apiCheckout } from '../api/requests/check-in-service/checkout'
import { apiStartCheckin } from '../api/requests/check-in-service/start-checkin'
import { apiStopCheckin } from '../api/requests/check-in-service/stop-checkin'
import { apiCheckinTicketInfo } from '../api/requests/check-in-service/ticket-info'

import { pipeP } from '../helpers/general'
export const state = () => ({
  checkinPortCode: '', // the port code from where is the checkin made
  availablePorts: [], // ports to use for searching checkin trips
  availableTrips: [], // trips result after searching
  tickets: [], // the tickets result after searching for tickets by reservation
  passengersList: [], // the ship list of passengers
  selectedTrip: {},
  ticketInfos: [],
  validationData: {},
  ticketNumber: ''
})

export const getters = {
  getTripByItineraryId: findOneBy('availableTrips', ['ItineraryId']), // usage: this.$store.getters['checkin/getTipByItineraryId']({ ItineraryId: 1231 })
  getCheckinPortCode: get('checkinPortCode'),
  getCheckinSelectedTrip: get('selectedTrip'),
  getPassStats: get('selectedTrip.stats'),
  getTicketNumber: get('ticketNumber'),
  getValidationData: get('validationData'),
  passengersList: get('passengersList')
  // getTripByItineraryId: state => data => {
  //   const tr = findOneBy('pricing.trips', tripId)(state)(trip)
  //   return _get(tr, 'prices', [])
  // }
}

export const mutations = {
  SET_AVAILABLE_PORTS: set('availablePorts'),
  SET_AVAILABLE_TRIPS: set('availableTrips'),
  SET_TICKETS: set('tickets'),
  SET_PASSENGERS_LIST: set('passengersList'),
  SET_VALIDATION_DATA: set('validationData'),
  SET_CHECKIN_PORT: set('checkinPortCode'),
  SET_SELECTED_TRIP: set('selectedTrip'),
  SET_CHECKIN_TICKET_INFO: set('ticketInfos'),
  SET_CHECKIN_STATS: set('selectedTrip.stats'),
  SET_TICKET_NUMBER: set('ticketNumber')
}

export const actions = {
  setCheckinStats({ commit }, payload) {
    commit('SET_CHECKIN_STATS', payload)
  },

  setValidationData({ commit }, payload) {
    commit('SET_VALIDATION_DATA', payload)
  },

  setTicketNumber({ commit }, payload) {
    commit('SET_TICKET_NUMBER', payload)
  },

  getCheckinAvailableTrips({ commit, rootGetters }, payload) {
    // available options payload
    // const { portCodeFrom, portCodeTo, departureDate, hoursAhead } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinAvailableInstances(data)
    const tap = data => {
      commit('SET_AVAILABLE_TRIPS', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload) // .catch(handleError)
  },

  getCheckinAvailablePorts({ commit, rootGetters }, payload) {
    // payload not needed currently (no options are available to api route)
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinAvailablePorts(data)
    const tap = data => {
      commit('SET_AVAILABLE_PORTS', _uniq(data))
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap, _uniq)
    return handleAction(payload)
  },

  getCheckinTicketsBy({ commit, rootGetters }, payload) {
    // available options payload
    // const { itineraryId, reservationCode } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiTicketsByReservationCode(data)
    const tap = data => {
      commit('SET_TICKETS', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getCheckinPassengerListBy({ commit, rootGetters }, payload) {
    // available options payload
    // const { itineraryId, fromCode } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinPassengerList(data)
    const tap = data => {
      commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },
  getCheckinPassengerListPaged({ commit, rootGetters }, payload) {
    // available options payload
    // const { itineraryId, fromCode } = payload\
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinPassengerListPaged(data)
    const tap = data => {
      commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getCheckinTicketInfo({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinTicketInfo(data)
    const tap = data => {
      commit('SET_CHECKIN_TICKET_INFO', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getCheckinGetVessels({ commit, rootGetters }, payload) {
    // available options payload
    // const { itineraryId } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinGetVessels(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getCheckinGetStats({ commit, rootGetters }, payload) {
    // available options payload
    // const { itineraryId } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinGetStats(data)
    const tap = data => {
      commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },
  // setPassengerStats({ commit }, payload) {
  //   commit('SET_CHECKIN_PASS_CKECKEDIN', payload)
  // },
  getCheckinHistory({ commit, rootGetters }, payload) {
    // available options
    // const { itineraryId, fromCode, pageSize, currentPage } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckinHistory(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  startTripCheckin({ commit, rootGetters }, payload) {
    // available options
    // const { itineraryId, fromCode } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiStartCheckin(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  stopTripCheckin({ commit, rootGetters }, payload) {
    // available options
    // const { itineraryId, fromCode } = payload
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiStopCheckin(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  checkinPassenger({ commit, rootGetters }, payload) {
    // available options
    // const { StopCode, PlateNumber, ItineraryId, TicketNumber, Comments } = payload
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckin(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  forceCheckinPassenger({ commit, rootGetters }, payload) {
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiForceCheckin(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  bulkCheckout({ commit, rootGetters }, payload) {
    // available options
    // const { StopCode, PlateNumber, ItineraryId, TicketNumber, Comments } = payload
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiBulkCheckout(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  validateTickets({ commit, rootGetters }, payload) {
    console.log({ payload })
    // available options
    // const { StopCode, PlateNumber, ItineraryId, TicketNumber, Comments } = payload
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiValidateTickets(data)
    const tap = data => {
      commit('SET_VALIDATION_DATA', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  checkoutPassenger({ commit, rootGetters }, payload) {
    // available options
    // const { StopCode, PlateNumber, ItineraryId, TicketNumber, Comments } = payload
    const createRequestParams = () => ({ data: payload, token: token(rootGetters) })
    const actionToApi = data => apiCheckout(data)
    const tap = data => {
      // commit('SET_PASSENGERS_LIST', data)
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  setSelectedTrip({ commit }, payload) {
    commit('SET_SELECTED_TRIP', payload)
  }
}
