import axios from 'axios'
import { mapState } from "vuex";
import Vue from 'vue'
import Vuex from 'vuex'
import { message } from 'ant-design-vue';
import _debounce from 'lodash/debounce'
import { createStorage } from '@/helpers/storage'
import { apiLogin } from '../requests/b2b-service/auth'
import { state,mutations,actions } from '../../store/user'
import store from "../../store/user";
import pkg from '../../../../package.json'
Vue.use(Vuex)


// // Response interceptor for API calls
// let conf='';
// axios.interceptors.response.use((response) => {

//   return response;
//   }, async function (error) {
//     const originalRequest = error.config;
//     if (error && error.response && error.response.status && error.response.status === 401 ) {
//       const run = () => message.error('Session has expired. Please Login again')
//       const notification = _debounce(run, 500);
//       notification()
//       setTimeout(() => {
//         $nuxt.$options.router.push({ path: '/' })
//       },1000)
//       // originalRequest._retry = true;
//       // const session_token = await refreshAccessToken();
//       // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//       // return axios(originalRequest);
//     }
//     return Promise.reject(error);
//   });

export default axios
