import axios from '../../client/axios'
import { BASE_URL_API_CHECKIN } from '../../settings'

/**
 * This method changes the user status to checkedin
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - The request body params {
 *  StopCode: string
 *  PlateNumber: string
 *  ItineraryId: integer
 *  TicketNumber: string
 *  Comments: string
 * }
 * @property { String } params.token - The access token for authorization
 */
export const apiCheckin = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/CheckIn',
    headers: { Authorization: `Bearer ${token}` },
    // params,
    data
  }).then(res => res.data)
}

/**
 * This method checkin a ticket from another trip
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - The request body params {
 *  StopCode: string
 *  PlateNumber: string
 *  ItineraryId: integer
 *  TicketNumber: string
 *  Comments: string
 * }
 * @property { String } params.token - The access token for authorization
 */

export const apiForceCheckin = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/ForceCheckIn',
    headers: { Authorization: `Bearer ${token}` },
    // params,
    data
  }).then(res => res.data)
}
/**
 * This method changes the user status to checkedin
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - The request body params {
 * }
 * @property { String } params.token - The access token for authorization
 */
export const apiBulkCheckout = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/BulkCheckout',
    headers: { Authorization: `Bearer ${token}` },
    // params,
    data
  }).then(res => res.data)
}

export const apiValidateTickets = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/ValidateTicket',
    headers: { Authorization: `Bearer ${token}` },
    // params,
    data
  }).then(res => res.data)
}
