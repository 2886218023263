import axios from '../../client/axios'
import { BASE_URL_API_CHECKIN } from '../../settings'

/**
 * This method brings all the passengers by itinerary id or portCode
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.params - The request query params {
 *  itineraryId: integer, optional
 *  fromCode: string optional
 * }
 * @property { String } params.token - The access token for authorization
 */
export const apiCheckinPassengerListPaged = ({ params, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_CHECKIN,
    url: '/PassengerListPaged',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)
}
