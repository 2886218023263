/**
 * Compose a new function that returns Promise from other functions
 * The execution of functions is from right to left
 *
 * Usage:
 * const add1Async = d => new Promise((resolve, reject) => setTimeout(() => resolve(d + 1), 1000))
 * const add2 = c => c + 2
 * const calc = composeP(add2, add1Async)
 * calc(1).then(res => console.log(res)) // 4
 *
 * @param  {...Function} funcs - Functions to be executed sequentially
 * @returns {Function<Promise>} - Returns a function tha returns a Promise
 */
export const composeP = (...funcs) => val => funcs.reduceRight((_acc, func) => Promise.resolve(_acc).then(func), val)

/**
 * Creates a new function that returns Promise from other functions
 * Could be used to create single function for multiple async tasks with Promises.
 * The execution of functions is from left to right
 *
 * Usage:
 * const promise1 = d => Promise.resolve(d + 1)
 * const promise2 = c => c + 2
 * const calc = pipeP(promise1, promise2)
 * calc(1).then(res => console.log(res)) // 4
 *
 * @param  {...Function} promises - Functions to be executed
 * @returns {Function<Promise>} - Returns a function tha returns a Promise
 */
export const pipeP = (...promises) => val =>
  promises.reduce((_acc, promise) => Promise.resolve(_acc).then(promise), val)

export const clone = data => (typeof data !== 'undefined' ? JSON.parse(JSON.stringify(data)) : data)

export const uuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const pricingUuid = () => {
  let dt = new Date().getTime()
  let dtm = new Date().getMilliseconds()
  const uuid = 'xxxxxxxxxxx-xxxxx-4xxxx-yxxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16) + dtm
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}