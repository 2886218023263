import pathOr from 'ramda/src/pathOr'
import Vue from 'vue'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _findKey from 'lodash/findKey'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _union from 'lodash/union'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import _some  from 'lodash/some'
import { scrollToId } from '@/helpers/dom'
import moment from 'moment'
import { pipeP, pricingUuid } from '../helpers/general'
import { log } from '../helpers/log'
import { createStorage } from '../helpers/storage'
import {
  updateOrCreateListItemBy,
  updateOrCreateListItemByIndex,
  addToList,
  removeFromListBy,
  get,
  getOr,
  existInListBy,
  set,
  updateOrSet,
  combinationsTotal,
  findOneBy
} from '../helpers/vuex'
import {
  pricingAttrKey,
  createSelectedPassengerVehicles,
  createPassenger,
  createVehicle,
  createPackage,
  idName,
  token,
  // userCred
} from '../helpers/book-ticket'
import { apiHarbourLocations, apiHarbourLocationsWithFilter } from '../api/requests/b2b-service/locations'
import { apiRetrievePrinters, apiRetrievePrinterJobs, apiSendPrinterJob, apiRetrieveTemplate, apiRetrieveCompanies, } from '../api/requests/b2b-service/printing'
import { apiHarbourRoutesWithFilter, apiRouteFrequency, apiRoutes } from '../api/requests/b2b-service/routes'
import { apiLoyaltyInfo, LoyaltyRequestBodyDTO } from '../api/requests/loyalty-service/info'
import {
  apiTrips,
  apiListOfTrips,
  apiProjectListOfTrips,
  apiTripsAvailability,
  apiPricingBookingReferenceData,
  apiTripsPerDay,
  TripRequestBodyDTO,
  TripRequestReferenceBodyDTO,
  TripPerDayRequestBodyDTO,
  seatMapForATS,
  seatMapForCabins,
  countries,
  vesselList,
  validateManCode,
  validateLoyaltyData,
  calculateLoyaltyMiles,
  availablePaymentMethods,
  lockAccomodations,
  unlockAccomodations
} from '../api/requests/b2b-service/trips'
import {
  apiPricing,
  PricingRequestBodyDTO,
  PricingRequestBodyDTONotSort,
  unlockRequestBodyDTONotSort,
  ConjuctionsPricingRequestBodyDTO
} from '../api/requests/b2b-service/pricing'
import { apiPrintingTemplate } from '../api/requests/b2b-service/printing-template'
import {
  apiBooking,
  apiOptionalBooking,
  apiAddItemsToBooking,
  apiReplaceOpenTickets,
  apiPriceDifferenceForReplaceOpenTickets,
  BookingRequestBodyDTO,
  ValidateResidentDataDTO,
  printPdf,
  sendEmail,
  sendSms,
  latestBookingAvailable,
  cashierData,
  statisticsData,
  residentCitiesList,
  agencyInfo,
  validateOpenTicket,
  getClearanceDownloadId,
  downloadClearance,
  getPrintingModels,
  availablePassengerTypes,
  apiRetrieveProjects,
  apiValidateVoucher,
  apiRetrieveProjectInfo,
  getCompanies,
  apiTransferTickets,
  apiRetrieveBookingsByNameList,
  apiRetrieveActiveOptionalBookings,
  insurance,
  sendInsurance,
  getSmallPackages,
  getSmallPackagesPricing,
  getSmallPackagesBooking,
  getSmallPackagesCancel,
  alternateAgencies
} from '../api/requests/b2b-service/booking'

export const tripId = [
  // 'arrivalDateTime', // missing from pricign response
  'departureDateTime',
  'destination.idOrCode',
  'origin.idOrCode',
  'vessel.idOrCode'
]

// used for finding selected passenger
const selectedPassengerId = ['uuid']

// used for finding selected vehicle
const selectedVehcleId = ['uuid']

const selectedPackId = ['uuid']

// [this.pricingAttrKey(attribute)]: { ...attribute, selectedValue }
const filters = () => ({
  shipType: [],
  company: [],
  intermediateStops: [],
  cabin: {
    hasCabin: null,
    hasNoCabin: null
  },
  garage: {
    hasGarage: null,
    hasNoGarage: null
  },
  dangerousGoods: {
    hasDangerousGoods: null,
    hasNoDangerousGoods: null
  },
  duration: [],
  departureTimeDuration: [],
  passPriceRange: [],
  vehPriceRange: [],
  positionType: []
})

const initialState = () => ({
  otherAccount: null,
  stepsVisited: {
    0: true
  }, // 0: search step, 1: select trips step, 2: pricing forms step
  loadingTrips: false,
  hasExtraService: false,
  truckSearch: false,
  isPTA: false,
  insurance: {
    details: {},
    confirm: false,
    productOffer: []
  },
  packageSearch: false,
  parcel: false,
  disableKeyboard: false,
  conjuctionsSearch: false, // Call trips with conjuctions
  daysToSearch: 0,
  addedPassengers: 0,
  addedExtraService: 0,
  addedVehicles: 0,
  printFinalTicket:true,
  specificTime: '',
  availablePrinters: '',
  availableCompanies: '',
  groupSearch: {
    status: false, // Call trips for groups
    minPassengers: 0, // minimum passengers for groups
    maxPassengers: 0 // maximum passengers for groups
  },
  groupName: '',
  type: '', // one way, multi, return
  selectedTrips: [],
  selectedTripsCheckDouble: [],
  selectedConjuctionsTrips: [], // Main trips (has conjuctions) ex. A - D (a-b b-c c-d)
  savedStateName: '', // used for saved state. When this property has value means tha user has come from saved state
  selectedRoutes: {
    routesExtras: {
      travellers: 1,
      vehicles: 0
    },
    routes: []
  },
  listOfTrips: {},
  locations: {},
  companies: {},
  selectedCompanyDiscount: [],
  attributes: {},
  discounts: {},
  routes: {},
  parcelPricing: [],
  dataPricing: {}, // pricing response object
  pricing: {}, // pricing response object
  booking: {}, // booking response object
  combinedTrips: [], // Combined trips for filtering
  filters: filters(),
  seatMapPlan: [],
  seatMapPlanForCabins: {},
  projects: {},
  routeFrequencies: {},
  countries: [],
  vessels: [],
  residentCities: {},
  residentDocTypes:[],
  availablePaymentMethods: [],
  bookingPaymentMethods: [],
  companiesForSearch: [],
  agencyService: {},
  remarks: '',
  leaderPhone: '',
  leaderMail: '',
  leaderEmergencyPhone: '',
  attributeCombinationsPassengers: [],
  attributeCombinationsVehicles: [],
  attributeCombinationsTrucks: [],
  vehiclesConfigurations: [],
  truckSearchResults: [],
  checked: true,
  fromSaved:false,
  fromStep2:false,
  hasProjectSelected: false,
  hasParcelsenderNameError: false,
  hasParcelsenderPhoneError: false,
  hasParcelreceiverNameError: false,
  hasParcelreceiverPhoneError: false,
  hasParcelQuantityValidationError: false,
  hasParcelPriceListValidationError: false,
  hasParcelComValidationError: false,
  hasParcelValidationError: false,
  mailHasError: false,
  openReturn: false,
  fetchAvailabilities: false,
  availableAttributes: '',
  flag: 0,
  focusedTripIndex: 0,
  selectedDate: '',
  date1: '',
  date2: '',
  date3: '',
  date4: '',
  isMAN: false,
  lockNationalityResidents:false,
  hasLoyaltyCard: false,
  isValidating: false,
  selectedProject: null,
  selectedProjectInfo: null,
  validateCoupon: false,
  coupon: '',
  deal:'',
  disabledPassengersVehicles: false,
  storeSelectedPassengersNumber:0,
  financialModalStatus: false,
  selectedTripAbbreviation: '',
})

export const state = () => initialState()

export const isTripWithConjuction = trip => {
  if (Object.keys(_groupBy(trip.intermediateStops, 'vessel.name')).length >= 2) {
    return true
  } else {
    return false
  }
}

export const getters = {
  getFlag: get('flag'),

  getIsMAN: get('isMAN'),

  getLockNationalityResidents: get('lockNationalityResidents'),

  getSelectedDate: get('selectedDate'),

  getDate1: get('date1'),

  getDate2: get('date2'),

  getDate3: get('date3'),

  getDate4: get('date4'),

  getIsValidating: get('isValidating'),

  getFinancialModalStatus: get('financialModalStatus'),

  getHasLoyaltyCard: get('hasLoyaltyCard'),

  getFocusedTripIndex: get('focusedTripIndex'),

  getOpenReturn: get('openReturn'),

  getHasParcel: get('parcel'),

  getSelectedProject: get('selectedProject'),

  getSelectedProjectInfo: get('selectedProjectInfo'),

  getCoupon: get('coupon'),

  getDeal: get('deal'),

  getValidateCoupon: get('validateCoupon'),

  getParcelPricing: getOr('parcelPricing', []),

  getDisableKeyboard: get('disableKeyboard'),

  getLoadingTrips: get('loadingTrips'),

  getLeaderEmail: get('leaderMail'),

  getcompaniesForSearch: get('companiesForSearch'),

  getSelectedTripAbbreviation: get('selectedTripAbbreviation'),

  getAvailablePrinters: get('availablePrinters'),

  getInsurance: get('insurance.details'),

  getInsuranceConfirm: getOr('insurance.confirm', false),

  getInsuranceProductOffer: get('insurance.productOffer'),

  getAddedPassengers: get('addedPassengers'),

  getAddedExtraService: get('addedExtraService'),
    
  getPrintFinalTicket: get('printFinalTicket'),

  getAddedVehicles: get('addedVehicles'),

  getAvailableCompanies: get('availableCompanies'),

  getTrucksCombinationsNumber: combinationsTotal('attributeCombinationsTrucks'),

  getPassengersCombinationsNumber: combinationsTotal('attributeCombinationsPassengers'),

  getVehiclesCombinationsNumber: combinationsTotal('attributeCombinationsVehicles'),

  getSeatMapPlan: getOr('seatMapPlan', []),

  getIsPTA: getOr('isPTA', false),

  getOtherAccount: getOr('otherAccount', {}),

  getAvailableAttributes: getOr('availableAttributes', {}),

  getCompanies: getOr('companies', {}),

  getAvailableProjects: getOr('projects', []),

  getDisabledPassengersVehicles: get('disabledPassengersVehicles'),

  getGroupName: getOr('groupName', ''),

  getEmergencyPhoneLeader: getOr('leaderEmergencyPhone', ''),

  getLeaderMail: getOr('leaderMail', ''),

  getLeaderPhone: getOr('leaderPhone', ''),

  getTruckSearchResults: getOr('truckSearchResults', []),

  getTruckSearchResultsCombined(state, getters) {
    const isDefault = state.truckSearchResults[state.flag]?.default
      ? state.truckSearchResults[state.flag].default
      : false
    const defaultattrs = state.truckSearchResults[state.flag]?.DefaultProductAttributes
    const secondaryAttrs = state.truckSearchResults[state.flag]?.SecondaryProductAttributes
    const attrs = state.truckSearchResults[state.flag]?.Attributes
    // return defaultattrs?.concat(attrs).map(attr => ({ ...attr, Value: +attr.Value }))
    return attrs
      ?.concat(isDefault ? defaultattrs : secondaryAttrs)
      .map(attr =>
        getters.getAttributeById(attr.AttributeId).datatype === 'NUMERIC' ? { ...attr, Value: +attr.Value } : attr
      )
  },

  getCombinationsPassengers: getOr('attributeCombinationsPassengers', []),

  getCombinationsVehicles: getOr('attributeCombinationsVehicles', []),

  getCombinationsTrucks: getOr('attributeCombinationsTrucks', []),

  getVehiclesConfigurations: getOr('vehiclesConfigurations', []),

  getAvailablePaymentMethods: getOr('availablePaymentMethods', {}),

  getAvailableBookingPaymentMethods: getOr('bookingPaymentMethods', {}),

  isTripSupportMultiple: getOr(
    'availablePaymentMethods.paymentMethodResponsePerCompanies[0].supportsPartialPayments',
    {}
  ),

  getAvailableCountries: getOr('countries', []),

  getAvailableVessels: getOr('vessels', []),

  getResidentCities: getOr('residentCities', {}),

  getResidentDocTypes: getOr('residentDocTypes', []),
  

  getSeatMapPlanForCabins: getOr('seatMapPlanForCabins', []),

  getPassengersNumber: getOr('selectedRoutes.routesExtras.travellers', 0),

  getVehiclesNumber: getOr('selectedRoutes.routesExtras.vehicles', 0),

  getTypeOfTrip: getOr('selectedRoutes.routesExtras.typeOfTrip', 1),

  getSelectedRoutes: getOr('selectedRoutes.routes', []),

  getSelectedRoutesExtras: get('selectedRoutes.routesExtras'),


  getSelectedTrips(state) {
    return _sortBy(_sortBy(_get(state, 'selectedTrips', []), o => o?.routeId), i => i?.departureDateTime).filter(item => item !== undefined)
  },

  getSelectedTripsCheckDouble: get('selectedTripsCheckDouble'),

  getSelectedConjuctionsTrips: get('selectedConjuctionsTrips'),

  getFilters: get('filters'),

  getCombinedTrips(state) {
    return _get(state, 'combinedTrips', [])
      .map(route => route.trips)
      .flat()
  },

  getTripSelectedPackages: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'selectedPackages', [])
  },

  getTripSelectedParcels: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'selectedParcels', {})
  },

  getTripParcels: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'parcels', {})
  },

  getTripSelectedPassengers: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'selectedPassengers', [])
  },

  getTripSelectedVehicles: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'selectedVehicles', [])
  },

  getTripExtraServices: state => trip => {
    const tr = findOneBy('selectedTrips', tripId)(state)(trip)
    return _get(tr, 'additionalServices', [])
  },

  getTripBy: findOneBy('selectedTrips', tripId),

  getAttributes: getOr('attributes', []),

  isTripSelected: state => trip =>
    existInListBy('selectedTrips', tripId)(state)(trip) ||
    existInListBy('selectedConjuctionsTrips', tripId)(state)(trip),

  searchWithTrucks: getOr('truckSearch', false),

  getListOfTripsData: getOr('listOfTrips', {}),

  getRoutesAvailable: getOr('routes', {}),

  getRouteFrequencies: getOr('routeFrequencies', {}),

  searchWithPackages: getOr('packageSearch', false),
  
  searchWithConjuctions: getOr('conjuctionsSearch', false),
  
  searchWithGroups: getOr('groupSearch.status', false),

  getCompanyById: state => idOrCode => _get(state, ['companies', idOrCode]),

  getLocationById: state => idOrCode => _get(state, ['locations', idOrCode]),

  getAttributeById: state => idOrCode => _get(state, ['attributes', idOrCode]),

  getDiscountById: state => idOrCode => _get(state, ['discounts', idOrCode]),

  getPricingTrips: getOr('pricing.trips', []),

  getTripPrices: state => trip => {
    const tr = findOneBy('pricing.trips', tripId)(state)(trip)
    return _get(tr, 'prices', [])
  },

  getDataPricing: state => {
    return state.dataPricing;
  },

  getTripAccommodation: state => trip => {
    const tr = findOneBy('pricing.trips', tripId)(state)(trip)
    return _get(tr, 'passengers', [])
  },

  getBookingRemarks: getOr('remarks', ''),

  getBookingLeader: getOr('booking.leader', {}),

  getBookingTrips: getOr('booking.trips', []),

  getBooking: getOr('booking', []),

  getBookingNumber: get('booking.crsReservationId'),

  getBookingOptionDate: get('booking.optionDateTime'),

  getBookingSupportsVoucherGeneration: get('booking.supportsVoucherGeneration'),

  getBookingPdfVoucher: get('booking.pdfVoucher'),

  /**
   * Getter used for checking if will the step 3 form be displayed
   * @param {*} state
   */
  useAttributesStep(state) {
    return getOr('selectedTrips', [])(state).some(t => _get(t, 'validationAttributes.bookingAttributes.passengers.length'))
  },

  getChecked: get('checked'),

  getFromSaved: get('fromSaved'),

  getHasProjectSelected: get('hasProjectSelected'),

  getHasExtraService: get('hasExtraService'),

  getFromStep2: get('fromStep2'),

  gethasParcelsenderNameError: get('hasParcelsenderNameError'),

  gethasParcelsenderPhoneError: get('hasParcelsenderPhoneError'),

  gethasParcelreceiverNameError: get('hasParcelreceiverNameError'),
  
  gethasParcelreceiverPhoneError: get('hasParcelreceiverPhoneError'),

  gethasParcelValidationError: get('hasParcelValidationError'),

  gethasParcelComValidationError: get('hasParcelComValidationError'),

  gethasParcelPriceListValidationError: get('hasParcelPriceListValidationError'),

  gethasParcelQuantityValidationError: get('hasParcelQuantityValidationError'),
  getMailHasError: get('mailHasError'),

  hasTicketFilled: state => state.selectedTrips.some(trip =>
    Object.values(_get(trip, 'selectedPassengers', '')).some(p => (p.ticketLetter && p.ticketLetter !== '' || p.ticketNumber && p.ticketNumber !== ''))
    || Object.values(_get(trip, 'selectedVehicles','')).some(v => (v.ticketLetter && v.ticketLetter !== '' || v.ticketNumber && v.ticketNumber !== ''))
  ),
}

export const mutations = {
  SET_FLAG: set('flag'),

  SET_FINANCIAL_MODAL_STATUS: set('financialModalStatus'),

  SET_IS_MAN: set('isMAN'),

  SET_LOCK_NATIONALITY_RESIDENTS: set('lockNationalityResidents'),

  SET_SELECTED_DATE: set('selectedDate'),

  SET_DATE1: set('date1'),  

  SET_DATE2: set('date2'),

  SET_DATE3: set('date3'),

  SET_DATE4: set('date4'),

  SET_IS_VALIDATING: set('isValidating'),

  SET_HAS_LOYALTY_CARD: set('hasLoyaltyCard'),

  OPEN_RETURN_CHANGE: set('openReturn'),

  PARCEL_CHANGE: set('parcel'),

  SET_SEAT_MAP: set('seatMapPlan'),

  SET_SAVED_STATE_NAME: set('savedStateName'),

  SET_SELECTED_TRIP_ABBREVIATION: set('selectedTripAbbreviation'),

  SET_DAYS_TO_SEARCH: set('daysToSearch'),

  SET_ADDED_PASSENGERS: set('addedPassengers'),

  SET_ADDED_EXTRA_SERVICE: set('addedExtraService'),

  SET_PRINT_FINAL_TICKET: set('printFinalTicket'),
  

  SET_INSURANCE_CLEAR(state, payload) {
    const empty = {
      details: {},
      confirm: false,
      productOffer: []
    }
    Vue.set(state, 'insurance', empty)
  },

  SET_ADDED_VEHICLES: set('addedVehicles'),

  SET_DISABLED_PASSENGERS_VEHICLES: set('disabledPassengersVehicles'),

  SET_PTA: set('isPTA'),

  SET_SPECIFIC_TIME: set('specificTime'),

  SET_SEAT_MAP_FOR_CABINS: set('seatMapPlanForCabins'),

  UPDATE_ROUTE_BY_INDEX: updateOrCreateListItemByIndex('selectedRoutes.routes'),

  SET_DEFAULT_ROUTE_FREQUENCIES: set('routeFrequencies'),

  UPDATE_PASSENGER_COMBINATION_BY_INDEX: updateOrCreateListItemByIndex('attributeCombinationsPassengers'),

  UPDATE_TRUCK_COMBINATION_BY_INDEX: updateOrCreateListItemByIndex('attributeCombinationsTrucks'),

  UPDATE_VEHICLE_CONFIGURATION_BY_INDEX: updateOrCreateListItemByIndex('attributeCombinationsVehicles'),

  UPDATE_SELECTED_TRIP: updateOrCreateListItemBy('selectedTrips', tripId),

  ADD_TRIP_TO_SELECTED: addToList('selectedTrips'),

  ADD_TRIP_TO_SELECTED_CONJUCTIONS_TRIPS: addToList('selectedConjuctionsTrips'),

  SET_SELECTED_TRIPS: set('selectedTrips'),

  SET_SELECTED_TRIPS_CHECK_DOUBLE: set('selectedTripsCheckDouble'),

  SET_PASSENGERS_COMBINATIONS: set('attributeCombinationsPassengers'),

  SET_TRUCKS_COMBINATIONS: set('attributeCombinationsTrucks'),

  STORE_AVAILABLE_PRINTERS: set('availablePrinters'),

  STORE_AVAILABLE_COMPANIES: set('availableCompanies'),

  SET_VEHICLES_COMBINATIONS: set('attributeCombinationsVehicles'),

  SET_BOOKING: set('booking'),

  REMOVE_TRIP_FROM_SELECTED: removeFromListBy('selectedTrips', tripId),

  REMOVE_TRIP_FROM_SELECTED_CONJUCTIONS_TRIPS: removeFromListBy('selectedConjuctionsTrips', tripId),

  UPDATE_PASSENGERS: set('selectedRoutes.routesExtras.travellers'),

  UPDATE_VEHICLES: set('selectedRoutes.routesExtras.vehicles'),

  // UPDATE_TYPE_OF_TRIP: set('selectedRoutes.routesExtras.typeOfTrip'),
  UPDATE_TYPE_OF_TRIP(state, payload) {
    Vue.set(state.selectedRoutes.routesExtras, 'typeOfTrip', payload)
  },

  SET_SELECTED_ROUTES: set('selectedRoutes.routes'),

  UPDATE_WITH_CABIN_FILTER: set('filters.cabin.hasCabin'),

  UPDATE_WITH_NO_CABIN_FILTER: set('filters.cabin.hasNoCabin'),

  UPDATE_WITH_GARAGE_FILTER: set('filters.garage.hasGarage'),

  UPDATE_WITH_NO_GARAGE_FILTER: set('filters.garage.hasNoGarage'),

  SET_INSURANCE_CONFIRM(state, payload) {
    Vue.set(state.insurance, 'confirm', payload)
    return
  },

  SET_INSURANCE(state, payload) {
    Vue.set(state.insurance, 'details', payload)
  },

  SET_INSURANCE_PRODUCT: addToList('insurance.productOffer'),

  UPDATE_WITH_DANGEROUS_GOODS_FILTER: set('filters.dangerousGoods.hasDangerousGoods'),

  UPDATE_WITH_NO_DANGEROUS_GOODS_FILTER: set('filters.dangerousGoods.hasNoDangerousGoods'),

  UPDATE_DURATION_FILTER: set('filters.duration'),

  // UPDATE_DEPARTURE_TIME_DURATION_FILTER: set('filters.departureTimeDuration'),
  UPDATE_DEPARTURE_TIME_DURATION_FILTER(state, payload) {
    const combinedTimes = payload
    if (combinedTimes.length === 1) combinedTimes[1] = combinedTimes[0]
    state.filters.departureTimeDuration = combinedTimes
  },

  UPDATE_PASS_PRICE_RANGE_FILTER: set('filters.passPriceRange'),

  ADD_POSITION_TYPE_FILTER: addToList('filters.positionType'),

  REMOVE_POSITION_TYPE_FILTER(state, payload) {
    const index = state.filters.positionType.indexOf(payload)
    state.filters.positionType.splice(index, 1)
  },

  ADD_COMPANY_FILTER: addToList('filters.company'),

  REMOVE_COMPANY_FILTER(state, payload) {
    const index = state.filters.company.indexOf(payload)
    state.filters.company.splice(index, 1)
  },

  ADD_SHIP_TYPE_FILTER: addToList('filters.shipType'),

  REMOVE_SHIP_TYPE_FILTER(state, payload) {
    const index = state.filters.shipType.indexOf(payload)
    state.filters.shipType.splice(index, 1)
  },

  ADD_INTERMEDIATE_FILTER: addToList('filters.intermediateStops'),

  REMOVE_INTERMEDIATE_FILTER(state, payload) {
    const index = state.filters.intermediateStops.indexOf(payload)
    state.filters.intermediateStops.splice(index, 1)
  },

  SET_LOADING_TRIPS: set('loadingTrips'),

  SET_FOCUSED_TRIP_INDEX: set('focusedTripIndex'),

  SET_DATA_PRICING: set('dataPricing'),

  SET_PRICING: set('pricing'),

  FETCH_TRIPS_WITH_AVAILABILITIES: set('fetchAvailabilities'),

  UPDATE_LOCATIONS: updateOrSet('locations'),

  CLEAR_PRICING_TRIP_PRICES(state, { trip }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if ( _get( tripToUpdate, 'prices', undefined ) ===  undefined) return;
    tripToUpdate.prices = []

    Object.entries(tripToUpdate.selectedPassengers).forEach(([key, value]) => {
      tripToUpdate.selectedPassengers[key].price = {};
    });
    if (tripToUpdate) Vue.set(tripToUpdate, 'edited', true)
  },

  UPDATE_LIST_OF_TRIPS: updateOrSet('listOfTrips'),

  UPDATE_COMPANIES(state, payload) {
    Object.entries(payload).map(([key, value]) => {
      if (Object.values(state.companies?.[key] || {}).length === 0) {
        Vue.set(state.companies, key, value)
      }
    })
  },
  UPDATE_COMPANIES_SPECIFIC(state, { companies, abr }) {
    if (Object.values(state.companies).length > 1) {
      state.companies[abr].accommodations = companies[abr].accommodations
    }
    else {
      Vue.set(state.companies, abr, companies[abr])
    }
    // state.companies[abr].accommodations = companies[abr].accommodations
  },

  UPDATE_ATTRIBUTES: updateOrSet('attributes'),

  UPDATE_DISCOUNTS: updateOrSet('discounts'),

  // UPDATE_EXTRA_SERVICES(state, payload) {
  //   // this.getAvailableConfigurations.additionalServices.map((service, s) =>
  //   //   service.items.map((item, i) => {
  //   //     const itemToUpdate = this.trip.additionalServices[s].items.find(it => it.name === item.name)
  //   //     const itemIndex = this.trip.additionalServices[s].items.findIndex(item => item === itemToUpdate)
  //   //     if (itemToUpdate) {
  //   //       this.onChangeServiceNumber(item.availableUnits, { serviceIndex: s, itemIndex })
  //   //     }
  //   //   })
  //   // )
  // },

  DISABLE_KEYBOARD(state, payload) {
    state.disableKeyboard = payload
  },
  RESET_STATE(state, payload) {
    Object.assign(state, payload || initialState())
  },

  RESET_FILTERS(state, payload) {
    Object.assign(state.filters, payload || filters())
  },

  SET_AVAILABLE_COUNTRIES: set('countries'),

  SET_AVAILABLE_VESSELS: set('vessels'),

  SET_RESIDENT_CITIES: set('residentCities'),

  SET_RESIDENT_DOCTYPES: set('residentDocTypes'),

  SET_AVAILABLE_PAYMENT_METHODS: set('availablePaymentMethods'),

  SET_AVAILABLE_BOOKING_PAYMENT_METHODS: set('bookingPaymentMethods'),

  SET_AVAILABLE_ATTRIBUTES: set('availableAttributes'),

  SET_COMBINED_TRIPS(state, payload) {
    const routeToUpdate = (typeof payload.route === "number") ? payload.route
      : _get(state, 'selectedRoutes.routes', []).findIndex(
        route => 
          _get(route, 'from.idOrCode') === _get(payload, 'route.from.idOrCode') ||
          _get(route, 'to.idOrCode') === _get(payload, 'route.to.idOrCode') ||
          (
            route.when &&
            moment(route.when).format('YYYY-MM-DD') === moment(payload.route.when).format('YYYY-MM-DD')
          )
    )
    
    if (!state.combinedTrips[routeToUpdate]) {
      state.combinedTrips.push(payload)
    } else {
      Vue.set(state.combinedTrips[routeToUpdate], 'trips', payload.trips)
    }
  },

  SET_TRIP_PARCELS(state, { trip, parcels, serviceIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate)
      Vue.set(tripToUpdate, 'parcels', parcels)
  },

  SET_TRIP_PARCELS_PRICING(state, { trip, pricing }) {
    Vue.set(state, 'parcelPricing', pricing)
  },

  UPDATE_TRIP_EXTRA_SERVICES(state, { trip, service, serviceIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) {
      Vue.set(tripToUpdate, 'edited', true)
      Vue.set(tripToUpdate.additionalServices, serviceIndex, service)
    }
  },
  DELETE_TRIP_EXTRA_SERVICES(state, { trip, service, serviceIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    
    if (tripToUpdate && tripToUpdate.additionalServices.length > serviceIndex) {
      tripToUpdate.additionalServices.splice(serviceIndex, 1);
      Vue.set(tripToUpdate, 'edited', true);
    }
  },
  
  SET_TRIP_AS_EDITED(state, { trip, isEdited }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    if (tripToUpdate) Vue.set(tripToUpdate, 'isEdited', isEdited)
  },

  SET_TRIP_AS_OPEN_RETURN(state, { trip, openReturn }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    if (tripToUpdate) Vue.set(tripToUpdate, 'openReturn', openReturn)
  },

  SET_TRIP_SELECTED_PASSENGER(state, { trip, selectedPassengers }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) Vue.set(tripToUpdate, 'selectedPassengers', selectedPassengers)
  },

  SET_TRIP_SELECTED_VEHICLE(state, { trip, selectedVehicles }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) Vue.set(tripToUpdate, 'selectedVehicles', selectedVehicles)
  },

  SET_TRIP_EXTRA_SERVICE(state, { trip, service, serviceIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate && tripToUpdate.additionalServices[0]?.items.length)
      Vue.set(tripToUpdate.additionalServices, serviceIndex, service)
  },

  UPDATE_TRIP_PASSENGER(state, { trip, passenger, passengerIndex }) {


    if (passengerIndex) {
      const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

      const selectedPassengers = _get(tripToUpdate, 'selectedPassengers')

      let indexPassenger = null
      Object.keys(selectedPassengers).forEach((key, index) => {
        if (passengerIndex === key) {
          indexPassenger = index
        }
      })

      let pricesFilterPassenger = tripToUpdate?.prices.filter(e => {
        return e?.priceAccommodationType == 'PASSENGER';
      })

      let pricesFilterVehicle = tripToUpdate?.prices.filter(e => {
        return e?.priceAccommodationType == 'VEHICLE';
      })


      if (passenger.fieldName == 'seatPlanMap' && pricesFilterPassenger.length > 0) {
        pricesFilterPassenger[indexPassenger].seatPlanMap = true
        pricesFilterPassenger[indexPassenger].accommodationNumberToDisplay = '';
      }


      if (_get(pricesFilterPassenger, '[' + indexPassenger + '].total', '') != '' && 
        (
           passenger.fieldName == 'type' 
        || passenger.fieldName == 'seatType' 
        || passenger.fieldName == 'sexType' 
        || passenger.fieldName == 'discountIdOrCode' 
        )) {
        pricesFilterPassenger[indexPassenger].accommodationNumberToDisplay = '';
        pricesFilterPassenger[indexPassenger].total = '';
      }

      tripToUpdate.prices = [...pricesFilterPassenger, ...pricesFilterVehicle];

      if (tripToUpdate) Vue.set(tripToUpdate, 'edited', true)
      if (tripToUpdate && selectedPassengers) {
        Vue.set(selectedPassengers, passengerIndex, passenger)
        Vue.set(passenger, 'flag', state.flag)
      }

    }
  },
  UPDATE_TRIP_VEHICLE(state, { trip, vehicle, vehicleIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    let pricesFilterVehicle = tripToUpdate?.prices.filter(e => {
      return e?.priceAccommodationType == 'VEHICLE';
    })
    let pricesFilterPassenger = tripToUpdate?.prices.filter(e => {
      return e?.priceAccommodationType == 'PASSENGER';
    })

    let indexVehicle = null
    Object.keys(tripToUpdate.selectedVehicles).forEach((key, index) => {
      if (vehicleIndex === key) {
        indexVehicle = index
      }
    })

    if (_get(pricesFilterVehicle, '[' + indexVehicle + '].accommodationNumber', '') !== '' && (vehicle.fieldName == 'length' || vehicle.fieldName == 'discountIdOrCode')) {
      pricesFilterVehicle[indexVehicle].accommodationNumberToDisplay = '';
      pricesFilterVehicle[indexVehicle].total = '';
    }

    tripToUpdate.prices = [...pricesFilterPassenger, ...pricesFilterVehicle];

    if (tripToUpdate) Vue.set(tripToUpdate, 'edited', true)
    const selectedVehicles = _get(tripToUpdate, 'selectedVehicles')
    if (tripToUpdate && selectedVehicles) Vue.set(selectedVehicles, vehicleIndex, vehicle)
  },

  SORT_PASSENGERS(state, { trip }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    const selectedPassengers = _get(tripToUpdate, 'selectedPassengers')
    const sortedPassengers = Object.entries(selectedPassengers).map(([key, val]) => ({ ...val, passIndex: val.passIndex || 11111, uuid: key })).sort((a, b) => b.passIndex - a.passIndex)
      .reduce((acc, val) => {
        return {
          ...acc,
          [val.uuid]: val
        }
      }, {})
    if (tripToUpdate) Vue.set(tripToUpdate, 'selectedPassengers', sortedPassengers)
  },

  DELETE_ALL_TRIP_PASSENGERS(state, { trip, passengers }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) {
      Vue.set(tripToUpdate, 'selectedPassengers', passengers)
    }
  },
  DELETE_TRIP_PASSENGER(state, { trip, passengerIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    tripToUpdate.prices = [];
    if (tripToUpdate) {
      Vue.delete(tripToUpdate.selectedPassengers, passengerIndex)
    }
  },

  ADD_TRIP_PASSENGER(state, { trip, passenger }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    if (tripToUpdate) {
      const attributes = pathOr({}, ['attributes'], state)
      const tripAttrs = pathOr({}, ['filteringAttributes'], tripToUpdate)
      const attrForFiltering = Object.values(attributes)
        .filter(attr => tripAttrs.find(a => +a.id === attr.id) && attr.type === 'Passenger')
        .map(i => ({ ...i, isRequired: !!tripAttrs.find(a => +a.id === i.id).isRequired }))
      // const attrForFiltering = Object.values(attributes).filter(
      //   attr => attr.isForFiltering && attr.type === 'Passenger'
      // )
      const pass = passenger || createPassenger()
      const newPass = attrForFiltering.reduce((_pass, attr) => {
        const defaultVal = attr.values.find(v => v.default)
        if (defaultVal) {
          Object.assign(_pass, {
            [pricingAttrKey(attr)]: {
              ...attr,
              selectedValue: defaultVal.value
            }
          })
        }
        return _pass
      }, pass)
      if (!tripToUpdate.selectedPassengers) Vue.set(tripToUpdate, 'selectedPassengers', {})
      Vue.set(tripToUpdate.selectedPassengers, newPass[idName(selectedPassengerId)], newPass)
      Vue.set(passenger, 'flag', state.flag)
    }
  },


  UPDATE_TRIP_PACKAGE(state, { trip, packageItem, packageIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    if (tripToUpdate) Vue.set(tripToUpdate, 'edited', true)
    const selectedPackages = _get(tripToUpdate, 'selectedPackages')
    if (tripToUpdate && selectedPackages) Vue.set(selectedPackages, packageIndex, packageItem)
  },

  UPDATE_TRIP_PARCEL(state, { trip, parcel }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)

    if (tripToUpdate) Vue.set(tripToUpdate, 'selectedParcels', parcel)
  },

  DELETE_TRIP_VEHICLE(state, { trip, vehicleIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    tripToUpdate.prices = [];

    if (tripToUpdate) {
      Vue.delete(tripToUpdate.selectedVehicles, vehicleIndex)
    }
  },
  DELETE_TRIP_PACKAGE(state, { trip, packageIndex }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) {
      Vue.delete(tripToUpdate.selectedPackages, packageIndex)
    }
  },

  ADD_TRIP_PACKAGE(state, { trip, packageItem }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) {
      const attributes = pathOr({}, ['attributes'], state)
      // const attrForFiltering = Object.values(attributes).filter(attr => attr.type === 'Vehicle')

      const tripAttrs = pathOr({}, ['filteringAttributes'], tripToUpdate)
      const attrForFiltering = Object.values(attributes).filter(
        attr => tripAttrs.find(a => +a.id === attr.id) && attr.type === 'Package'
      )

      const veh = packageItem || createPackage()
      const newPack = attrForFiltering.reduce((_veh, attr) => {
        const defaultVal = attr.values.find(v => v.default)

        if (defaultVal) {
          Object.assign(_veh, {
            [pricingAttrKey(attr)]: {
              ...attr,
              selectedValue: defaultVal.value
            }
          })
        }
        return _veh
      }, veh)
      if (!tripToUpdate.selectedPackages) Vue.set(tripToUpdate, 'selectedPackages', {})
      Vue.set(tripToUpdate.selectedPackages, newPack[idName(selectedPackId)], newPack)
    }
  },

  ADD_TRIP_VEHICLE(state, { trip, vehicle }) {
    const tripToUpdate = findOneBy('selectedTrips', tripId)(state)(trip)
    if (tripToUpdate) {
      const attributes = pathOr({}, ['attributes'], state)
      // const attrForFiltering = Object.values(attributes).filter(attr => attr.type === 'Vehicle')

      const tripAttrs = pathOr({}, ['filteringAttributes'], tripToUpdate)
      const attrForFiltering = Object.values(attributes).filter(
        attr => tripAttrs.find(a => +a.id === attr.id) && attr.type === 'Vehicle'
      )

      const veh = vehicle || createVehicle()
      const newVeh = attrForFiltering.reduce((_veh, attr) => {
        const defaultVal = attr.values.find(v => v.default)

        if (defaultVal) {
          Object.assign(_veh, {
            [pricingAttrKey(attr)]: {
              ...attr,
              selectedValue: defaultVal.value
            }
          })
        }
        return _veh
      }, veh)
      if (!tripToUpdate.selectedVehicles) Vue.set(tripToUpdate, 'selectedVehicles', {})
      Vue.set(tripToUpdate.selectedVehicles, newVeh[idName(selectedVehcleId)], newVeh)
    }
  },
  SET_BOOKING_REMARKS: set('remarks'),

  SET_ROUTES: set('routes'),

  SET_LEADER_PHONE: set('leaderPhone'),

  SET_LEADER_MAIL: set('leaderMail'),

  SET_PROJECTS: set('projects'),

  SET_SELECTED_PROJECT: set('selectedProject'),

  SET_SELECTED_PROJECT_INFO: set('selectedProjectInfo'),

  SET_COUPON: set('coupon'),

  SET_DEAL: set('deal'),
  
  SET_VALIDATE_COUPON: set('validateCoupon'),

  SET_LEADER_EMERGENCY_PHONE: set('leaderEmergencyPhone'),

  SET_SELECTED_COMPANY_DISCOUNT(state, data) {

    if( _get(state, 'selectedCompanyDiscount', []).some(item => item.index === data.index) ){
      let oldSelectedCompany = _get(state, 'selectedCompanyDiscount', []).filter(item =>  {
        return item.index !== data.index
      });
      if (data.value !== undefined) {
        oldSelectedCompany.push(data)
      }
      Vue.set(state, 'selectedCompanyDiscount', oldSelectedCompany)
    } else {
      state.selectedCompanyDiscount.push(data)
    }

  },

  SEARCH_WITH_TRUCKS: set('truckSearch'),

  SET_COMPANIES_FOR_SEARCH: set('companiesForSearch'),

  SEARCH_WITH_PACKAGES: set('packageSearch'),

  SEARCH_WITH_CONJUCTIONS: set('conjuctionsSearch'),

  SEARCH_WITH_GROUPS: set('groupSearch.status'),

  SET_TRUCK_SEARCH_RESULTS: set('truckSearchResults'),

  UPDATE_TRUCK_SEARCH_RESULTS(state, { defaultProduct, id }) {
    const defaultOrNot = id => {
      if (state.truckSearchResults.DefaultProductId === +id) return true
      if (state.truckSearchResults.SecondaryProductId === +id) return false
    }
    Vue.set(state.truckSearchResults, 'default', defaultOrNot(id))
  },

  SET_OTHER_ACCOUNT: set('otherAccount'),

  UPDATE_GROUP_NAME: set('groupName'),

  UPDATE_MIN_PASSENGERS_GROUP: set('groupSearch.minPassengers'),

  UPDATE_MAX_PASSENGERS_GROUP: set('groupSearch.maxPassengers'),

  UPDATE_PAYMENT_METHODS(state, { name, ammount }) {
    const methodToUpdate = _get(state, 'bookingPaymentMethods', []).find(m => m.name === name)
    // const methodIndex = state.bookingPaymentMethods.findIndex(p => p === methodToUpdate)
    if (methodToUpdate) {
      state.bookingPaymentMethods.forEach((mthd, index) => {
        if (mthd === methodToUpdate) {
          Vue.set(state.bookingPaymentMethods[index], 'ammount', ammount)
        } else {
          Vue.set(state.bookingPaymentMethods[index], 'ammount', 0)
        }
      })
    }
  },

  SET_CHECKED: set('checked'),

  SET_FROM_SAVED: set('fromSaved'),

  SET_HAS_PROJECT_SELECTED: set('hasProjectSelected'),

  SET_HAS_EXTRASERVICE(state, payload) {
    state.hasExtraService = payload;
  },

  SET_FROM_STEP2: set('fromStep2'),

  SET_hasParcelsenderNameError: set('hasParcelsenderNameError'),

  SET_hasParcelsenderPhoneError: set('hasParcelsenderPhoneError'),

  SET_hasParcelreceiverNameError: set('hasParcelreceiverNameError'),
  
  SET_hasParcelreceiverPhoneError: set('hasParcelreceiverPhoneError'),
  
  SET_hasParcelValidationError: set('hasParcelValidationError'),

  SET_hasParcelComValidationError: set('hasParcelComValidationError'),

  SET_hasParcelPriceListValidationError: set('hasParcelPriceListValidationError'),
  
  SET_hasParcelQuantityValidationError: set('hasParcelQuantityValidationError'),
  SET_mailHasError: set('mailHasError'),
}

export const actions = {
  getProjects({ rootGetters, commit }, payload) {
    const createParams = data => ({ token: token(rootGetters) })
    const handleAction = pipeP(createParams, apiRetrieveProjects)
    return handleAction(payload).then(res => {
      commit('SET_PROJECTS', res)
    })
  },
  validateVoucher({ rootGetters, commit }, payload) {
    const createParams = data => ({
      token: token(rootGetters), params: {
        id: payload.id,
        code: payload.code
      }
    })
    const handleAction = pipeP(createParams, apiValidateVoucher)
    return handleAction(payload).then(res => {
      if (res.erroCode == 0) {
        commit('SET_VALIDATE_COUPON', true);
      }
      return res
    })
  },

  setFinancialModalStatus({ commit }, payload) {
    commit('SET_FINANCIAL_MODAL_STATUS', payload);
    if (payload === false)
      commit('SET_SELECTED_TRIP_ABBREVIATION', '');
  },

  getProjectInfo({ rootGetters, commit }, payload) {
    const createParams = data => ({ token: token(rootGetters), params: { id: payload.id } })
    const handleAction = pipeP(createParams, apiRetrieveProjectInfo)
    return handleAction(payload).then(res => {
      commit('SET_SELECTED_PROJECT_INFO', res)
      return res
    })
  },

  setIsMAN({ commit }, payload) {
    commit('SET_IS_MAN', payload)
  },

  setSelectedDate({ commit }, payload) {
    commit('SET_SELECTED_DATE', payload)
  },

  setDate1({ commit }, payload) {
    commit('SET_DATE1', payload)
  },

  setDate2({ commit }, payload) {
    commit('SET_DATE2', payload)
  },

  setDate3({ commit }, payload) {
    commit('SET_DATE3', payload)
  },

  setDate4({ commit }, payload) {
    commit('SET_DATE4', payload)
  },

  setIsValidating({ commit }, payload) {
    commit('SET_IS_VALIDATING', payload)
  },
  setHasLoyaltyCard({ commit }, payload) {
    commit('SET_HAS_LOYALTY_CARD', payload)
  },
  setValidateCoupon({ commit }, payload) {
    commit('SET_VALIDATE_COUPON', payload)
  },
  setDeal({ commit }, payload) {
    commit('SET_DEAL', payload)
  },
  setLoadingTrips({ commit }, payload) {
    commit('SET_LOADING_TRIPS', payload)
  },
  setFlag({ commit }, payload) {
    commit('SET_FLAG', payload)
  },
  getPassengerTypes({ rootGetters }, payload) {
    const createParams = data => ({ token: token(rootGetters) })
    const handleAction = pipeP(createParams, availablePassengerTypes)
    return handleAction(payload)
  },
  fetchTripsWithAvailabilities({ rootGetters, commit }, payload) {
    commit('FETCH_TRIPS_WITH_AVAILABILITIES', payload)
  },
  getAvailablePrintingModels({ rootGetters, commit }, payload) {
    const createParams = data => ({ parms: data, token: token(rootGetters) })
    const handleAction = pipeP(createParams, getPrintingModels)
    return handleAction(payload)
  },
  getHarbourLocations({ rootGetters }, payload) {
    return apiHarbourLocations({
      token: token(rootGetters),
    })
  },
  getHarbourLocationsWithFilter({ rootGetters }, payload) {
    const { searchText } = payload
    return apiHarbourLocationsWithFilter({
      searchText,
      token: token(rootGetters),
    })
  },

  getHarbourLocationDestinations({ rootGetters, state }, payload) {
    const { idOrCode } = payload
    const getLocations = pathOr([], [0, 'routes', 0, 'destinations'])

    return apiHarbourRoutesWithFilter({
      searchText: idOrCode,
      token: token(rootGetters),
      includeConjunctions: state.conjuctionsSearch
    }).then(getLocations)
  },
  getRoutes({ rootGetters, commit, state }, payload) {
    return apiRoutes({
      token: token(rootGetters),
      transportationType: 'ST'
    }).then((res) => {
      commit('SET_ROUTES', res)
      return res
    })
  },
  updateGroupName({ commit }, payload) {
    commit('UPDATE_GROUP_NAME', payload)
  },
  updateMinPassengersGroup({ commit }, payload) {
    commit('UPDATE_MIN_PASSENGERS_GROUP', payload)
  },

  updateMaxPassengersGroup({ commit }, payload) {
    commit('UPDATE_MAX_PASSENGERS_GROUP', payload)
  },

  getTripsPerDayForRoute({ rootGetters, state }, payload) {
    if (state.daysToSearch > 0) {
      const body = data =>
        TripPerDayRequestBodyDTO({
          daysToSearch: state.daysToSearch || 0,
          company: data?.company?.length ? JSON.parse(pathOr('', ['company'], data)) : undefined,
          departureDate: pathOr('', ['when'], data),
          specificTime: state.specificTime,
          fetch: pathOr(undefined, ['fetch'], data),
          originIdOrCode: pathOr('', ['from', 'idOrCode'], data),
          destinationIdOrCode: pathOr('', ['to', 'idOrCode'], data)
        })
      const createRequestParams = data => ({
        body: body(data),
        token: token(rootGetters)
      })
      const getTripsProp = pathOr([], ['trips'])
      const handleError = er => {
        return []
      }
      const handleAction = pipeP(createRequestParams, apiTripsPerDay, getTripsProp)
      return handleAction(payload).catch(handleError)
    }
  },

  getListOfTrips({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return TripRequestBodyDTO({
        departureDate: pathOr('', ['when'], data),
        specificTime: state.specificTime,
        originIdOrCode: pathOr('', ['from', 'idOrCode'], data),
        destinationIdOrCode: pathOr('', ['to', 'idOrCode'], data),
        fetch: pathOr(undefined, ['fetch'], data),
        company: data?.company?.length ? { ...JSON.parse(pathOr('', ['company'], data)), vehicleAvailability: undefined } : undefined
      })
    }

    const createTripsParams = data => ({
      project: state.selectedProject || undefined,
      body: data.map(function (currentRoute) {
        return body(currentRoute)
      }),
      token: token(rootGetters)
    })

    const keepDataToStore = data => {
      if (!data.tripsWithDictionary) data.tripsWithDictionary = []
      commit('UPDATE_LIST_OF_TRIPS', data)
      return data
    }

    const handleError = er => {
      console.error(er)
      return []
    }

    const handleAction = pipeP(createTripsParams, state.selectedProject ? apiProjectListOfTrips : apiListOfTrips, keepDataToStore)
    return handleAction(payload).catch(handleError)
  },

  getTripsForOneRoute({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return TripRequestBodyDTO({
        departureDate: pathOr('', ['when'], data),
        specificTime: state.specificTime,
        originIdOrCode: pathOr('', ['from', 'idOrCode'], data),
        destinationIdOrCode: pathOr('', ['to', 'idOrCode'], data),
        fetch: pathOr(undefined, ['fetch'], data),
        company: data?.company?.length ? { ...JSON.parse(pathOr('', ['company'], data)), vehicleAvailability: undefined } : undefined
      })
    }
    const createTripsParams = data => ({
      project: state.selectedProject || undefined,
      body: body(data),
      token: token(rootGetters)
    })

    const combinedTripsTimes = propName =>
      getters.getCombinedTrips.map(trip =>
        parseInt(
          moment(trip[propName === 'arrival' ? 'arrivalDateTime' : 'departureDateTime'])
            .format('HH:mm')
            .split(':')
            .join('')
        )
      )
    const resetTripsFilters = data => {
      const maxDepartureTime = Math.max(...combinedTripsTimes('departure'))
      const minDepartureTime = Math.min(...combinedTripsTimes('departure'))
      commit('UPDATE_DEPARTURE_TIME_DURATION_FILTER', [minDepartureTime, maxDepartureTime])
      return data
    }

    const keepDataToStore = data => {
      const locations = pathOr({}, ['locations'], data.tripsWithDictionary[0])
      const companies = pathOr({}, ['companies'], data.tripsWithDictionary[0])
      const attributes = pathOr({}, ['attributes'], data.tripsWithDictionary[0])
      const discounts = pathOr({}, ['discounts'], data.tripsWithDictionary[0])

      commit('UPDATE_LOCATIONS', locations)
      commit('UPDATE_COMPANIES', companies)
      commit('UPDATE_ATTRIBUTES', attributes)
      commit('UPDATE_DISCOUNTS', discounts)
      commit('SET_COMBINED_TRIPS', {
        route: payload,
        trips: [..._get(data.tripsWithDictionary[0], 'trips', []) ]
      })
      return data.tripsWithDictionary[0]
    }

    const updatedTrips = trips => {
      setTimeout(() => {
        scrollToId('scroll-anchor-results')
      }, 400)
      return trips.map(trip => ({ ...trip }))
    }

    const getTrips = pathOr([], ['trips'])

    const handleError = er => {
      return []
    }
    const handleAction = pipeP(createTripsParams, state.selectedProject ? apiProjectListOfTrips : apiListOfTrips, keepDataToStore, resetTripsFilters, getTrips, updatedTrips)
        return handleAction(payload).catch(handleError)
  },
  getTripsForRoute({ state, rootGetters, rootState, commit, getters }, payload) {
    const createTripsParams = data => ({
      project: state.selectedProject || undefined,
      body: body(data),
      token: token(rootGetters)
    })


    const combinedTripsTimes = propName =>
      getters.getCombinedTrips.map(trip =>
        parseInt(
          moment(trip[propName === 'arrival' ? 'arrivalDateTime' : 'departureDateTime'])
            .format('HH:mm')
            .split(':')
            .join('')
        )
      )
    const resetTripsFilters = data => {
      const maxDepartureTime = Math.max(...combinedTripsTimes('departure'))
      const minDepartureTime = Math.min(...combinedTripsTimes('departure'))
      commit('UPDATE_DEPARTURE_TIME_DURATION_FILTER', [minDepartureTime, maxDepartureTime])
      return data
    }

    const keepDataToStore = routeId => {
      const data = getters.getListOfTripsData.tripsWithDictionary[routeId]
      const locations = pathOr({}, ['locations'], data)
      const companies = pathOr({}, ['companies'], data)
      const attributes = pathOr({}, ['attributes'], data)
      const discounts = pathOr({}, ['discounts'], data)

      commit('UPDATE_LOCATIONS', locations)
      commit('UPDATE_COMPANIES', companies)
      commit('UPDATE_ATTRIBUTES', attributes)
      commit('UPDATE_DISCOUNTS', discounts)
      commit('SET_COMBINED_TRIPS', {
        route: payload,
        trips: [...data.trips]
      })
      return data
    }

    const updatedTrips = trips => {
      setTimeout(() => {
        scrollToId('scroll-anchor-results')
      }, 400)
      return trips.map(trip => ({ ...trip }))
    }

    const getTrips = pathOr([], ['trips'])

    const handleError = er => {
      console.error(er)
      return []
    }
    const handleAction = pipeP(keepDataToStore, resetTripsFilters, getTrips, updatedTrips)
    return handleAction(payload).catch(handleError)
  },

  apiTransferTickets({ state, rootGetters, rootState, commit, getters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiTransferTickets(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, log('Transfer Trip'), tap)
    return handleAction(payload)
  },

  apiInsurance({ state, rootGetters, rootState, commit, getters }, payload) {
    const createRequestParams = () => {
      return {
        data: BookingRequestBodyDTO({
          alternateAgencyCode: getters.getOtherAccount?.credentials,
          fareIdOrCode: state.selectedCompanyDiscount.length > 1  ? undefined : pathOr(undefined, ['selectedCompanyDiscount[0]'], state),
          trips: getters.getSelectedTrips,
          openReturn: getters.getOpenReturn,
          companies: getters.getCompanies,
          remarks: state.remarks,
          leaderPhone: pathOr('', ['leaderPhone'], state) || '9999999999',
          leaderMail: pathOr('', ['leaderMail'], state),
          emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
          paymentMethods: state.bookingPaymentMethods,
        }),
        token: token(rootGetters),
      }
    }

    const tap = data => {
      commit('SET_INSURANCE', data.insuranceOfferPerCompanyResponses[0])
      return data
    }
    const handleAction = pipeP(createRequestParams, insurance, tap)
    return handleAction(payload)
  },

  apiSendInsurance({ state, rootGetters, rootState, commit, getters }, payload) {
    const createRequestParams = () => {
      return {
        data: {
          ...payload,
          leader: {
            firstName: Object.values( getters.getSelectedTrips[0].selectedPassengers)[0].pricing_attribute_name.selectedValue,
            lastName: Object.values( getters.getSelectedTrips[0].selectedPassengers)[0].pricing_attribute_surname.selectedValue,
            phone: pathOr('', ['leaderPhone'], state) || '9999999999',
            email: pathOr('', ['leaderMail'], state),
          }
        },
        token: token(rootGetters),
      }
    }

    const tap = data => {
      // commit('SET_INSURANCE', data.insuranceOfferPerCompanyResponses[0])
      return data
    }
    const handleAction = pipeP(createRequestParams, sendInsurance, tap)
    return handleAction(payload)
  },

  apiRetrieveBookingsByNameList({ state, rootGetters, rootState, commit, getters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiRetrieveBookingsByNameList(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  apiRetrieveActiveOptionalBookings({ rootGetters }, payload) {
    const createRequestParams = () => ({ params: payload, token: token(rootGetters) })
    const actionToApi = data => apiRetrieveActiveOptionalBookings(data)
    const tap = data => data
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getTripsForTrasnferFunctionality({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return TripRequestBodyDTO({
        departureDate: pathOr('', ['when'], data),
        specificTime: state.specificTime,
        originIdOrCode: pathOr('', ['from', 'idOrCode'], data),
        destinationIdOrCode: pathOr('', ['to', 'idOrCode'], data),
        fetch: pathOr(undefined, ['fetch'], data),
        company: data?.company?.length ? JSON.parse(pathOr('', ['company'], data)) : undefined
      })
    }
    const createTripsParams = data => ({
      body: body(data),
      token: token(rootGetters)
    })
    const keepDataToStore = data => {
      const companies = pathOr({}, ['companies'], data)

      commit('UPDATE_COMPANIES', companies)
      return data
    }

    const handleError = er => {
      return []
    }
    const handleAction = pipeP(createTripsParams, apiTrips, keepDataToStore)
    return handleAction(payload).catch(handleError)
  },

  getTripsForRecallSearch({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return TripRequestBodyDTO({
        departureDate: pathOr('', ['when'], data),
        specificTime: state.specificTime,
        originIdOrCode: pathOr('', ['from', 'idOrCode'], data),
        destinationIdOrCode: pathOr('', ['to', 'idOrCode'], data),
        fetch: pathOr(undefined, ['fetch'], data),
        company: data?.company?.length ? JSON.parse(pathOr('', ['company'], data)) : undefined
      })
    }
    const createTripsParams = data => ({
      body: body(data),
      token: token(rootGetters)
    })
    // const getTrips = pathOr([], ['trips'])

    const keepDataToStore = data => {
      const locations = pathOr({}, ['locations'], data)
      const companies = pathOr({}, ['companies'], data)

      commit('UPDATE_LOCATIONS', locations)
      commit('UPDATE_COMPANIES', companies)
      return data
    }

    const handleError = er => {
      return []
    }
    const handleAction = pipeP(createTripsParams, apiTrips, keepDataToStore)
    return handleAction(payload).catch(handleError)
  },

  fetchAvailabilitiesWithListOfTrips({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return {
        trips: data.trips.map(t => {
          return TripRequestReferenceBodyDTO({
            departureDateTime: pathOr('', ['departureDateTime'], t),
            arrivalDateTime: pathOr('', ['arrivalDateTime'], t),
            origin: pathOr('', ['origin',], t),
            destination: pathOr('', ['destination'], t),
            vessel: pathOr('', ['vessel'], t)
          })
        })
      }
    }
    const createTripsParams = data => ({
      body: body(data),
      token: token(rootGetters)
    })

    const keepDataToStore = data => {
      const locations = pathOr({}, ['locations'], data)
      const companies = pathOr({}, ['companies'], data)
      const attributes = pathOr({}, ['attributes'], data)
      const discounts = pathOr({}, ['discounts'], data)

      commit('UPDATE_LOCATIONS', locations)
      commit('UPDATE_ATTRIBUTES', attributes)
      commit('UPDATE_DISCOUNTS', discounts)
      return data
    }
    const getTrips = pathOr([], ['trips'])

    const tap = data => {
      return data
    }

    const handleError = er => {
      return []
    }
    const handleAction = pipeP(createTripsParams, apiPricingBookingReferenceData, keepDataToStore, tap)
    return handleAction(payload).catch(handleError)
  },
  findTripsForRouteWithAvailability({ state, rootGetters, rootState, commit, getters }, payload) {
    const body = data => {
      return TripRequestBodyDTO({
        departureDate: pathOr('', ['when'], data) || data.trip.departureDateTime,
        departureTime: pathOr('', ['departureTime'], data),
        originIdOrCode:  data.trip.origin.idOrCode || pathOr('', ['from', 'idOrCode'], data),
        destinationIdOrCode:  data.trip.destination.idOrCode || pathOr('', ['to', 'idOrCode'], data),
        fetch: pathOr(undefined, ['fetch'], data),
        company: pathOr('', ['vessel', 'company'], data),
        arrivalTime: pathOr('', ['arrivalDatetime'], data),
        arrivalDate: pathOr('', ['arrivalDatetime'], data) || data.trip.arrivalDateTime,
        fetchIntermediaryStops: true,
        fetchExtraServices: true,
        projectId: state.selectedProject ? state.selectedProject : undefined,
        vesselIdOrCode: pathOr('', ['vessel', 'idOrCode'], data)
      })
    }
    const createTripsParams = data => ({
      body: body(data),
      token: token(rootGetters)
    })

    const keepDataToStore = data => {
      if (data.trips) {
        const locations = pathOr({}, ['locations'], data)
        const companies = pathOr({}, ['companies'], data)
        const attributes = pathOr({}, ['attributes'], data)
        const discounts = pathOr({}, ['discounts'], data)

        companies[data?.trips?.[0].vessel.company.abbreviation].accommodations.additionalServices = data?.trips?.[0].additionalServices

        commit('UPDATE_LOCATIONS', locations)
        commit('UPDATE_COMPANIES_SPECIFIC', { companies, abr: data?.trips?.[0].vessel.company.abbreviation })
        commit('UPDATE_ATTRIBUTES', attributes)
        commit('UPDATE_DISCOUNTS', discounts)
      }
      return data
    }

    const combinedTripsTimes = propName =>
      getters.getCombinedTrips.map(trip =>
        parseInt(
          moment(trip[propName === 'arrival' ? 'arrivalDateTime' : 'departureDateTime'])
            .format('HH:mm')
            .split(':')
            .join('')
        )
      )
    const combinedTripsPrices = propName => {
      return getters.getCombinedTrips.map(trip => trip[propName] || 0)
    }

    const resetTripsFilters = data => {
      const maxDepartureTime = Math.max(...combinedTripsTimes('departure'))
      const minDepartureTime = Math.min(...combinedTripsTimes('departure'))
      commit('UPDATE_DEPARTURE_TIME_DURATION_FILTER', [minDepartureTime, maxDepartureTime])

      const minPrice = Math.min(...combinedTripsPrices('basicPrice'))
      const maxPrice = Math.max(...combinedTripsPrices('basicPrice'))
      if (minPrice > 0 && maxPrice > 0) commit('UPDATE_PASS_PRICE_RANGE_FILTER', [minPrice, maxPrice])
      return data
    }

    const handleError = er => {
      return []
    }
    const handleAction = pipeP(createTripsParams, apiTripsAvailability, keepDataToStore, resetTripsFilters)
    return handleAction(payload).catch(handleError)
  },
  setSelectedCompanyDiscount({ state, commit }, payload) {
    commit('SET_SELECTED_COMPANY_DISCOUNT', payload)
  },
  setSelectedRoutes({ commit }, payload) {
    commit('SET_SELECTED_ROUTES', payload)
  },
  addIntermediateFilter({ commit }, payload) {
    commit('ADD_INTERMEDIATE_FILTER', payload)
  },

  removeIntermediateFilter({ commit }, payload) {
    commit('REMOVE_INTERMEDIATE_FILTER', payload)
  },

  addShipTypeFilter({ commit }, payload) {
    commit('ADD_SHIP_TYPE_FILTER', payload)
  },

  removeShipTypeFilter({ commit }, payload) {
    commit('REMOVE_SHIP_TYPE_FILTER', payload)
  },

  addCompanyFilter({ commit }, payload) {
    commit('ADD_COMPANY_FILTER', payload)
  },

  removeCompanyFilter({ commit }, payload) {
    commit('REMOVE_COMPANY_FILTER', payload)
  },

  updateWithCabinFilter({ commit }, payload) {
    commit('UPDATE_WITH_CABIN_FILTER', payload)
  },

  updateWithNoCabinFilter({ commit }, payload) {
    commit('UPDATE_WITH_NO_CABIN_FILTER', payload)
  },

  updateWithGarageFilter({ commit }, payload) {
    commit('UPDATE_WITH_GARAGE_FILTER', payload)
  },

  updateWithNoGarageFilter({ commit }, payload) {
    commit('UPDATE_WITH_NO_GARAGE_FILTER', payload)
  },
  updateWithDangerousGoodsFilter({ commit }, payload) {
    commit('UPDATE_WITH_DANGEROUS_GOODS_FILTER', payload)
  },

  updateWithNoDangerousGoodsFilter({ commit }, payload) {
    commit('UPDATE_WITH_NO_DANGEROUS_GOODS_FILTER', payload)
  },

  updateDurationFilter({ commit }, payload) {
    commit('UPDATE_DURATION_FILTER', payload)
  },

  updateDepartureTimeDurationFilter({ commit }, payload) {
    commit('UPDATE_DEPARTURE_TIME_DURATION_FILTER', payload)
  },

  updatePassPriceRangeFilter({ commit }, payload) {
    commit('UPDATE_PASS_PRICE_RANGE_FILTER', payload)
  },

  addPositionTypeFilter({ commit }, payload) {
    commit('ADD_POSITION_TYPE_FILTER', payload)
  },

  removePositionTypeFilter({ commit }, payload) {
    commit('REMOVE_POSITION_TYPE_FILTER', payload)
  },

  updatePassengers({ commit }, payload) {
    if (payload < 0) {
      commit('UPDATE_PASSENGERS', 0)
    } else {
      commit('UPDATE_PASSENGERS', payload)
    }
  },

  updateVehicles({ commit }, payload) {
    if (payload < 0) commit('UPDATE_VEHICLES', 0)
    else commit('UPDATE_VEHICLES', payload)
  },

  updateTypeOfTrip({ commit }, payload) {
    commit('UPDATE_TYPE_OF_TRIP', payload)
  },
  // 
  setFocusedTripIndex({ commit }, payload) {
    commit('SET_FOCUSED_TRIP_INDEX', payload)
  },
  addTripToSelected({ commit, state, getters, rootGetters }, trip) {
    if (isTripWithConjuction(trip) && state.conjuctionsSearch) {
      commit('ADD_TRIP_TO_SELECTED_CONJUCTIONS_TRIPS', createSelectedPassengerVehicles(trip, getters))
      // const log = st => data => {
      //   return data
      // }
      // group by vesssel
      const groupByVessel = intermediateStops => _groupBy(intermediateStops, 'vessel.name')

      // make array
      const toArray = obj => Object.values(obj)

      // foreach grouped intermediate stops array create request body
      const createRequestBody = groupedIntermediateStops =>
        groupedIntermediateStops
          // .filter(stops => stops.length > 1)
          .map(intStops => {
            //
            const body = {
              forTrucks: state.truckSearch,
              forPackages: state.packageSearch,
              forGroups: state.groupSearch.status,
              includeConjunctions: state.conjuctionsSearch
            }

            if (intStops.length > 1) {
              body.departureDate = intStops[0].departureDateTime
              body.originIdOrCode = intStops[0].originIdOrCode
              body.destinationIdOrCode = intStops[intStops.length - 1].destinationIdOrCode
              body.departureTime = pathOr(undefined, ['departureDateTime'], intStops[0]).split(' ')[1]
              body.arrivalTime = pathOr(undefined, ['arrivalDatetime'], intStops[0]).split(' ')[1]
              body.arrivalDate = intStops[0].arrivalDateTime
            } else {
              body.departureDate = intStops[0].departureDateTime
              body.originIdOrCode = intStops[0].originIdOrCode
              body.destinationIdOrCode = intStops[0].destinationIdOrCode
              body.departureTime = pathOr(undefined, ['departureDateTime'], intStops[0]).split(' ')[1]
              body.arrivalTime = pathOr(undefined, ['arrivalDatetime'], intStops[0]).split(' ')[1]
              body.arrivalDate = intStops[0].arrivalDateTime
            }

            return body
          })

      // foreach request body call trips
      const apiCallTrips = requestBodies =>
        requestBodies.map(body =>
          apiTrips({
            body: TripRequestBodyDTO(body),
            token: token(rootGetters)
          })
            .then(pathOr([], ['trips']))
            .catch(er => null)
        )

      const toOnePromise = arOfPromises => Promise.all(arOfPromises)

      // foreach trip respoonse find trip requested
      /**
       * TODO: findOneBy('selectedTrips', tripId)({ selectedTrips: trips })({
              origin: { idOrCode: intStop.originIdOrCode },
              destination: { idOrCode: intStop.destinationIdOrCode },
              arrivalDateTime: intStop.arrivalDateTime,
              departureDateTime: intStop.departureDateTime,
              vessel: intStop.vessel
            })
       */
      const mapTrips = arrayOfTrips =>
        arrayOfTrips
          .filter(t => t)
          .map(trips =>
            trips && trips[0]
              ? {
                ...trips[0],
                isConjuction: true,
                isConjuctionFrom:
                  trip.origin.idOrCode + trip.destination.idOrCode + trip.departureDateTime + trip.vessel.idOrCode
              }
              : trips
          )

      const setSelectedTrips = trips =>
        trips.map(t => commit('ADD_TRIP_TO_SELECTED', createSelectedPassengerVehicles(t, getters)))

      // array me trips
      const getTripsFromApiAndSave = pipeP(
        groupByVessel,
        // log('After groupByVessel: '),
        toArray,
        // log('After toArray: '),
        createRequestBody,
        // log('After createRequestBody: '),
        apiCallTrips,
        // log('After apiCallTrips: '),
        toOnePromise,
        // log('After toOnePromise: '),
        mapTrips,
        // log('After mapTrips: '),
        setSelectedTrips
      )

      return getTripsFromApiAndSave(trip.intermediateStops)
    } else if (!getters.isTripSelected(trip)) {
      commit('ADD_TRIP_TO_SELECTED', createSelectedPassengerVehicles(trip, getters))
    }
  },

  removeTripFromSelected({ commit, state }, trip) {
    if (isTripWithConjuction(trip)) {
      const tripsToRemove = state.selectedTrips.filter(
        trip =>
          trip.isConjuctionFrom ===
          trip.origin.idOrCode + trip.destination.idOrCode + trip.departureDateTime + trip.vessel.idOrCode
      )
      commit('SET_SELECTED_TRIPS', tripsToRemove || [])
      commit('REMOVE_TRIP_FROM_SELECTED_CONJUCTIONS_TRIPS', trip)
    } else {
      commit('REMOVE_TRIP_FROM_SELECTED', trip)
    }
  },

  updateTripExtraServiceTest({ commit, state, getters }, { trip, service, units }) {
    const serviceToUpdate = getters.getTripExtraServices(trip)[0].items.find(i => i.name === service)
    const serviceIndexToUpdate = getters.getTripExtraServices(trip)[0].items.findIndex(i => i === serviceToUpdate)
    commit('UPDATE_TRIP_EXTRA_SERVICES', {
      trip,
      service: { items: [{ ...serviceToUpdate, selAvailableUnits: units }] },
      serviceIndex: serviceIndexToUpdate
    })
  },
  
  deleteTripExtraService({ commit, state }, { trip, service, serviceIndex }) {
    commit('DELETE_TRIP_EXTRA_SERVICES', {
      trip,
      service,
      serviceIndex
    })
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
  },
  updateTripExtraService({ commit, state }, { trip, service, serviceIndex }) {
    commit('UPDATE_TRIP_EXTRA_SERVICES', {
      trip,
      service,
      serviceIndex
    })
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
  },

  updateBookingPayment({ commit }, { name, ammount }) {
    commit('UPDATE_PAYMENT_METHODS', {
      name,
      ammount
    })
  },

  sortPassengers({ commit, state, getters }, { trip }) {
    commit("SORT_PASSENGERS", { trip })
  },

  updateTripPassenger({ commit, state, getters }, { trip, passenger, passengerIndex, copy = false }) {
    commit('UPDATE_TRIP_PASSENGER', {
      trip,
      passenger,
      passengerIndex
    })
    if (copy) {
      if (getters.getSelectedTrips[0] === trip) {
        let TripArr = []

        const firstSelPass = () =>
          Object.entries(getters.getSelectedTrips[0].selectedPassengers)
            .reduce((_ob, [id, data]) => {
              const finalObj = Object.entries(data).filter(([key, value]) => key.indexOf('booking_attribute_') === 0).reduce((_obj, [key, value]) => ({ ..._obj, [key]: value }), {})
              TripArr.push({ ...finalObj, id })

              return _uniqBy(TripArr, e => e.id)

            }, {})
        getters.getSelectedTrips.slice(1).forEach(selTrip => {
          const passengersToUpdate = () => {
            return Object.entries(selTrip.selectedPassengers).reduce(
              (_obj, [key, value], i) => ({
                ..._obj,
                [key]: {
                  ...value,
                  ...firstSelPass()[i]
                }
              }),
              {}
            )
          }

          commit('SET_TRIP_SELECTED_PASSENGER', {
            trip: selTrip,
            selectedPassengers: passengersToUpdate()
          })
        })
      }
    }
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
  },

  updateTripVehicle({ commit, state, getters }, { trip, vehicle, vehicleIndex, copy = false }) {
    commit('UPDATE_TRIP_VEHICLE', {
      trip,
      vehicle,
      vehicleIndex
    })
    if (copy) {
      if (getters.getSelectedTrips[0] === trip) {
        let TripArr = []

        const firstSelVeh = () =>
          Object.entries(state.selectedTrips[0].selectedVehicles).reduce((_ob, [id, data]) => {
            const finalObj = Object.entries(data).filter(([key, value]) => key.indexOf('booking_attribute_') === 0).reduce((_obj, [key, value]) => ({ ..._obj, [key]: value }), {})
            TripArr.push({ ...finalObj, id })

            return _uniqBy(TripArr, e => e.id)

          }, {})

        getters.getSelectedTrips.slice(1).forEach(selTrip => {
          const vehiclesToUpdate = () => {
            return Object.entries(selTrip.selectedVehicles).reduce(
              (_obj, [key, value], i) => ({
                ..._obj,
                [key]: {
                  ...value,
                  ...firstSelVeh()[i]
                }
              }),
              {}
            )
          }

          commit('SET_TRIP_SELECTED_VEHICLE', {
            trip: selTrip,
            selectedVehicles: vehiclesToUpdate()
          })
        })
      }
    }
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
  },
  deleteTripPassenger({ commit }, { trip, passengerIndex }) {
    commit('DELETE_TRIP_PASSENGER', {
      trip,
      passengerIndex
    })
  },
  addTripVehicleTest({ state, commit, getters }, { trip, type, acc, val }) {
    const vehicle = createVehicle()
    commit('ADD_TRIP_VEHICLE', {
      trip,
      vehicle: {
        ...vehicle,
        ...val,
        accommodationType: { ...acc, isEdited: true }
      }
    })
    return vehicle[idName(selectedVehcleId)] // return the id of the created passenger
  },

  addTripPassengerTest({ state, commit, getters }, { trip, sex, acc, type, passIndex }) {
    const editedPass = Object.values(getters.getTripSelectedPassengers(trip)).find(
      pas => !pas.accommodationType?.isEdited
    )
    if (editedPass) {
      Object.values(getters.getTripSelectedPassengers(trip)).forEach(selPass => {
        commit('DELETE_TRIP_PASSENGER', {
          trip,
          passengerIndex: editedPass.uuid
        })
      })
    }
    const attributes = trip.validationAttributes.pricingAttributes
    const sexTypeAttr = attributes.passengers.concat(attributes.vehicles).find(a => a.fieldName === 'sexType')
    const typeAttr = attributes.passengers.concat(attributes.vehicles).find(a => a.fieldName === 'type')

    const passenger = createPassenger()
    let passengerUpdated = {}
    if (typeAttr) {
      passengerUpdated = {
        ...passenger,
        [pricingAttrKey(typeAttr)]: { ...typeAttr, selectedValue: 'AD' },
        accommodationType: { ...acc, isEdited: true }
      }
    }
    if (sexTypeAttr) {
      passengerUpdated = {
        ...passengerUpdated,
        ...passenger,
        [pricingAttrKey(sexTypeAttr)]: { ...sexTypeAttr, selectedValue: type || 'MALE' },
        accommodationType: { ...acc, isEdited: true }
      }
    } else {
      passengerUpdated = {
        ...passenger,
        accommodationType: { ...acc, isEdited: true },
        selectedSeat: { exclusive: sex === 'WHOLE' }
      }
    }
    if (passIndex) {
      passengerUpdated = {
        ...passengerUpdated,
        passIndex
      }
    }
    commit('ADD_TRIP_PASSENGER', {
      trip,
      passenger: passengerUpdated
    })
    return passenger[idName(selectedPassengerId)] // return the id of the created passenger
  },
  addTripPassenger({ state, commit }, { trip }) {
    const attributes = trip.validationAttributes.pricingAttributes
    const typeAttr = attributes.passengers.concat(attributes.vehicles).find(a => a.fieldName === 'type')
    const sexTypeAttr = attributes.passengers.concat(attributes.vehicles).find(a => a.fieldName === 'sexType')

    const passenger = createPassenger()
    let passengerUpdated = {}

    if (typeAttr) {
      passengerUpdated = {
        ...passenger,
        [pricingAttrKey(typeAttr)]: { ...typeAttr, selectedValue: 'AD' }
      }
    }
    if (sexTypeAttr) {
      passengerUpdated = {
        ...passengerUpdated,
        ...passenger,
        [pricingAttrKey(sexTypeAttr)]: { ...sexTypeAttr, selectedValue: 'MALE' },
      }
    }
    commit('ADD_TRIP_PASSENGER', {
      trip,
      passenger: passengerUpdated
    })
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
    return passenger[idName(selectedPassengerId)] // return the id of the created passenger
  },
  copyFirstTripToRemaining({ state, commit }) {
    const tripsBiggerThanOne = trips => trips.length >= 2
    const tripPassengers = trip => trip.selectedPassengers
    const tripVehicles = trip => trip.selectedVehicles
    // const tripServices = trip => trip.selectedVehicles

    if (tripsBiggerThanOne(state.selectedTrips)) {
      const firstTripVehicles = () =>
        Object.entries(tripVehicles(state.selectedTrips[0])).reduce(
          (_obj, [key, value]) => ({
            ..._obj,
            [key + Date.now()]: {
              ...value,
              uuid: key + Date.now()
            }
          }),
          {}
        )
      const firstTripPassengers = () =>
        Object.entries(tripPassengers(state.selectedTrips[0])).reduce(
          (_obj, [key, value]) => ({
            ..._obj,
            [key + Date.now()]: {
              ...value,
              uuid: key + Date.now()
            }
          }),
          {}
        )
      const firstTripService = trip => {
        return { items: trip.additionalServices[0]?.items }
      }
      state.selectedTrips.slice(1).forEach(selTrip => {
        commit('SET_TRIP_SELECTED_VEHICLE', {
          trip: selTrip,
          selectedVehicles: firstTripVehicles()
        })
        commit('SET_TRIP_SELECTED_PASSENGER', {
          trip: selTrip,
          selectedPassengers: firstTripPassengers()
        })
        commit('SET_TRIP_EXTRA_SERVICE', {
          trip: selTrip,
          service: firstTripService(state.selectedTrips[0]),
          serviceIndex: 0
        })
      })
    }
    // grab services
  },
  updateTripParcel({ commit, state }, { trip, parcel }) {
    commit('UPDATE_TRIP_PARCEL', { trip, parcel })
  },

  updateTripPackage({ commit, state }, { trip, packageItem, packageIndex }) {
    commit('UPDATE_TRIP_PACKAGE', { trip, packageItem, packageIndex })
  },
  deleteTripVehicle({ commit }, { trip, vehicleIndex }) {
    commit('DELETE_TRIP_VEHICLE', {
      trip,
      vehicleIndex
    })
  },
  deleteTripPackage({ commit }, { trip, packageIndex }) {
    commit('DELETE_TRIP_PACKAGE', {
      trip,
      packageIndex
    })
  },
  addTripPackage({ state, commit }, { trip }) {
    const packageItem = createPackage()
    commit('ADD_TRIP_PACKAGE', {
      trip,
      packageItem
    })
    // if (state.selectedTrips[0] === trip) {
    //   if (!state.selectedTrips.slice(1).some(trip => trip.isEdited)) {
    //     // Grap first trip passengers
    //     const firstSelVeh = () =>
    //       Object.entries(state.selectedTrips[0].selectedVehicles).reduce(
    //         (_obj, [key, value]) => ({
    //           ..._obj,
    //           [key + Date.now()]: {
    //             ...value,
    //             uuid: key + Date.now()
    //           }
    //         }),
    //         {}
    //       )
    //     state.selectedTrips.slice(1).forEach(selTrip => {
    //       commit('SET_TRIP_SELECTED_VEHICLE', {
    //         trip: selTrip,
    //         selectedVehicles: firstSelVeh()
    //       })
    //     })
    //   }
    // }
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
    return packageItem[idName(selectedVehcleId)] // return the id of the created vehicle
  },
  addTripVehicle({ state, commit }, { trip }) {
    const vehicle = createVehicle()
    commit('ADD_TRIP_VEHICLE', {
      trip,
      vehicle
    })
    // if (state.selectedTrips[0] === trip) {
    //   if (!state.selectedTrips.slice(1).some(trip => trip.isEdited)) {
    //     // Grap first trip passengers
    //     const firstSelVeh = () =>
    //       Object.entries(state.selectedTrips[0].selectedVehicles).reduce(
    //         (_obj, [key, value]) => ({
    //           ..._obj,
    //           [key + Date.now()]: {
    //             ...value,
    //             uuid: key + Date.now()
    //           }
    //         }),
    //         {}
    //       )
    //     state.selectedTrips.slice(1).forEach(selTrip => {
    //       commit('SET_TRIP_SELECTED_VEHICLE', {
    //         trip: selTrip,
    //         selectedVehicles: firstSelVeh()
    //       })
    //     })
    //   }
    // }
    commit('SET_TRIP_AS_EDITED', {
      trip,
      isEdited: true
    })
    return vehicle[idName(selectedVehcleId)] // return the id of the created vehicle
  },

  setBookingRemarks({ commit }, { remarks }) {
    commit('SET_BOOKING_REMARKS', remarks)
  },

  setLeaderPhone({ commit }, { phone }) {
    commit('SET_LEADER_PHONE', phone)
  },

  setLeaderMail({ commit }, { mail }) {
    commit('SET_LEADER_MAIL', mail)
  },

  setLeaderEmergencyPhone({ commit }, { emergencyPhone }) {
    commit('SET_LEADER_EMERGENCY_PHONE', emergencyPhone)
  },

  searchWithTrucks({ commit }, { isChecked }) {
    commit('SEARCH_WITH_TRUCKS', isChecked)
  },

  searchWithPackages({ commit }, { isChecked }) {
    commit('SEARCH_WITH_PACKAGES', isChecked)
  },

  searchWithConjuctions({ commit }, { isChecked }) {
    commit('SEARCH_WITH_CONJUCTIONS', isChecked)
  },

  searchWithGroups({ commit }, { isChecked }) {
    commit('SEARCH_WITH_GROUPS', isChecked)
  },
  initializePricingData({ state, commit, getters }) {
    let initializeData = {
      uuid: pricingUuid()
    }
    commit('SET_DATA_PRICING', initializeData)
  },
  async unlockSeatAccomodations({ rootGetters, state, commit, getters, dispatch }, from ) {

    let statePricingData = { ..._get(state.dataPricing, 'responseData', {}) }

    // Get all trips
    const trips = state.selectedTrips.filter(trip => trip !== undefined);

    // Has at least one seat selected from Seat Plan
    const atLeastOneSeatIsSelected = trips
      .some(trip => trip.prices.some(price => price?.accommodationNumber) || Object.values(trip.selectedPassengers)
      .some(passenger => (passenger.selectedSeat && Object.keys(passenger.selectedSeat).length) || (passenger.price && passenger.price?.accommodationNumber))
    );

    // Dummy Array to store { accommodationNumber, idOrCode } of selected seats
    let selectedSeat = [];
    
    trips.forEach(trip => {
      Object.values(trip.selectedPassengers).filter(el => el.selectedSeat).forEach(el => {
        selectedSeat.push(
          {
            accommodationNumber: el.selectedSeat.accommodationNumber,
            idOrCode: el.selectedSeat.accommodationIdOrCode
          }
        );
      })
    });

    // If Pricing has been executed
    if (_get(statePricingData, 'trips', []).length > 0) {
      _get(statePricingData, 'trips', []).map((trip, index) => {

        if (_get(trip, 'prices', []).length > 0) {

          let planItems = []
          _get(trip, 'prices', []).map(passenger => {

            let item = {};
            
            // Has at least one seat selected
            if (selectedSeat.length > 0) {
              item = {
                accommodation: {
                  idOrCode: _get(passenger, 'itemIdOrCode', ''),
                  priceAccommodationType: _get(passenger, 'priceAccommodationType', '')
                },
                accommodationNumber: _get(passenger, 'itemIdOrCode', '') === selectedSeat[0].idOrCode
                  ? selectedSeat[0].accommodationNumber
                  : _get(passenger, 'accommodationNumber', '')
              };
              
              // Update the array
              selectedSeat.shift();
            // No seat selected
            } else {
              item = {
                accommodation: {
                  idOrCode: _get(passenger, 'itemIdOrCode', ''),
                  priceAccommodationType: _get(passenger, 'priceAccommodationType', '')
                },
                accommodationNumber: _get(passenger, 'accommodationNumber', '')
              };
            }
            
            if( !_get(passenger, 'seatPlanMap', false ) ) {
              planItems.push(item)
            }

          })

          dispatch('unlockAccomodationsSeatMap', {
            trip: {
              departureDateTime: trip.departureDateTime,
              origin: { idOrCode: trip.origin.idOrCode },
              destination: { idOrCode: trip.destination.idOrCode },
              vessel: {
                idOrCode: trip.vessel.idOrCode,
                company: {
                  abbreviation: trip.vessel.company.abbreviation
                }
              }
            },
            planItems: planItems,
            returnPlan: false
          })
          commit('CLEAR_PRICING_TRIP_PRICES', { trip: trip })
        }
      })
    // If we are going to redirect
    } else if (from?.name) {
      // Unlock accomodations only when needed. That is only when at least on seat is selected from the seat-map.
      if (atLeastOneSeatIsSelected) {
        trips.forEach(trip=>{
          dispatch('unlockAccomodationsSeatMap', {
            trip: {
              departureDateTime: trip.departureDateTime,
              origin: { idOrCode: trip.origin.idOrCode },
              destination: { idOrCode: trip.destination.idOrCode },
              vessel: {
                idOrCode: trip.vessel.idOrCode,
                company: {
                  abbreviation: trip.vessel.company.abbreviation
                }
              }
            },
            planItems: Object.values(trip.selectedPassengers).filter(el => el.selectedSeat).map(el => {
              return {
                accommodation: {
                  idOrCode: el.selectedSeat.accommodationIdOrCode
                },
                accommodationNumber: el.selectedSeat.accommodationNumber
              }
            }),
            returnPlan: false
          })
        })
      }
    }
  },
  unlockToSave({ rootGetters, state, commit, getters, dispatch }, { keepSeats }){

    let statePricingData = { ..._get(state.dataPricing, 'responseData', {}) }

    async function unlockSeatAccomodations() {
      await _get(statePricingData, 'trips', []).map((trip, index) => {
        let planItems = []
        _get(trip, 'prices', []).map(passenger => {
          let item = {
            accommodation: {
              idOrCode: _get(passenger, 'itemIdOrCode', ''),
              priceAccommodationType: _get(passenger, 'priceAccommodationType', '')
            },
            accommodationNumber: _get(passenger, 'accommodationNumber', '')
          }
          planItems.push(item)
        })

        commit('CLEAR_PRICING_TRIP_PRICES', { trip: trip })

        dispatch('unlockAccomodationsSeatMap', {
          trip: {
            departureDateTime: trip.departureDateTime,
            origin: { idOrCode: trip.origin.idOrCode },
            destination: { idOrCode: trip.destination.idOrCode },
            vessel: {
              idOrCode: trip.vessel.idOrCode,
              company: {
                abbreviation: trip.vessel.company.abbreviation
              }
            }
          },
          planItems: planItems,
          returnPlan: false
        })
      })
    }

    let statePricing = { ...state.dataPricing }
    statePricing.legs = getters.getSelectedTrips.map((i, ind) => {

      let addServices = [];
   
      _get(i, 'additionalServices', []).map(e =>{
        _get(e, 'items', []).map( v =>{
          addServices.push( _get(v, 'selAvailableUnits', 0) )
        })
      })

      let selectedPassengers = _get(i, 'selectedPassengers', {})
      let objectSelectedPassengers = Object.keys(selectedPassengers)
      let selectedVehicles = _get(i, 'selectedVehicles', {})
      let objectSelectedVehicles = Object.keys(selectedVehicles)

      return {
        passengers: objectSelectedPassengers.map((key, index) => {
          return {
            idOrCode: _get(selectedPassengers[key], 'accommodationType.accommodation.idOrCode', ''),
            type: _get(selectedPassengers[key], 'pricing_attribute_type.selectedValue', ''),
            discount: _get(selectedPassengers[key], 'pricing_attribute_discountidorcode.selectedValue', ''),
            birthdate: _get(selectedPassengers[key], 'pricing_attribute_birthdate.selectedValue', ''),
            loyalty:  _get(selectedPassengers[key], 'pricing_attribute_loyaltycard.selectedValue', ''),
            uuid: _get(selectedPassengers[key], 'uuid', ''),
            ...addServices
          }
        }),
        vehicles: objectSelectedVehicles.map((key, index) => {
          return {
            idOrCode: _get(selectedVehicles[key], 'accommodationType.accommodation.idOrCode', ''),
            length: _get(selectedVehicles[key], 'pricing_attribute_length.selectedValue', ''),
            uuid: _get(selectedVehicles[key], 'uuid', ''),
          }
        })
      }
    });
    
    async function checkIfNeedUnlockSeat() {
      await unlockSeatAccomodations();
    }

     checkIfNeedUnlockSeat()
  },
  async getPricing({ rootGetters, state, commit, getters, dispatch }, { keepSeats, checkIfMadePricingOnGoToStep4 }) {

    let statePricingData = { ..._get(state.dataPricing, 'responseData', {}) }

    async function unlockSeatAccomodations() {
   
      await _get(statePricingData, 'trips', []).map((trip, index) => {
        let planItems = []
        _get(trip, 'prices', []).map(passenger => {
          let item = {
            accommodation: {
              idOrCode: _get(passenger, 'itemIdOrCode', ''),
              priceAccommodationType: _get(passenger, 'priceAccommodationType', '')
            },
            accommodationNumber: _get(passenger, 'accommodationNumber', '')
          }
          planItems.push(item)
        })


        commit('CLEAR_PRICING_TRIP_PRICES', { trip: trip })

        dispatch('unlockAccomodationsSeatMap', {
          trip: {
            departureDateTime: trip.departureDateTime,
            origin: { idOrCode: trip.origin.idOrCode },
            destination: { idOrCode: trip.destination.idOrCode },
            vessel: {
              idOrCode: trip.vessel.idOrCode,
              company: {
                abbreviation: trip.vessel.company.abbreviation
              }
            }
          },
          planItems: planItems,
          returnPlan: false
        })
      })

    }




    let statePricing = { ...state.dataPricing }
    statePricing.legs = getters.getSelectedTrips.map((i, ind) => {

      let addServices=[];
   

      _get(i, 'additionalServices', []).map(e =>{
        _get(e, 'items', []).map( v =>{
          addServices.push( _get(v, 'selAvailableUnits', 0) )
        })
      })


      let selectedPassengers = _get(i, 'selectedPassengers', {})
      let objectSelectedPassengers = Object.keys(selectedPassengers)
      let selectedVehicles = _get(i, 'selectedVehicles', {})
      let objectSelectedVehicles = Object.keys(selectedVehicles)

      
      return {
        passengers: objectSelectedPassengers.map((key, index) => {



          return {
            idOrCode: _get(selectedPassengers[key], 'accommodationType.accommodation.idOrCode', ''),
            type: _get(selectedPassengers[key], 'pricing_attribute_type.selectedValue', ''),
            discount: _get(selectedPassengers[key], 'pricing_attribute_discountidorcode.selectedValue', ''),
            birthdate: _get(selectedPassengers[key], 'pricing_attribute_birthdate.selectedValue', ''),
            loyalty:  _get(selectedPassengers[key], 'pricing_attribute_loyaltycard.selectedValue', ''),
            sextype: _get(selectedPassengers[key], 'pricing_attribute_sextype.selectedValue', ''),
            uuid: _get(selectedPassengers[key], 'uuid', ''),
            ...addServices
          }
        }),
        vehicles: objectSelectedVehicles.map((key, index) => {
          return {
            idOrCode: _get(selectedVehicles[key], 'accommodationType.accommodation.idOrCode', ''),
            length: _get(selectedVehicles[key], 'pricing_attribute_length.selectedValue', ''),
            discount: _get(selectedVehicles[key], 'pricing_attribute_discountidorcode.selectedValue', ''),
            uuid: _get(selectedVehicles[key], 'uuid', ''),
          }
        })
      }
    });

    async function checkIfNeedUnlockSeat() {


      if (_get(state.dataPricing, 'bookingIdentifier', '') !== '' && _isEqual(state.dataPricing.legs, statePricing.legs)) {
        if (checkIfMadePricingOnGoToStep4) {
          return;
        }
      }


      let itemsBefore = _get(state, 'dataPricing.legs', []).reduce((total, item) => {
        return total + item.passengers.length + item.vehicles.length
      }, 0)

      let itemsAfter = _get(statePricing, 'legs', []).reduce((total, item) => {
        return total + item.passengers.length + item.vehicles.length
      }, 0)
 

      if (_get(state.dataPricing, 'bookingIdentifier', '') == '') {
        keepSeats = true;
      } else if (itemsBefore !== itemsAfter) {
        await unlockSeatAccomodations();
        keepSeats = true;
      } else if (!_isEqual(state.dataPricing.legs, statePricing.legs) || getters.getPricingTrips.length == 0) {
        await unlockSeatAccomodations();
        keepSeats = true;
      } else {
        keepSeats = false;
        return;
      }

    }

    await checkIfNeedUnlockSeat()

    let farePerCompaniesShort = null

    if (state.selectedCompanyDiscount.length > 0) {
      farePerCompaniesShort = getters.getSelectedTrips.map(trip => {
        const companyAbbreviation = trip.vessel?.company?.abbreviation || '';
        return {
          company: {
            abbreviation: companyAbbreviation
          },
          idOrCode: ''
        };
      });

      state.selectedCompanyDiscount.forEach(discount => {
        if (discount.index < farePerCompaniesShort.length) {
          farePerCompaniesShort[discount.index].idOrCode = discount.value || '';
        }
      });
    }
  
    let requestDataFilter = PricingRequestBodyDTONotSort({
      trips: getters.getSelectedTrips.map(trip => ({ ...trip, alternateAgencyCode: getters.getOtherAccount?.credentials })),
      companies: getters.getCompanies,
      hasExtraServices: getters.getHasExtraService,
      projectCode: state.selectedProjectInfo?.project?.code ? state.selectedProjectInfo.project.code : undefined,
      voucherCode: state.coupon ? state.coupon : undefined,
      keepSeats,
      openReturn: getters.getOpenReturn,
      ...(farePerCompaniesShort && { farePerCompanies: farePerCompaniesShort })
    })

      requestDataFilter.trips.forEach((trip, index) => {

        trip.accommodationRequests.forEach(accommodation => {
          accommodation.accommodationRequestAnalysises.forEach(request => {
            // Remove it from data request
            delete request.uuid;
          })
        })
        delete trip.fareIdOrCode;
      });


    const requestData = () => ({
      data: requestDataFilter,
      token: token(rootGetters)
    })

    const addPricingData = pricing => {
      let stPricingData = { ...state.dataPricing }
      stPricingData.bookingIdentifier = _get(pricing, 'bookingIdentifier', '-')
      stPricingData.legs = statePricing.legs 
      if (keepSeats) {
        stPricingData.responseData = pricing
        
        commit('SET_DATA_PRICING', stPricingData)
      }
    }

    const savePricing = pricing => {
      commit('SET_PRICING', pricing)
      if (pricing && pricing?.trips?.length) {

        pricing?.trips?.forEach((tripWithPricing) => {
          const tripToUpdate = tripId.concat('prices').reduce(
            (_trip, key) => ({
              ..._trip,
              [key]: _get(tripWithPricing, key)
            }),
            {}
          )
          tripToUpdate.prices.forEach((price, index) => {
            tripToUpdate.prices[index].accommodationNumberToDisplay = price.accommodationNumber;
          })
          commit('UPDATE_SELECTED_TRIP', {
            ...tripToUpdate,
            prices: tripWithPricing.prices
          })
        })
      }
      return pricing
    }
    const handle = pipeP(state.conjuctionsSearch ? conjuctionsRequestData : requestData, apiPricing, savePricing)


    return new Promise((resolve, reject) => {
      if (keepSeats) {
        handle().then(res => {
          addPricingData(res)

          return resolve(res)
        }).catch(error => {
          return reject(error)
        })
      } else {
        return resolve(_get(state.dataPricing, 'responseData', {}))
      }
    })

  },

  validateManCode({ rootGetters, state, commit, getters, dispatch }, payload) {
    // let passenger = {}
    // const clonedTrips = [...JSON.parse(JSON.stringify(getters.getSelectedTrips))]

    // // GET 
    // const selectedTrips = clonedTrips.map(trip => {
    //   for (let [key, value] of Object.entries(trip.selectedPassengers)) {
    //     if (value?.pricing_attribute_loyaltycard?.selectedValue !== '') {
    //       passenger =  {...passenger, [key]: value}
    //       trip.selectedPassengers = passenger
    //     }
    //   }
    //   return trip
    // })
    const requestData = () => {
      return {
        data:
          payload.pricing ?
            PricingRequestBodyDTO({
              // trips: selectedTrips,
              trips: payload.trips && payload.trips.length ? payload.trips : getters.getSelectedTrips,
              recall: payload.trips && payload.trips.length,
              companies: getters.getCompanies
            })
            :
            BookingRequestBodyDTO({
              trips: getters.getSelectedTrips.length ? getters.getSelectedTrips : payload.trips,
            }),
        token: token(rootGetters),
      }
    }
    const handleAction = pipeP(requestData, validateManCode)
    return handleAction(payload).then(res => {
      return res
    }).catch(error => {
      return error
    })
  },

  validateLoyaltyData({ rootGetters }, payload) {
    const requestData = () => {
      return {
        data: {
          company: {
            abbreviation: payload.abbreviation
          },
          loyaltyCard: payload.loyaltyCard
        },
        token: token(rootGetters),
      }
    }
    const handleAction = pipeP(requestData, validateLoyaltyData)
    return handleAction(payload).then(res => {
      return res
    }).catch(error => {
      return error
    })
  },

  calculateLoyaltyMiles({ rootGetters }, payload) {
    const requestData = () => {
      return {
        data: payload.data,
        token: token(rootGetters),
      }
    }
    const handleAction = pipeP(requestData, calculateLoyaltyMiles)
    return handleAction(payload).then(res => {
      return res
    }).catch(error => {
      return error
    })
  },

  book({ rootGetters, state, commit, getters, dispatch }, payload) {
    const { printOnlyReceipt, withOptionsPerTicket, openReturn } = payload || {}
    commit('SET_PTA', printOnlyReceipt)

    let farePerCompaniesShort = null

    if (state.selectedCompanyDiscount.length > 0) {
      farePerCompaniesShort = getters.getSelectedTrips.map(trip => {
        const companyAbbreviation = trip.vessel?.company?.abbreviation || '';
        return {
          company: {
            abbreviation: companyAbbreviation
          },
          idOrCode: ''
        };
      });

      state.selectedCompanyDiscount.forEach((discount, index) => {
        if (index < farePerCompaniesShort.length) {
          farePerCompaniesShort[index].idOrCode = discount.value || '';
        }
      });
    }

    const requestData = () => {
      return {
        data: BookingRequestBodyDTO({
          alternateAgencyCode: getters.getOtherAccount?.credentials,
          fareIdOrCode: state.selectedCompanyDiscount.length > 1  ? undefined : pathOr(undefined, ['selectedCompanyDiscount[0]'], state),
          trips: getters.getSelectedTrips,
          projectCode: state.selectedProjectInfo?.project?.code ? state.selectedProjectInfo.project.code : undefined,
          voucherCode: state.coupon ? state.coupon : undefined,
          openReturn: getters.getOpenReturn,
          companies: getters.getCompanies,
          remarks: state.remarks,
          hasExtraServices: getters.getHasExtraService,
          leaderPhone: pathOr('', ['leaderPhone'], state) || '9999999999',
          leaderMail: pathOr('', ['leaderMail'], state),
          emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
          paymentMethods: state.bookingPaymentMethods,
          ...(farePerCompaniesShort && {farePerCompanies: farePerCompaniesShort}),
          userSelectedEticket: true,
          printOnlyReceipt,
          insurances: getters.getInsuranceConfirm ? [
            {
              company: getters.getInsurance?.company,
              productOfferIds: getters.getInsurance?.insuranceProducts.filter(p => getters.getInsuranceProductOffer.includes(p.productOfferId?.substr(p.productOfferId.length - 6))).map(a => a.productOfferId)
            }
          ] : undefined
        }),
        token: token(rootGetters),
      }
    }

    const ifOpenReturn = requestData => {
      if (openReturn && requestData.data.trips && requestData.data.trips.length === 2) {
        requestData.data.trips[1].accommodationRequests = requestData.data.trips[1].accommodationRequests.map(r => ({
          ...r,
          isOpen: true
        }))
      }

      return requestData
    }
    // save pricing inside selectedTrips
    const saveData = booking => {
      commit('SET_BOOKING', booking)
      return booking
    }
    const handle = pipeP(requestData, ifOpenReturn, apiBooking, log(printOnlyReceipt ? 'pta' : 'book'), saveData)
    return handle()
  },

  addItemsToBooking({ rootGetters, getters, state, commit, dispatch }, { crsReservationId }) {
    // companyReservationCode
    const requestData = () => ({
      data: BookingRequestBodyDTO({
        alternateAgencyCode: getters.getOtherAccount?.credentials,
        comments: pathOr('', ['remarks'], state),
        companies: getters.getCompanies,
        trips: getters.getSelectedTrips,
        companies: getters.getCompanies,
        remarks: state.remarks,
        leaderPhone: pathOr('', ['leaderPhone'], state),
        leaderMail: pathOr('', ['leaderMail'], state),
        emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
        paymentMethods: state.bookingPaymentMethods,
        projectCode: state.selectedProjectInfo?.project?.code,
        voucherCode: state.coupon || undefined,
        crsReservationId
      }),
      token: token(rootGetters)
    })
    const handle = pipeP(requestData, apiAddItemsToBooking, log('Book from recall'))
    return handle()
  },

  replaceOpenTickets({ rootGetters, state, commit, getters, dispatch }, payload) {
    const { printOnlyReceipt, openReturn } = payload || {}
    const requestData = () => {
      const data = BookingRequestBodyDTO({
        alternateAgencyCode: getters.getOtherAccount?.credentials,
        fareIdOrCode: state.selectedCompanyDiscount.length > 1  ? undefined : pathOr(undefined, ['selectedCompanyDiscount[0]'], state),
        trips: getters.getSelectedTrips,
        openReturn: getters.getOpenReturn,
        companies: getters.getCompanies,
        remarks: state.remarks,
        leaderPhone: pathOr('', ['leaderPhone'], state) || '9999999999',
        leaderMail: pathOr('', ['leaderMail'], state),
        emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
        paymentMethods: state.bookingPaymentMethods,
        printOnlyReceipt,
        hasExtraServices: getters.getHasExtraService,
      })
      
      return {
        data,
        token: token(rootGetters),
      }
    }
    const ifOpenReturn = requestData => {
      if (openReturn && requestData.data.trips && requestData.data.trips.length === 2) {
        requestData.data.trips[1].accommodationRequests = requestData.data.trips[1].accommodationRequests.map(r => ({
          ...r,
          isOpen: true
        }))
      }

      // temporary fix until gds handles zeros
      if (requestData.data.trips && requestData.data.trips[0].accommodationRequests) {
        requestData.data.trips[0].accommodationRequests = requestData.data.trips[0].accommodationRequests.map(r => {
          return {
            ...r,
            accommodationRequestAnalysises: r.accommodationRequestAnalysises.map(accommodation => {
              const passengerData = accommodation.passengerData;
              const vehicleData = accommodation.vehicleData;
              if (passengerData) {
                return {
                  ...accommodation,
                  passengerData: {
                    ...passengerData,
                    ticket: {
                      ...passengerData.ticket,
                      number: passengerData.ticket.number.padStart(12, '0')
                    }
                  }
                };
              } else if (vehicleData) {
                return {
                  ...accommodation,
                  vehicleData: {
                    ...vehicleData,
                    ticket: {
                      ...vehicleData.ticket,
                      number: vehicleData.ticket.number.padStart(12, '0')
                    }
                  }
                };
              }
              
              return accommodation;
            })
          };
        });
      }

      return requestData
    }
    // save pricing inside selectedTrips
    const saveData = booking => {
      commit('SET_BOOKING', booking)
      return booking
    }
    const handle = pipeP(requestData, ifOpenReturn, apiReplaceOpenTickets, log('replace-open-tickets'), saveData)
    return handle()
  },

  priceDifferenceForReplaceOpenTickets({ rootGetters, state, commit, getters, dispatch }, payload) {
    const { printOnlyReceipt, openReturn } = payload || {}
    
    const requestData = () => {
      const data = BookingRequestBodyDTO({
        alternateAgencyCode: getters.getOtherAccount?.credentials,
        fareIdOrCode: state.selectedCompanyDiscount.length > 1  ? undefined : pathOr(undefined, ['selectedCompanyDiscount[0]'], state),
        trips: getters.getSelectedTrips,
        openReturn: getters.getOpenReturn,
        companies: getters.getCompanies,
        remarks: state.remarks,
        leaderPhone: pathOr('', ['leaderPhone'], state) || '9999999999',
        leaderMail: pathOr('', ['leaderMail'], state),
        emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
        paymentMethods: state.bookingPaymentMethods,
        printOnlyReceipt,
        hasExtraServices: getters.getHasExtraService,
      })
      return {
        data,
        token: token(rootGetters),
      }
    }
    const ifOpenReturn = requestData => {
      if (openReturn && requestData.data.trips && requestData.data.trips.length === 2) {
        requestData.data.trips[1].accommodationRequests = requestData.data.trips[1].accommodationRequests.map(r => ({
          ...r,
          isOpen: true
        }))
      }

      // temporary fix until gds handles zeros
      if (requestData.data.trips && requestData.data.trips[0].accommodationRequests) {
        requestData.data.trips[0].accommodationRequests = requestData.data.trips[0].accommodationRequests.map(r => {
          return {
            ...r,
            accommodationRequestAnalysises: r.accommodationRequestAnalysises.map(accommodation => {
              const passengerData = accommodation.passengerData;
              const vehicleData = accommodation.vehicleData;
              if (passengerData) {
                return {
                  ...accommodation,
                  passengerData: {
                    ...passengerData,
                    ticket: {
                      ...passengerData.ticket,
                      number: passengerData.ticket.number.padStart(12, '0')
                    }
                  }
                };
              } else if (vehicleData) {
                return {
                  ...accommodation,
                  vehicleData: {
                    ...vehicleData,
                    ticket: {
                      ...vehicleData.ticket,
                      number: vehicleData.ticket.number.padStart(12, '0')
                    }
                  }
                };
              }
              
              return accommodation;
            })
          };
        });
      }
      return requestData
    }
    // save pricing inside selectedTrips
    const saveData = booking => {
      commit('SET_BOOKING', booking)
      return booking
    }
    const handle = pipeP(requestData, ifOpenReturn, apiPriceDifferenceForReplaceOpenTickets, log('price-diff-for-open'), saveData)
    return handle()
  },

  optionalBook({ rootGetters, getters, state, commit, dispatch }) {
    let farePerCompaniesShort = null

    if (state.selectedCompanyDiscount.length > 0) {
      farePerCompaniesShort = getters.getSelectedTrips.map(trip => {
        const companyAbbreviation = trip.vessel?.company?.abbreviation || '';
        return {
          company: {
            abbreviation: companyAbbreviation
          },
          idOrCode: ''
        };
      });

      state.selectedCompanyDiscount.forEach((discount, index) => {
        if (index < farePerCompaniesShort.length) {
          farePerCompaniesShort[index].idOrCode = discount.value || '';
        }
      });
    }

    const requestData = () => ({
      data: BookingRequestBodyDTO({
        alternateAgencyCode: getters.getOtherAccount?.credentials,
        comments: pathOr('', ['remarks'], state),
        companies: getters.getCompanies,
        trips: getters.getSelectedTrips,
        companies: getters.getCompanies,
        remarks: state.remarks,
        leaderMail: pathOr('', ['leaderMail'], state),
        leaderPhone: pathOr('', ['leaderPhone'], state),
        emergencyPhone: pathOr('', ['leaderEmergencyPhone'], state),
        paymentMethods: state.bookingPaymentMethods,
        ...(farePerCompaniesShort && { farePerCompanies: farePerCompaniesShort }),
        projectCode: state.selectedProjectInfo?.project?.code ? state.selectedProjectInfo.project.code : undefined,
        voucherCode: state.coupon ? state.coupon : undefined,
        hasExtraServices: getters.getHasExtraService
      }),
      token: token(rootGetters)
    })
    // save pricing inside selectedTrips
    const saveData = booking => {
      commit('SET_BOOKING', booking)
      return booking
    }
    // const refreshCreditLimit = booking => {
    //   dispatch('getFinancialData', null, {
    //     root: true
    //   })
    //   return booking
    // }
    // const handle = pipeP(requestData, apiOptionalBooking, saveData, refreshCreditLimit)
    const handle = pipeP(requestData, apiOptionalBooking, log('optional-book'), saveData)
    return handle()
  },

  addBookingOptionsToRoutes({ commit, getters, state }) {
    getters.getSelectedRoutes.forEach((route, index) => {
      commit('UPDATE_ROUTE_BY_INDEX', {
        index,
        value: {
          travellers: getters.getPassengersNumber,
          vehicles: getters.getVehiclesNumber,
          truckSearch: state.truckSearch
        }
      })
    })
  },

  setRouteFrequencies({ commit }, payload) {
    commit('SET_DEFAULT_ROUTE_FREQUENCIES', {
      ...this.getters['book-ticket/getRouteFrequencies'],
      [payload.send.id]: payload.send.data
    })
  },
  copyRouteOptionsToTrips({ commit, getters }, routeIndex) {
    getters.getSelectedTrips
      .filter(t => t.routeId === routeIndex)
      .forEach(t =>
        commit('UPDATE_SELECTED_TRIP', {
          ...createSelectedPassengerVehicles(t, getters)
        })
      )
  },

  resetSelectedTrips({ commit }, payload) {
    commit('SET_SELECTED_TRIPS', payload || [])
  },

  resetState({ commit, state }) {
    commit('RESET_STATE', { ...initialState(), companiesForSearch: state.companiesForSearch, routes: state.routes, countries: state.countries, vessels: state.vessels, residentCities:state.residentCities, otherAccount: state.otherAccount })
  },

  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  },

  setTruckSearchResults({ state, commit }, payload) {
    commit('SET_TRUCK_SEARCH_RESULTS', [...state.truckSearchResults, payload])
  },
  updateTruckSearchResults({ commit }, payload) {
    commit('UPDATE_TRUCK_SEARCH_RESULTS', payload)
    // commit('SET_TRUCK_SEARCH_RESULTS', payload)
  },

  getRouteFrequency({ rootGetters, state }, route) {
    const { project, from, to, when } = route
    return apiRouteFrequency({
      project,
      originIdOrCode: from.idOrCode,
      destinationIdOrCode: to.idOrCode,
      departureDate: when,
      includeConjunctions: state.conjuctionsSearch,
      token: token(rootGetters)
    })
  },

  saveCurrentStateToStorage({ state, commit }, payload = {}) {
    const { name } = payload
    createStorage(localStorage, {
      namespace: `liknoss_book_ticket_`
    }).set(`${name}`, {
      state,
      windowLocation: window.location
    })
    commit('SET_SAVED_STATE_NAME', name)
  },

  loadSavedState({ commit }, payload = {}) {
    const { name } = payload
    const { state, windowLocation } = createStorage(localStorage, {
      namespace: `liknoss_book_ticket_`
    }).get(`${name}`, {})
    const { selectedRoutes, ...rest } = state;
    commit('SET_LEADER_PHONE', state.leaderPhone)
    commit('SET_BOOKING_REMARKS', state.remarks)
    commit('SET_LEADER_MAIL', state.leaderMail)
    commit('SET_LEADER_EMERGENCY_PHONE', state.leaderEmergencyPhone)
    commit('RESET_STATE', rest)
    commit('SET_SAVED_STATE_NAME', name)
    commit('SET_FROM_SAVED', true)
    this.leaderEmergencyPhone = state.leaderEmergencyPhone
    this.leaderPhone = state.leaderPhone
    this.remarks = state.remarks
    this.mail = state.leaderMail
    this.$router.push(this.localePath('/book-ticket/step-2/'))
  },

  deleteSavedState({ commit }, payload = {}) {
    const { name } = payload
    createStorage(localStorage, {
      namespace: `liknoss_book_ticket_`
    }).remove(name)
  },

  getStorageSavedBookings() {
    return createStorage(localStorage, {
      namespace: `liknoss_book_ticket_`
    }).getKeys()
  },

  getATSSeatMap({ rootGetters, commit }, payload) {
    const createParams = data => ({
      body: data,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, seatMapForATS)
    return handleAction(payload)
      .then(res => {
        // console.log('res', res)
        // const seatsGrouped = res.map(seatType => {
        //   const result = Object.entries(_groupBy(seatType.seats, 'x')).map(([key, value]) => ({
        //     [key]: value
        //   }))
        //   return {
        //     ...seatType,
        //     seats: result
        //   }
        // })
        commit('SET_SEAT_MAP', res.decks)
      })
      .catch(error => console.log(error))
  },

  getCabinsSeatMap({ rootGetters, commit }, payload) {
    const { accommodationId } = payload
    const createParams = data => ({
      body: data,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, seatMapForCabins)
    return handleAction(payload)
      .then(res => {
        commit(
          'SET_SEAT_MAP_FOR_CABINS',
          res.cabins.map(c => ({
            ...c,
            accommodationId
          }))
        )
      })
      .catch(error => console.log(error))
  },
  getVesselList({ rootGetters , commit }, payload) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, vesselList)
    return handleAction(payload)
    .then(res => {
      commit('SET_AVAILABLE_VESSELS', res)
      return res
    })
    .catch(error => console.log(error))
  },

  getCountries({ rootGetters, commit }, payload) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, countries)
    return handleAction(payload)
      .then(res => {
        commit('SET_AVAILABLE_COUNTRIES', res)
        return res
      })
      .catch(error => console.log(error))
  },

  setAvailablePaymentMethods({ rootGetters, commit }, payload) {
    const createParams = data => ({
      body: data,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, availablePaymentMethods)
    return handleAction(payload)
      .then(res => {
        commit('SET_AVAILABLE_PAYMENT_METHODS', res)
        commit(
          'SET_AVAILABLE_BOOKING_PAYMENT_METHODS',
          res.paymentMethodResponsePerCompanies[0]?.methods?.map(method => ({
            name: method.name,
            default: method.isDefault,
            ammount: 0,
            id: method.id
          }))
        )
        return res
      })
      .catch(error => console.log(error))
  },
  lockAccomodationsSeatMap({ rootGetters, commit }, payload) {
    const createParams = data => ({
      body: data,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, lockAccomodations)
    return handleAction(payload)
  },

  unlockAccomodationsSeatMap({ rootGetters, getters, commit }, payload) {
    let requestDataFilter = unlockRequestBodyDTONotSort({
      trips: getters.getSelectedTrips.map(trip => ({ ...trip, alternateAgencyCode: getters.getOtherAccount?.credentials })),
      companies: getters.getCompanies,
      hasExtraServices: getters.getHasExtraService,
      planItems: payload.planItems,
      openReturn: getters.getOpenReturn,
    })

    requestDataFilter.trips.forEach(trip => {
      trip.accommodationRequests.forEach(accommodation => {
        accommodation.accommodationRequestAnalysises.forEach(request => {
          delete request.uuid;
        })
      })
      delete trip.fareIdOrCode;
    });

    const createParams = data => ({
      body: requestDataFilter,
      token: token(rootGetters)
    })

    const clearPricing = data => {
      commit('SET_PRICING', {})
    }

    const handleAction = pipeP(createParams, unlockAccomodations, clearPricing)
    return handleAction(payload)
  },

  printPdfAction({ rootGetters, commit }, { crsReservationId }) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(
      createParams,
      printPdf({
        crsReservationId
      })
    )
    return handleAction
  },

  sendEmailAction({ rootGetters, commit }, { crsReservationId }) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(
      createParams,
      sendEmail({
        crsReservationId
      })
    )
    return handleAction
  },
  sendSmsAction({ rootGetters, commit }, { crsReservationId }) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(
      createParams,
      sendSms({
        crsReservationId
      })
    )
    return handleAction
  },
  retrieveLatestBooking({ rootGetters }) {
    const createParams = data => ({
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, latestBookingAvailable)
    return handleAction().then(res => res)
  },
  setSelectedTrips({ commit }, { trips }) {
    commit('SET_SELECTED_TRIPS', _sortBy(_sortBy(trips, o => o.routeId), i => i?.departureDateTime).filter(item => item !== undefined))
  },

  setSelectedTripsCheckDouble({ commit }, { trips }) {
    commit('SET_SELECTED_TRIPS_CHECK_DOUBLE', trips)
  },

  getPrintingTemplate({ rootGetters }, payload) {
    // const { reservationId, printerName } = payload
    const createParams = data => ({
      token: token(rootGetters),
      params: {
        ...data
      }
    })

    return Promise.resolve(createParams(payload)).then(apiPrintingTemplate)
  },

  sendPrinterJob({ rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload
    })
    const actionToApi = data => apiSendPrinterJob(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, tap)
    return handleAction(payload)
  },

  getAvailablePrinters({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, apiRetrievePrinters)
    return handleAction().then(res => res)
  },

  getAvailableCompaniesSearch({ rootGetters, commit }, payload) {
    const createParams = data => ({
      params: { ...payload, filter: 'ferry-only' },
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, getCompanies)
    return handleAction(payload).then(res => res)
  },

  getAvailableCompaniesPrinters({ rootGetters, commit }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, apiRetrieveCompanies)
    return handleAction(payload).then(res => res)
  },

  getPrinterTemplate({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, apiRetrieveTemplate)
    return handleAction().then(res => res)
  },

  // getAvailablePrinterJobs({ rootGetters }, payload) {
  //   const createParams = data => ({
  //     params: payload,
  //     token: token(rootGetters)
  //   })
  //   const handleAction = pipeP(createParams, apiRetrievePrinterJobs)
  //   return handleAction().then(res => res)
  // },

  getCashierData({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, cashierData)
    return handleAction().then(res => res)
  },
  
  getStatisticsData({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, statisticsData)
    return handleAction().then(res => res)
  },
  getResidentCitiesData({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, residentCitiesList)
    return handleAction().then(res => res)
  },



  getAgencyInfo({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, agencyInfo)
    return handleAction().then(res => res)
  },

  validateOpenTicketAction({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, validateOpenTicket)
    return handleAction().then(res => res)
  },

  getClearanceDownloadIdAction({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, getClearanceDownloadId)
    return handleAction().then(res => res)
  },

  downloadClearanceAction({ rootGetters }, payload) {
    const createParams = data => ({
      params: payload,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, downloadClearance)
    return handleAction().then(res => res)
  },

  setOtherAccount({ commit }, payload) {
    commit('SET_OTHER_ACCOUNT', payload)
  },

  setDefaultPassengersOrVehiclesFromSettings({ rootGetters, getters, dispatch, commit }) {
    // Get passengers combinations and quantity
    const passengerCombinations = getters.getCombinationsPassengers
    const getDefaultNumberOfPassengers = rootGetters['settings/getDefaultNumberOfPassengers']
    // Get vehicles combinations and quantity
    const vehiclesCombinations = getters.getCombinationsVehicles
    const getDefaultNumberOfVehicles = rootGetters['settings/getDefaultNumberOfVehicles']
    const commitMutation = (mutation, number, index) => {
      if (index >= 0) {
        commit(mutation, {
          index,
          value: {
            number
          }
        })
      }
    }
    if (Array.isArray(passengerCombinations)) {
      /**
       * Set by default the first selected from settings panel
       * Because passengerCombinations has items that we dont use (we display only combinations that has child attributes)
       * find the index of the first combination whicha has childAttribute
       */
      const firstIndex = passengerCombinations.findIndex(p => p.childAttribute)
      commitMutation('UPDATE_PASSENGER_COMBINATION_BY_INDEX', getDefaultNumberOfPassengers, firstIndex)
    }

    if (Array.isArray(vehiclesCombinations)) {
      const firstIndex = vehiclesCombinations.findIndex(p => vehiclesCombinations[0])
      commitMutation('UPDATE_VEHICLE_CONFIGURATION_BY_INDEX', getDefaultNumberOfVehicles, firstIndex)
    }
  },

  getLoyaltyInfo({ rootGetters, getters, dispatch, commit }, payload) {
    // { trip, passenger, cardNumber } = payload
    const params = data => ({
      data: LoyaltyRequestBodyDTO(data),
      token: token(rootGetters)
    })
    // apiLoyaltyInfo
    const apiCall = pipeP(params, apiLoyaltyInfo)
    return apiCall(payload)
  },
  setChecked({ commit }, payload) {
    commit('SET_CHECKED', payload)
  },
  setFromSaved({ commit }, payload) {
    commit('SET_FROM_SAVED', payload)
  },
  setHasProjectSelected({ commit }, payload) {
    commit('SET_HAS_PROJECT_SELECTED', payload)
  },
  setHasExtraService({ commit }, payload) {
    commit('SET_HAS_EXTRASERVICE', payload)
  },
  setFromStep2({ commit }, payload) {
    commit('SET_FROM_STEP2', payload)
  },
  smallPackagesAction({ rootGetters, commit }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })

    const storeData = data => {
      commit('SET_TRIP_PARCELS', { trip: payload.trip, parcels: data })
      return data
    }

    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, getSmallPackages, storeData, tap)
    return handleAction(payload)
  },
  smallPackagesActionPricing({ rootGetters, commit }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })

    const storeData = data => {
      commit('SET_TRIP_PARCELS_PRICING', { pricing: data })
      return data
    }

    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, getSmallPackagesPricing, storeData, tap)
    return handleAction(payload)
  },
  smallPackagesActionBooking({ rootGetters, commit }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })

    const setBooking = data => {
      commit('SET_BOOKING', data)
      return data
    }

    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, getSmallPackagesBooking, setBooking, tap)
    return handleAction(payload)
  },
  smallPackagesActionCancel({ rootGetters, commit }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, getSmallPackagesCancel, tap)
    return handleAction(payload)
  },
  alternateAgenciesAction({ rootGetters, commit }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, alternateAgencies, tap)
    return handleAction(payload)
  },
}
