import Vue from 'vue'
import Vuex from 'vuex'
import { token } from '../helpers/book-ticket'
import { pipeP } from '../helpers/general'
import { apiLogin, changePassword } from '../api/requests/b2b-service/auth'
Vue.use(Vuex)

export const state = () => ({
  session: null,
  username: '',
  password: '',
  loggedUser: {},
  isIdle: false,
  validation_code: '',
  token:''
})

export const getters = {
  getAccessToken: state => {
    return state.token
  },
  getIsIdle: state => {
    return state.isIdle
  },
  getUserCredentials: state => {
    return   {
      "token": state.token
    }

  },
  getSession: state => state.session
}

export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload &&  payload.token
  },
  SET_IDLE(state, payload) {
    state.isIdle = payload
  },
  SET_SESSION(state, payload) {
    state.session =  payload
  }
}



export const actions = {
  login({ commit, dispatch }, {username, password, agencyCode, agencySignature,validation_code,validation_code_expiry_in_minutes}) {
    return apiLogin(username, password, agencyCode, agencySignature, this.getters['app/getMinorVersion'], this.getters['app/getMajorVersion'], this.getters['app/getPatchVersion'],validation_code,validation_code_expiry_in_minutes).then(data => {
      commit('SET_TOKEN', {...data, username, password, agencyCode, agencySignature,validation_code})
      dispatch('book-ticket/resetState', null, { root: true })
      commit('SET_SESSION', {...data, username, password, agencyCode, agencySignature,validation_code})
      return data
    })
  },

  logout({ commit }) {
    commit('SET_TOKEN', null)
    this.$router.push(this.app.localePath('/'))
  },

  changeUserPassword({ rootGetters, commit }, payload) {
    const createParams = data => ({
      data,
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, changePassword)
    return handleAction(payload)
  }
}

// export const Store = new Vuex.Store({
//   actions: actions,
// })