import axios from '../../client/axios'
import { API_PRINTER } from '../../settings'
const apiKey = 'rZGH7W323KFUqqzw4jL_2SYXDgxQp5xzjdPIu53Mtk4'

export const apiSendPrinterJob = ({ data }) => {
  return axios({
    method: 'POST',
    baseURL: 'https://api.printnode.com',
    url: '/printjobs',
    headers: {
      Authorization: `Basic ${btoa(apiKey)}`
    },
    data
  }).then(res => res.data)
}

export const apiRetrievePrinterJobs = ({ params, token, otherAccount }) => {
  return axios({
    method: 'GET',
    baseURL: 'https://api.printnode.com',
    url: '/printjobs',
    headers: {
      Authorization: `Basic ${btoa(apiKey)}`
    },
    params
  }).then(res => res.data)
}

export const apiRetrievePrinters = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: API_PRINTER,
    url: '/printersx.ini',
    params
  }).then(res => res.data)

export const apiRetrieveTemplate = ({ params, token }) => {
  let selectedForm = '';

  if (params.isPass) {
    if (params.ticketFormIdentifier === 'E') {
      selectedForm = 'ECP';
    } else if (params.ticketFormIdentifier === 'U') {
      selectedForm = 'UPP';
    } else if (params.ticketFormIdentifier === 'O' && params.openReturn == true) {
      selectedForm = 'OTP';
    } else if (params.ticketFormIdentifier === 'O' && params.openReturn == false) {
      selectedForm = 'OCP';
    } else {
      selectedForm = 'PAS';
    }
  } else if (params.isExtra) {
    if (params.ticketFormIdentifier === 'E') {
      selectedForm = 'ETX'
    } else {
      selectedForm = 'ATX'
    }
  } else if (params.isPetService) {
    if (params.ticketFormIdentifier === 'E') {
      selectedForm = 'ECE';
    } else {
      selectedForm = 'EXT'
    }
  } else {
    if (params.ticketFormIdentifier === 'E') {
      selectedForm = 'ECC';
    } else if (params.ticketFormIdentifier === 'U') {
      selectedForm = 'UPC';
    } else if (params.ticketFormIdentifier === 'O' && params.openReturn == true) {
      selectedForm = 'OTC';
    } else if (params.ticketFormIdentifier === 'O' && params.openReturn == false) {
      selectedForm = 'OCC';
    } else {
      selectedForm = 'CAR';
    }
  }

  return axios({
    method: 'GET',
    baseURL: API_PRINTER,
    url: `/${params.printerId}/${params.id}${params.companyId}.${selectedForm}.TFM`,
    responseType: 'arraybuffer',
    responseEncoding: 'binary',
  }).then(res => {
    let win1251decoder = new TextDecoder('iso-8859-7');
    let bytes = new Uint8Array(res.data);
    return win1251decoder.decode(bytes)
  });
}

export const apiRetrieveCompanies = ({ params, token }) => {
    return axios({
      method: 'GET',
      baseURL: API_PRINTER,
      url: '/companies.ini',
    }).then(res => res.data)
}
