import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c449c0a = () => interopDefault(import('../pages/action-log.vue' /* webpackChunkName: "pages/action-log" */))
const _87ebcdb4 = () => interopDefault(import('../pages/book-ticket/index.vue' /* webpackChunkName: "pages/book-ticket/index" */))
const _2f3901ec = () => interopDefault(import('../pages/booking-group-search/index.vue' /* webpackChunkName: "pages/booking-group-search/index" */))
const _0aac403c = () => interopDefault(import('../pages/booking-search/index.vue' /* webpackChunkName: "pages/booking-search/index" */))
const _4906a5ea = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _860082f6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b253f66a = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _144ccf40 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _e4d990c0 = () => interopDefault(import('../pages/shortcuts.vue' /* webpackChunkName: "pages/shortcuts" */))
const _10c1d700 = () => interopDefault(import('../pages/statistics.vue' /* webpackChunkName: "pages/statistics" */))
const _2443cb71 = () => interopDefault(import('../pages/switch-account.vue' /* webpackChunkName: "pages/switch-account" */))
const _2760f4d0 = () => interopDefault(import('../pages/transaction-history.vue' /* webpackChunkName: "pages/transaction-history" */))
const _10d80766 = () => interopDefault(import('../pages/book-ticket/step-2.vue' /* webpackChunkName: "pages/book-ticket/step-2" */))
const _5cb35510 = () => interopDefault(import('../pages/book-ticket/step-2/index.vue' /* webpackChunkName: "pages/book-ticket/step-2/index" */))
const _4af426b6 = () => interopDefault(import('../pages/book-ticket/step-2/step3.vue' /* webpackChunkName: "pages/book-ticket/step-2/step3" */))
const _109fa962 = () => interopDefault(import('../pages/book-ticket/step-4.vue' /* webpackChunkName: "pages/book-ticket/step-4" */))
const _28bc5064 = () => interopDefault(import('../pages/book-ticket/step-4-optional.vue' /* webpackChunkName: "pages/book-ticket/step-4-optional" */))
const _39c081b9 = () => interopDefault(import('../pages/book-ticket/step1.vue' /* webpackChunkName: "pages/book-ticket/step1" */))
const _e1dabc00 = () => interopDefault(import('../pages/booking-group-search/_reservationId.vue' /* webpackChunkName: "pages/booking-group-search/_reservationId" */))
const _db29cde4 = () => interopDefault(import('../pages/booking-search/_reservationId.vue' /* webpackChunkName: "pages/booking-search/_reservationId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/action-log",
    component: _3c449c0a,
    name: "action-log___el"
  }, {
    path: "/book-ticket",
    component: _87ebcdb4,
    name: "book-ticket___el"
  }, {
    path: "/booking-group-search",
    component: _2f3901ec,
    name: "booking-group-search___el"
  }, {
    path: "/booking-search",
    component: _0aac403c,
    name: "booking-search___el"
  }, {
    path: "/change-password",
    component: _4906a5ea,
    name: "change-password___el"
  }, {
    path: "/en",
    component: _860082f6,
    name: "index___en"
  }, {
    path: "/info",
    component: _b253f66a,
    name: "info___el"
  }, {
    path: "/it",
    component: _860082f6,
    name: "index___it"
  }, {
    path: "/settings",
    component: _144ccf40,
    name: "settings___el"
  }, {
    path: "/shortcuts",
    component: _e4d990c0,
    name: "shortcuts___el"
  }, {
    path: "/statistics",
    component: _10c1d700,
    name: "statistics___el"
  }, {
    path: "/switch-account",
    component: _2443cb71,
    name: "switch-account___el"
  }, {
    path: "/transaction-history",
    component: _2760f4d0,
    name: "transaction-history___el"
  }, {
    path: "/book-ticket/step-2",
    component: _10d80766,
    children: [{
      path: "",
      component: _5cb35510,
      name: "book-ticket-step-2___el"
    }, {
      path: "step3",
      component: _4af426b6,
      name: "book-ticket-step-2-step3___el"
    }]
  }, {
    path: "/book-ticket/step-4",
    component: _109fa962,
    name: "book-ticket-step-4___el"
  }, {
    path: "/book-ticket/step-4-optional",
    component: _28bc5064,
    name: "book-ticket-step-4-optional___el"
  }, {
    path: "/book-ticket/step1",
    component: _39c081b9,
    name: "book-ticket-step1___el"
  }, {
    path: "/en/action-log",
    component: _3c449c0a,
    name: "action-log___en"
  }, {
    path: "/en/book-ticket",
    component: _87ebcdb4,
    name: "book-ticket___en"
  }, {
    path: "/en/booking-group-search",
    component: _2f3901ec,
    name: "booking-group-search___en"
  }, {
    path: "/en/booking-search",
    component: _0aac403c,
    name: "booking-search___en"
  }, {
    path: "/en/change-password",
    component: _4906a5ea,
    name: "change-password___en"
  }, {
    path: "/en/info",
    component: _b253f66a,
    name: "info___en"
  }, {
    path: "/en/settings",
    component: _144ccf40,
    name: "settings___en"
  }, {
    path: "/en/shortcuts",
    component: _e4d990c0,
    name: "shortcuts___en"
  }, {
    path: "/en/statistics",
    component: _10c1d700,
    name: "statistics___en"
  }, {
    path: "/en/switch-account",
    component: _2443cb71,
    name: "switch-account___en"
  }, {
    path: "/en/transaction-history",
    component: _2760f4d0,
    name: "transaction-history___en"
  }, {
    path: "/it/action-log",
    component: _3c449c0a,
    name: "action-log___it"
  }, {
    path: "/it/book-ticket",
    component: _87ebcdb4,
    name: "book-ticket___it"
  }, {
    path: "/it/booking-group-search",
    component: _2f3901ec,
    name: "booking-group-search___it"
  }, {
    path: "/it/booking-search",
    component: _0aac403c,
    name: "booking-search___it"
  }, {
    path: "/it/change-password",
    component: _4906a5ea,
    name: "change-password___it"
  }, {
    path: "/it/info",
    component: _b253f66a,
    name: "info___it"
  }, {
    path: "/it/settings",
    component: _144ccf40,
    name: "settings___it"
  }, {
    path: "/it/shortcuts",
    component: _e4d990c0,
    name: "shortcuts___it"
  }, {
    path: "/it/statistics",
    component: _10c1d700,
    name: "statistics___it"
  }, {
    path: "/it/switch-account",
    component: _2443cb71,
    name: "switch-account___it"
  }, {
    path: "/it/transaction-history",
    component: _2760f4d0,
    name: "transaction-history___it"
  }, {
    path: "/en/book-ticket/step-2",
    component: _10d80766,
    children: [{
      path: "",
      component: _5cb35510,
      name: "book-ticket-step-2___en"
    }, {
      path: "step3",
      component: _4af426b6,
      name: "book-ticket-step-2-step3___en"
    }]
  }, {
    path: "/en/book-ticket/step-4",
    component: _109fa962,
    name: "book-ticket-step-4___en"
  }, {
    path: "/en/book-ticket/step-4-optional",
    component: _28bc5064,
    name: "book-ticket-step-4-optional___en"
  }, {
    path: "/en/book-ticket/step1",
    component: _39c081b9,
    name: "book-ticket-step1___en"
  }, {
    path: "/it/book-ticket/step-2",
    component: _10d80766,
    children: [{
      path: "",
      component: _5cb35510,
      name: "book-ticket-step-2___it"
    }, {
      path: "step3",
      component: _4af426b6,
      name: "book-ticket-step-2-step3___it"
    }]
  }, {
    path: "/it/book-ticket/step-4",
    component: _109fa962,
    name: "book-ticket-step-4___it"
  }, {
    path: "/it/book-ticket/step-4-optional",
    component: _28bc5064,
    name: "book-ticket-step-4-optional___it"
  }, {
    path: "/it/book-ticket/step1",
    component: _39c081b9,
    name: "book-ticket-step1___it"
  }, {
    path: "/en/booking-group-search/:reservationId?",
    component: _e1dabc00,
    name: "booking-group-search-reservationId___en"
  }, {
    path: "/en/booking-search/:reservationId?",
    component: _db29cde4,
    name: "booking-search-reservationId___en"
  }, {
    path: "/it/booking-group-search/:reservationId?",
    component: _e1dabc00,
    name: "booking-group-search-reservationId___it"
  }, {
    path: "/it/booking-search/:reservationId?",
    component: _db29cde4,
    name: "booking-search-reservationId___it"
  }, {
    path: "/booking-group-search/:reservationId?",
    component: _e1dabc00,
    name: "booking-group-search-reservationId___el"
  }, {
    path: "/booking-search/:reservationId?",
    component: _db29cde4,
    name: "booking-search-reservationId___el"
  }, {
    path: "/",
    component: _860082f6,
    name: "index___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
