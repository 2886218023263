<template>
  <a-modal
    :visible="visible"
    :keyboard="true"
    @cancel="handleClose"
  >     
    <p class="ticket-stock-info">
      {{ text }}
    </p>
    <footer slot="footer">
      <a-button type="button" v-if="!getValidateCoupon" class="btn-submit modal-secondary-action" @click="secondaryAction">{{ secondaryActionText }}</a-button>
      <button type="button" class="btn-submit ant-btn modal-default-action" ref="primary"  @click="primaryAction">{{ primaryActionText }}</button>
    </footer>
  </a-modal>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    text:{
      type: String,
      default: () => ''
    },
    primaryActionText: {
      type: String,
      default: () => ''
    },
    secondaryActionText: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  },
  watch: {
    visible() {
      if (this.visible) {
        this.giveFocus()
      }
    }
  },
  computed: {
    ...mapGetters({
      getValidateCoupon: 'book-ticket/getValidateCoupon',
    }),
  },
  methods: {
    giveFocus() {
      this.$nextTick(() => {
        const el = this.$refs.primary
        if (el) {
          el.focus();
        }
      })
    },
    handleClose() {
      this.$emit('closeModal')
    },
    primaryAction() {
      this.$emit('onPrimaryClick')
    },
    secondaryAction() {
      this.$emit('onSecondaryClick')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>