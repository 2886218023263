// import _get from 'lodash/get'
import Vue from 'vue'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import {
  apiBooking,
  apiBookingCancel,
  apiConvertBookingToOpen,
  apiConvertBookingToOptional,
  issueBooking,
  apiSplitBooking,
  apiMergeBookings,
  ModifyBookingDTO,
  modifyBooking,
  modifiableAttributes,
  allowedActions,
  UpdateGroupBodyDTO,
  apiUpdateGroupBookings,
  changeOptionDateTime,
  issueFromOptional,
  issueFromPta,
  issueFromOptionalGroup
} from '../api/requests/b2b-service/booking'
import { tripId } from './book-ticket'
import { updateOrCreateListItemByIndex, getOr, set, findOneBy } from '@/helpers/vuex'
import { pipeP } from '@/helpers/general'
import { log } from '@/helpers/log'
import { token } from '@/helpers/book-ticket'
// const refreshCreditLimit = dispatch => booking => {
//   dispatch('getFinancialData', null, {
//     root: true
//   })
//   return booking
// }

export const state = () => ({
  bookingRecall: {},
  actions: {},
  modifiedBookingPassengers: {},
  legLength: [],
  availableServices: []
})

export const getters = {
  getbookingRecall: getOr('bookingRecall', {}),
  getbookingActions: getOr('actions', {}),
  getBookingEditedPassengers: getOr('modifiedBookingPassengers', {}),
  getLegLength: getOr('legLength', []),
  getAvailableServices: getOr('availableServices', [])
}

const getTrip = (state, tripIndex) => _get(state, `bookingRecall.trips.${tripIndex}`)
const updateTripPassengers = trip => {
  if (trip) {
    const toggle = !trip.passengers.every(p => p.selected)
    trip.passengers.forEach(p => Vue.set(p, 'selected', toggle))
  }
}
const updateTripVehicles = trip => {
  if (trip) {
    const toggle = !trip.vehicles.every(p => p.selected)
    trip.vehicles.forEach(p => Vue.set(p, 'selected', toggle))
  }
}
const updateTripPackages = trip => {
  if (trip) {
    const toggle = !trip.entities.every(p => p.selected)
    trip.entities.forEach(p => Vue.set(p, 'selected', toggle))
  }
}
const updateTripServices = trip => {
  if (trip) {
    const toggle = !trip.additionalServices[0].items.every(e => e.selected)
    if (trip.additionalServices[0]) trip.additionalServices[0].items.forEach(p => Vue.set(p, 'selected', toggle))
  }
}
export const mutations = {
  SET_BOOKING_RECALL(state, res) {
    state.bookingRecall = res
  },

  SET_BOOKING_RECALL_TICKETS(state, { res, isIssued = false }) {
    const bookingRecall = { ...state.bookingRecall };
    const recallBooking = _get(bookingRecall, 'trips', []).map((trip, index) => {
      const passengers = trip.passengers;
      const vehicles = trip.vehicles;
      const allTickets = passengers.concat(vehicles);
      if (trip.tickets.length > 0 || isIssued) {
        trip['tickets'] = res.trips[index] ? res.trips[index].tickets : trip['tickets'];
        trip['tickets'] = trip['tickets'].map((ticket, ticketIndex) => {
          if (ticket?.qrCode === '') {
            const firstLetter = ticket.letter.charAt(0);
            const qrCode = `${firstLetter}_${ticket.number}`;
            ticket.qrCode = qrCode;
          } else {
            ticket.qrCode = allTickets[ticketIndex]?.ticket.qrCode;
          }
          return ticket;
        });
      }
      return trip;
    });
  
    state.bookingRecall.trips = recallBooking;
  },

  setSelectedTripReservationStatus(state, { tripIndex, reservationStatus }) {
    state.selectedTrips[tripIndex].reservationStatus = reservationStatus;
  },

  SET_LEG_LENGTH: set('legLength'),

  SET_AVAILABLE_SERVICES: set('availableServices'),

  UPDATE_BOOKING_TRIP_PASSENGER(state, { passenger, ticket, tripIndex }) {
    const tripToUpdate = _get(state, `bookingRecall.trips.${tripIndex}`)
    const pasIndex = _get(tripToUpdate, 'passengers', []).find(p => p.ticket.letter + p.ticket.number === ticket.code)
    if (pasIndex) Vue.set(pasIndex, 'modifiedValues', passenger)
  },

  TOGGLE_SINGLE_PASSENGER(state, { passenger, tripIndex, index }) {
    const tripToUpdate = _get(state, `bookingRecall.trips.${tripIndex}`)
    if (tripToUpdate) {
      const pasIndex = _get(tripToUpdate, 'passengers', []).find((p, indx) => p?.accommodation?.idOrCode === passenger?.accommodation?.idOrCode && indx == index)
      if (pasIndex) Vue.set(pasIndex, 'selected', !pasIndex.selected)
    }
  },
  TOGGLE_SINGLE_SERVICE(state, {  tripIndex, serviceIndex , addSerIndex }) {
    const tripToUpdate = _get(state, `bookingRecall.trips.${tripIndex}`)
    if (tripToUpdate) {
      const serviceToUpdate = _get(
        tripToUpdate,
        `additionalServices[${addSerIndex}].items[${serviceIndex}]`,
        null
      );
      if (serviceToUpdate) {
        Vue.set(serviceToUpdate, 'selected', !serviceToUpdate.selected);
      }

    }
  },
  TOGGLE_SINGLE_VEHICLE(state, { vehicle, tripIndex }) {
    const tripToUpdate = getTrip(state, tripIndex)
    if (tripToUpdate) {
      const vehIndex = tripToUpdate.vehicles.find(v => v.plateNumber === vehicle.plateNumber && _isEqual(v?.accommodation, vehicle?.accommodation) && v.driverSurname === vehicle.driverSurname)

      if (vehIndex) Vue.set(vehIndex, 'selected', !vehIndex.selected)
    }
  },
  TOGGLE_SINGLE_PACKAGE(state, { packageItem, tripIndex }) {
    const tripToUpdate = getTrip(state, tripIndex)
    if (tripToUpdate) {
      const packIndex = tripToUpdate.entities.find(v => v.ticket.qrCode === packageItem.ticket.qrCode)
      if (packIndex) Vue.set(packIndex, 'selected', !packIndex.selected)
    }
  },
  TOGGLE_SELECT_TRIP(state, { trip, tripIndex, toggle }) {
    const tripToUpdate = getTrip(state, tripIndex)
    if (tripToUpdate) {
      const areAllSelected = trip => trip.passengers.concat(trip.vehicles).every(t => t.selected)
      toggle = toggle || !areAllSelected(trip)
      Vue.set(tripToUpdate, 'selectedTrip', toggle)
      tripToUpdate.passengers.forEach(p => Vue.set(p, 'selected', toggle))
      tripToUpdate.vehicles.forEach(v => Vue.set(v, 'selected', toggle))
      if (trip.additionalServices[0])
        tripToUpdate.additionalServices[0].items.forEach(s => Vue.set(s, 'selected', toggle))
    }
  },
  TOGGLE_ALL_PASSENGERS(state, { tripIndex, toggle }) {
    updateTripPassengers(getTrip(state, tripIndex))
  },
  TOGGLE_ALL_BOOKING(state, { trips, toggle }) {
    trips.forEach(trip => {
      const areAllSelected = trip => trip.passengers.concat(trip.vehicles).every(t => t.selected)
      toggle = toggle || !areAllSelected(trip)
      trip.passengers.forEach(p => Vue.set(p, 'selected', toggle))
      trip.vehicles.forEach(v => Vue.set(v, 'selected', toggle))
      if (trip.additionalServices[0]) trip.additionalServices[0].items.forEach(s => Vue.set(s, 'selected', toggle))
    })
  },
  TOGGLE_ALL_VEHICLES(state, { tripIndex, toggle }) {
    updateTripVehicles(getTrip(state, tripIndex))
  },
  TOGGLE_ALL_PACKAGES(state, { tripIndex, toggle }) {
    updateTripPackages(getTrip(state, tripIndex))
  },

  TOGGLE_ALL_SERVICES(state, { tripIndex, toggle }) {
    updateTripServices(getTrip(state, tripIndex))
  },

  UPDATE_TRIP_PASSENGER(state, { trip, passenger, passengerIndex }) {
    const tripToUpdate = findOneBy('bookingRecall.trips', tripId)(state)(trip)

    const selectedPassengers = _get(tripToUpdate, 'passengers')
    if (tripToUpdate && selectedPassengers) Vue.set(selectedPassengers, passengerIndex, passenger)
  },
  UPDATE_TRIP_PACKAGE(state, { trip, passenger, passengerIndex }) {
    const tripToUpdate = findOneBy('bookingRecall.trips', tripId)(state)(trip)

    const selectedPackages = _get(tripToUpdate, 'entities')
    if (tripToUpdate && selectedPackages) Vue.set(selectedPackages, passengerIndex, passenger)
  },

  UPDATE_TRIP_VEHICLE(state, { trip, vehicle, vehicleIndex }) {
    const tripToUpdate = findOneBy('bookingRecall.trips', tripId)(state)(trip)

    const selectedVehicles = _get(tripToUpdate, 'vehicles')
    if (tripToUpdate && selectedVehicles) Vue.set(selectedVehicles, vehicleIndex, vehicle)
  },

  UPDATE_TRIP: updateOrCreateListItemByIndex('bookingRecall.trips'),

  ADD_BOOKING_ACTIONS: set('actions'),

  SET_MODIFIED_PASSENGERS: set('modifiedBookingPassengers')
}

export const actions = {

  setBookingRecallAction({ commit }, { res, isIssued = false }) {
    commit('SET_BOOKING_RECALL_TICKETS', { res, isIssued });
  },

  resetBookingRecall({ commit }) {
    commit('SET_BOOKING_RECALL', {})
    commit('SET_MODIFIED_PASSENGERS', {})
  },
  setLegLength({ commit, state }, payload) {
    commit('SET_LEG_LENGTH', payload)
  },
  setAvailableServices({ commit, state }, payload) {
    commit('SET_AVAILABLE_SERVICES', payload)
  },
  allowActionsPerPassenger({ rootGetters }, { pnr }) {
    const createParams = data => ({
      params: {
        pnr
      },
      token: token(rootGetters)
    })
    const handleAction = pipeP(createParams, allowedActions)
    return handleAction()
  },
  addBookingActions({ commit }, payload) {
    commit('ADD_BOOKING_ACTIONS', payload)
  },
  modifyBookingAction({ rootGetters, commit }, { trips, crsReservationCode, leader, dryRun, optionDateTime }) {
    // Πραγματοποιούμε την αλλαγή στο όνομα του πεδίου loyaltyCard σε loyaltyNumber
    trips.forEach(trip => {
      trip.passengers.forEach(passenger => {
          if (passenger.hasOwnProperty('loyaltyCard')) {
              passenger.loyaltyNumber = passenger.loyaltyCard;
              delete passenger.loyaltyCard;
          }
      });
    });

    const requestData = () => ({
      data: ModifyBookingDTO({
        trips,
        leader,
        crsReservationCode,
      }),
      token: token(rootGetters)
    })
    const handleAction = pipeP(requestData, modifyBooking, log('Modify booking'))
    return handleAction()
  },
  changeOptionDateTimeAction({ rootGetters, commit }, { crsReservationId, newOptionDateTime, newAgentCode }) {
    const requestData = () => ({
      data: {
        crsReservationId,
        newOptionDateTime,
        newAgentCode,
      },
      token: token(rootGetters)
    })
    const handleAction = pipeP(requestData, changeOptionDateTime, log('Change optional time'))
    return handleAction()
  },
  toggleSinglePassenger({ commit }, { passenger, tripIndex, index }) {
    commit('TOGGLE_SINGLE_PASSENGER', {
      passenger,
      tripIndex,
      index
    })
  },
  toggleSingleService({ commit }, { tripIndex, serviceIndex , addSerIndex }) {
    commit('TOGGLE_SINGLE_SERVICE', {
      tripIndex,
      serviceIndex,
      addSerIndex
    })
  },
  toggleSingleVehicle({ commit }, { vehicle, tripIndex }) {
    commit('TOGGLE_SINGLE_VEHICLE', {
      vehicle,
      tripIndex
    })
  },
  toggleSinglePackage({ commit }, { packageItem, tripIndex }) {
    commit('TOGGLE_SINGLE_PACKAGE', {
      packageItem,
      tripIndex
    })
  },
  addSelectedTrip({ commit }, { trip, tripIndex }) {
    commit('TOGGLE_SELECT_TRIP', {
      trip,
      tripIndex
    })
  },
  toggleAllPassengers({ commit }, { tripIndex, toggle }) {
    commit('TOGGLE_ALL_PASSENGERS', {
      tripIndex,
      toggle
    })
  },
  toggleAllVehicles({ commit }, { tripIndex }) {
    commit('TOGGLE_ALL_VEHICLES', {
      tripIndex
    })
  },
  toggleAllPackages({ commit }, { tripIndex }) {
    commit('TOGGLE_ALL_PACKAGES', {
      tripIndex
    })
  },
  toggleAllServices({ commit }, { tripIndex }) {
    commit('TOGGLE_ALL_SERVICES', {
      tripIndex
    })
  },
  toggleAllBooking({ commit }, { trips }) {
    commit('TOGGLE_ALL_BOOKING', {
      trips
    })
  },
  convertTicketToOpen({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiConvertBookingToOpen(data)
    const tap = data => {
      return data
    }
    // const recallBooking = id =>
    //   dispatch(
    //     'booking-search/getSpecificBookingById',
    //     {
    //       crsReservationId: state.bookingRecall.crsReservationId
    //     },
    //     {
    //       root: true
    //     }
    //   )
    const handleAction = pipeP(createRequestParams, actionToApi, log('Convert to open'), tap)
    return handleAction(payload)
  },

  convertTicketFromOptional({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => issueFromOptional(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, log(payload.pta ? 'Issue PTA' : 'Issue from recall'), tap)
    return handleAction(payload)
  },

  convertTicketFromOptionalGroup({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => issueFromOptionalGroup(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, log(payload.pta ? 'Issue PTA' : 'Issue tickets'), tap)
    return handleAction(payload)
  },

  convertTicketFromPta({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => issueFromPta(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, log('Issue PTA'), tap)
    return handleAction(payload)
  },

  convertTicketToOptional({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiConvertBookingToOptional(data)
    const tap = data => {
      return data
    }
    const handleAction = pipeP(createRequestParams, actionToApi, log('Ticket to optional'), tap)
    return handleAction(payload)
  },
  convertTicketToIssued({ dispatch, state, rootGetters }, payload) {
    const createRequestParams = () => ({
      data: { ...payload, paymentMethods: rootGetters['book-ticket/getAvailableBookingPaymentMethods'] },
      token: token(rootGetters)
    })
    const actionToApi = data => issueBooking(data)
    const tap = data => {
      return data
    }
    const recallBooking = id =>
      dispatch(
        'booking-search/getSpecificBookingById',
        {
          crsReservationId: state.bookingRecall.crsReservationId
        },
        {
          root: true
        }
      )
    const handleAction = pipeP(createRequestParams, actionToApi, tap, recallBooking)
    return handleAction(payload)
  },
  cancelBookingOnRecall({ rootGetters, dispatch, state }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiBookingCancel(data)
    const tap = data => {
      return data
    }

    const handleAction = pipeP(createRequestParams, actionToApi, log('cancel-booking'), tap)
    return handleAction(payload)
  },
  issueGroupBooking({ rootGetters, dispatch, state }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiBooking(data)
    const tap = data => {
      return data
    }

    const handleAction = pipeP(createRequestParams, actionToApi, log('group-booking'), tap)
    return handleAction(payload)
  },
  splitBooking({ rootGetters, dispatch, state }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiSplitBooking(data)
    const tap = data => {
      return data
    }
    const recallBooking = data => {
      dispatch(
        'booking-search/getSpecificBookingById',
        {
          crsReservationIds: data[1].crsReservationId
        },
        {
          root: true
        }
      )
      return data
    }

    const handleAction = pipeP(createRequestParams, actionToApi, tap, recallBooking, log('Split booking'))
    return handleAction(payload)
  },
  mergeBookings({ rootGetters, dispatch, state }, payload) {
    const createRequestParams = () => ({
      data: payload,
      token: token(rootGetters)
    })
    const actionToApi = data => apiMergeBookings(data)
    const tap = data => {
      return data
    }
    // const recallBooking = data => {
    //   dispatch(
    //     'booking-search/getSpecificBookingById',
    //     {
    //       crsReservationIds: state.bookingRecall.crsReservationId
    //     },
    //     {
    //       root: true
    //     }
    //   )
    //   return data
    // }

    const handleAction = pipeP(createRequestParams, actionToApi, log('merge-bookings'), tap)
    return handleAction(payload)
  },
  getModifiableAttributes({ commit, rootGetters }, { trip, tripIndex }) {
    const createRequestParams = () => ({
      data: trip,
      token: token(rootGetters)
    })
    const modAttributes = attributes =>
      attributes.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: {
            ...rootGetters['book-ticket/getAttributeById'](item.id),
            value: ''
          }
        }),
        {}
      )
    const actionToApi = data => modifiableAttributes(data)
    const handleAction = pipeP(createRequestParams, actionToApi)
    handleAction(trip)
      .then(data => {
        const modAttrs = modAttributes(data?.attributes || [])

        const config = Object.keys(data.config).reduce(
          (conf, status) => ({
            ...conf,
            [status]:
              typeof data.config.CHECKEDIN === 'object' && data.config.CHECKEDIN.attributes
                ? {
                  ...modAttributes(data.config[status].attributes),
                  canModifyProduct: data?.config[status]?.canModifyProduct,
                  canModifyRoute: data?.config[status]?.canModifyRoute
                }
                : data.config[status]
          }),
          {}
        )

        const pModAttr = modAttrs // .filter(a => a.type === 'Passenger')
        // const vModAttr = modAttrs.filter(a => a.type === 'Vehicle')

        const getPassengerModifyAttributes = p => _get(config, [p?.ticket?.status]) || pModAttr

        const passengers = trip.passengers.map(p => ({
          ...p,
          modifyAttributes: p.attributes
            .filter(a => getPassengerModifyAttributes(p)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(p)[attr.id],
                  value:
                    attr.values.length === 1
                      ? rootGetters['book-ticket/getAvailableAttributes'][attr.id]?.values?.find(
                        v => v.id === attr.values[0].id
                      )?.value
                      : attr.value,
                  values:
                    attr.values.length === 1
                      ? [
                        rootGetters['book-ticket/getAvailableAttributes'][attr.id]?.values?.find(
                          v => v.id === attr.values[0].id
                        )
                      ]
                      : []
                },
                canModifyProduct: getPassengerModifyAttributes(p).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(p).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(p)
              }
            )
        }))
        const vehicles = trip.vehicles.map(v => ({
          ...v,
          modifyAttributes: v.attributes
            .filter(a => getPassengerModifyAttributes(v)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(v)[attr.id],
                  value: attr.value
                },
                canModifyProduct: getPassengerModifyAttributes(v).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(v).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(v)
              }
            )
        }))
        const entities = trip.entities.map(e => ({
          ...e,
          modifyAttributes: e.attributes
            .filter(a => getPassengerModifyAttributes(e)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(e)[attr.id],
                  value: attr.value
                },
                canModifyProduct: getPassengerModifyAttributes(e).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(e).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(e)
              }
            )
        }))
        commit('UPDATE_TRIP', {
          index: tripIndex,
          value: {
            passengers,
            vehicles,
            entities,
            modifiableAttributes: modAttrs
          }
        })
      })
      .catch(console.log)
  },
  getModifiableAttributesCheckIn({ commit, rootGetters }, { trip, tripIndex }) {
    const createRequestParams = () => ({
      data: trip,
      token: token(rootGetters)
    })
    const modAttributes = attributes =>
      attributes.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: {
            ...rootGetters['book-ticket/getAttributeById'](item.id),
            value: ''
          }
        }),
        {}
      )
    const actionToApi = data => modifiableAttributes(data)
    const handleAction = pipeP(createRequestParams, actionToApi)
    handleAction(trip)
      .then(data => {
        const modAttrs = modAttributes(data?.attributes || [])

        const config = Object.keys(data.config).reduce(
          (conf, status) => ({
            ...conf,
            CHECKEDIN:
              typeof data.config.CHECKEDIN === 'object' && data.config.CHECKEDIN.attributes
                ? {
                  ...modAttributes(data.config.CHECKEDIN.attributes),
                  canModifyProduct: data?.config.CHECKEDIN?.canModifyProduct,
                  canModifyRoute: data?.config.CHECKEDIN?.canModifyRoute
                }
                : data.config.CHECKEDIN
          }),
          {}
        )

        const pModAttr = modAttrs // .filter(a => a.type === 'Passenger')
        // const vModAttr = modAttrs.filter(a => a.type === 'Vehicle')

        const getPassengerModifyAttributes = p => _get(config, ['CHECKEDIN']) || pModAttr

        const passengers = trip.passengers.map(p => ({
          ...p,
          modifyAttributes: p.attributes
            .filter(a => getPassengerModifyAttributes(p)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(p)[attr.id],
                  value:
                    attr.values.length === 1
                      ? rootGetters['book-ticket/getAvailableAttributes'][attr.id]?.values?.find(
                        v => v.id === attr.values[0].id
                      )?.value
                      : attr.value,
                  values:
                    attr.values.length === 1
                      ? [
                        rootGetters['book-ticket/getAvailableAttributes'][attr.id]?.values?.find(
                          v => v.id === attr.values[0].id
                        )
                      ]
                      : []
                },
                canModifyProduct: getPassengerModifyAttributes(p).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(p).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(p)
              }
            )
        }))
        const vehicles = trip.vehicles.map(v => ({
          ...v,
          modifyAttributes: v.attributes
            .filter(a => getPassengerModifyAttributes(v)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(v)[attr.id],
                  value: attr.value
                },
                canModifyProduct: getPassengerModifyAttributes(v).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(v).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(v)
              }
            )
        }))
        const entities = trip.entities.map(e => ({
          ...e,
          modifyAttributes: e.attributes
            .filter(a => getPassengerModifyAttributes(e)[a.id])
            .reduce(
              (_modAttrs, attr) => ({
                ..._modAttrs,
                [attr.id]: {
                  ...getPassengerModifyAttributes(e)[attr.id],
                  value: attr.value
                },
                canModifyProduct: getPassengerModifyAttributes(e).canModifyProduct,
                canModifyRoute: getPassengerModifyAttributes(e).canModifyRoute
              }),
              {
                ...getPassengerModifyAttributes(e)
              }
            )
        }))
        commit('UPDATE_TRIP', {
          index: tripIndex,
          value: {
            passengers,
            vehicles,
            entities,
            modifiableAttributes: modAttrs
          }
        })
      })
      .catch(console.log)
  },
  updateGroupBooking({ commit, rootGetters }, { trips, company_abbreviation, cmp_reservation_code }) {
    const requestData = () => ({
      data: UpdateGroupBodyDTO({
        trips,
      }),
      company_abbreviation,
      cmp_reservation_code,
      companies: rootGetters['book-ticket/getCompanies'],
      token: token(rootGetters)
    })
    // const log = (t) => {
    //   console.log(111, t)
    // } 
    const handleAction = pipeP(requestData, apiUpdateGroupBookings)
    return handleAction()
  }
}
