<template>
  <nuxt />
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.setBrowserInfo({ isTouch: this.isTouch() })
  },
  methods: {
    ...mapActions({ setBrowserInfo: 'setBrowserInfo' }),
    isTouch() {
      return 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    }
  }
}
</script>
