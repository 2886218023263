import * as Sentry from "@sentry/browser";
import * as SentryVue from "@sentry/vue"; // Import Sentry Vue integration
import Vue from "vue"; // Ensure you're importing Vue

SentryVue.init({
  Vue, // Vue instance
  dsn: process.env.SENTRY_DSN,
  release: `agency-x@${process.env.npm_package_version}`,
  environment: process.env.SENTRY_ENV || "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      unmask: ["[data-sentry-unmask]"]
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["https://agxuat.liknoss.com"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  attachProps: true, // Attach Vue component props to errors

  // Filter errors using beforeSend callback
  beforeSend(event) {
    // Ignore hot-update related errors
    if (event.message && event.message.includes("hot-update.json")) {
      return null;
    }

    // Otherwise, send the error to Sentry
    return event;
  }
});

if (!process.env.SENTRY_ENV) {
  SentryVue.setUser({
    username: process.env.SENTRY_USER_USERNAME,
    email: process.env.SENTRY_USER_EMAIL,
    // You can add any custom fields here as well
    role: process.env.SENTRY_USER_ROLE,
    department: process.env.SENTRY_USER_DEPARTMENT
  });
}

// Capture Vue warnings as errors and send them to Sentry
Vue.config.warnHandler = function(msg, vm, trace) {
  Sentry.captureException(new Error(`[Vue warn]: ${msg}${trace}`));
};
