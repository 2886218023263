<template>
  <div 
  

  v-shortkey="getFormattedShortcut('Save_Booking')" 
  class="big-blue-button" @click="onClickSave" @shortkey="onClickSave">
    <div v-if="visible" v-shortkey="['esc']" @shortkey="() => (visible = !visible)" />
    <slot>
      <a-button v-if="!getValidateCoupon " class="fw-bold save-button">
        <img class="btn-img" src="@/assets/img/icons/save.svg"/>
        {{ $t('save') }}
      </a-button>
    </slot>
    <a-modal v-model="visible" @ok="handleOk">
      <div slot="title" style="font-size: 2rem; color: black;">
        {{ $t('booking_title') }}
      </div>
      <a-input :value="title || savedStateName" @change="onChangeTitle" />
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          {{ $t('cancel') }}
        </a-button>
        <a-button class="saveButton" :loading="loading" @click="handleOk" >
          {{ $t('save') }}
        </a-button>
      </template>
    </a-modal>
  </div>

</template>

<script>
import { mapActions, mapState ,mapGetters } from 'vuex'
import { Message } from 'element-ui';
import { EventBus } from "@/plugins/event-bus";

export default {
  data() {
    return {
      visible: false,
      title: '',
      loading: false
    }
  },
  props:{
    open: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    this.visible = this.open
    
    // Create Save Booking Event
    if (!EventBus._events["Save_Booking"]) {
      EventBus.$on("Save_Booking", () => {
        this.onClickSave();
      });
    }
  },
  beforeDestroy() {
    // Destroy Save Booking Event
    EventBus.$off("Save_Booking");
  },
  computed: {
    ...mapState('book-ticket', {
      savedStateName: 'savedStateName'
    }),
    ...mapGetters({
      getValidateCoupon :'book-ticket/getValidateCoupon',
      getShortcutsFormatted: "settings/getShortcutsFormatted",
      getNotificationCloseStatus: 'settings/getNotificationCloseStatus',
    }),
  },
  methods: {
    ...mapActions({
      saveCurrentStateToStorage: 'book-ticket/saveCurrentStateToStorage',
      loadSavedState: 'book-ticket/loadSavedState'
    }),
    getFormattedShortcut(action) {
      const shortcutFormatted = this.getShortcutsFormatted;
      return shortcutFormatted && shortcutFormatted[action] ? shortcutFormatted[action].keys.split(',') : null;
    },
    onChangeTitle(ev) {
      this.title = ev.target.value
    },
    onClickSave() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      if (this.title !== '') {
        this.saveCurrentStateToStorage({ name: this.title })
        this.visible = false
        Message({
          message: 'Saved current options',
          type: 'success',
          showClose: this.getNotificationCloseStatus ? true : false,
          duration: this.getNotificationCloseStatus ? 1000000 : 3000
        });
        this.$emit('closeSave')
      } else {   
        Message({
          message: 'You must provide a name',
          type: 'error',
          showClose: this.getNotificationCloseStatus ? true : false,
          duration: this.getNotificationCloseStatus ? 1000000 : 3000
        });
      }
    }
  },
  watch:{
    visible() {
      // Destroy Save Booking Event
      if (this.visible) {
        EventBus.$off("Save_Booking");
      // Create Save Booking Event
      } else {        
        EventBus.$on("Save_Booking", () => {
          this.onClickSave();
        });
      }
    }
  }
}
</script>

<style lang="scss">
.save-button {
  border-color: rgb(111, 111, 111);
}

.saveButton {
  background: transparent;
  color: var(--main-color);
  border: solid 1px var(--main-color);
  &:hover {
    background: var(--main-color);
    color: white;
  }
}
.btn-img{
  margin: 0 3px;
  width: 17px !important;
  padding: 0px 3px;
}
</style>
