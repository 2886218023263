import _get from 'lodash/get'
import _set from 'lodash/set'
import { isObject } from './validations'
const createFilter = (keys, value) => keys.reduce((_filter, key) => ({ ..._filter, [key]: _get(value, key) }), {})
const itemHasFilterValues = (filter, item) =>
  Object.entries(filter).every(([path, fValue]) => _get(item, path) === fValue)
/**
 * --------------
 * Getters
 * --------------
 */

/**
 * Creates a getter for vuex
 * Usage:
 * state: {
 *   users: [
 *      {
 *        name: 'example'
 *      }
 *   ]
 * }
 *
 * getters: {
 *  getUsers: get('users')
 * }
 *
 * this.$store.getters.getUsers
 * @param { String } path - Path to state property
 */
export const get = path => state => _get(state, path)

/**
 * Creates a getter for vuex
 * Usage:
 * state: {
 *   additionalServices: [
 *      {
 *        ...
 *        items: [
            {
              ...
              availableUnits: 1
            },
            {
              ...
              availableUnits: 2
            },
          ]
 *      },
 *   ]
 * }
 *
 * getters: {
 *  getServicesNumber: servicesTotalNumber('bookingConfigurations.additionalServices')
 * }
 *
 * this.$store.getters.servicesTotalNumber
 * @param { String } path - Path to state property
 */

export const servicesTotalNumber = path => state =>
  _get(state, path, []).map(service => service.items.reduce((_number, item) => (_number += item.availableUnits), 0))[0]

/**
 * Creates a getter for vuex
 * Usage:
 * state: {
 *   passengerCombinations: [
 *      {
 *        ...
 *        number: 0
 *      },
 *      {
 *        ...
 *        number: 2
 *      }
 *   ]
 * }
 *
 * getters: {
 *  getPassCombinations: combinationsTotal('passengerCombinations')
 * }
 *
 * this.$store.getters.getPassCombinations
 * @param { String } path - Path to state property
 */

export const combinationsTotal = path => state =>
  _get(state, path).reduce((_total, item) => {
    return (_total += item.number)
  }, 0)

/**
 * Creates a getter for vuex
 * Usage:
 * state: {
 *   user: {
 *        name: 'example'
 *      }
 * }
 *
 * getters: {
 *  getUser: getOr('user', { name: '' })
 * }
 *
 * this.$store.getters.getUser
 * @param { String } path - Path to state property
 * @param { any } defaultValue - Default value if none found
 */
export const getOr = (path, defaultValue) => state => _get(state, path, defaultValue)

/**
 * Creates a getter for vuex
 * Filter an array of objects by a filter object
 * Usage:
 * state: {
 *   users: [
 *      {
 *        name: 'example',
 *        country: 'Gr'
 *      },
 *      {
 *        name: 'example2',
 *        country: 'Us'
 *      }
 *   ]
 * }
 *
 * getters: {
 *  getUsersWhere: findMany('users')
 * }
 *
 * this.$store.getters.getUsersWhere({ country: 'Gr' }) // returns: [ { name: 'example', country: 'Gr' } ]
 * @param { String } path - Path to state property
 */
export const findMany = path => state => filter => {
  return _get(state, path, []).filter(item => {
    return !itemHasFilterValues(filter || {}, item)
  })
}

/**
 * Creates a getter for vuex
 * Find one item from an array of objects by a filter object
 * Usage:
 * state: {
 *   users: [
 *      {
 *        name: 'example',
 *        country: 'Gr'
 *      },
 *      {
 *        name: 'example2',
 *        country: 'Us'
 *      }
 *   ]
 * }
 *
 * getters: {
 *  getUserWhere: findOne('users')
 * }
 *
 * this.$store.getters.getUsersWhere({ country: 'Gr' }) // returns: { name: 'example', country: 'Gr' } | null
 * @param { String } path - Path to state property we want to get value
 */
export const findOne = path => state => filter => {
  return _get(state, path, []).find(item => {
    return itemHasFilterValues(filter, item) // Object.entries(filter).every(([fValue, path]) => _get(item, path) === fValue)
  })
}

export const findOneBy = (path, keys) => state => filter => {
  return findOne(path)(state)(createFilter(keys, filter))
  // return _get(state, path, []).find(item => {
  //   return itemHasFilterValues(createFilter(keys, filter), item) // Object.entries(filter).every(([fValue, path]) => _get(item, path) === fValue)
  // })
}

export const existInListBy = (path, keys) => state => value => !!findOne(path)(state)(createFilter(keys, value))

/**
 * Creates a getter for vuex
 * Find one item from an array of objects by a filter object or return a default value
 * Usage:
 * state: {
 *   users: [
 *      {
 *        name: 'example',
 *        country: 'Gr'
 *      },
 *      {
 *        name: 'example2',
 *        country: 'Us'
 *      }
 *   ]
 * }
 *
 * getters: {
 *  getUserWhere: findOneOr('users', { name: 'default' })
 * }
 *
 * this.$store.getters.getUserWhere({ country: 'Gr' }) // returns: { name: 'example', country: 'Gr' } | { name: 'default' }
 * @param { String } path - Path of state property we want to get value
 * @param { any } defaultValue - Default value if none was found
 */
export const findOneOr = (path, defaultValue) => state => filter => findOne(path)(state)(filter) || defaultValue

/**
 * -----------------
 * Setters mutations
 * -----------------
 */

/**
 * Creates a mutation for vuex
 * Used for setting a property
 * Sets the property based on path or creates the path if it doesnt exist
 *
 *
 * Usage:
 * state: {
 *   user: { name: 'Test', lastName: 'Last' }
 * }
 *
 * mutations: {
 *  setUser: set('user'),
 *  setUserLastName: set('user.lastName')
 * }
 *
 * this.$store.commit('setUser', { name: 'changed' })
 * state is:
 * state: {
 *   user: {
 *    name: 'changed'
 *   }
 * }
 *
 * this.$store.commit('setUserLastName', 'changed')
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'changed'
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const set = path => (state, value) => _set(state, path, value)

/**
 * Creates a mutation for vuex
 * Used for updating a property
 * Updates the property based on path if it is object
 * or sets the value to path if it doesnt exist
 *
 *
 * Usage:
 * state: {
 *   user: { name: 'Test', lastName: 'Last' },
 *   date: '2020-03-03'
 * }
 *
 * mutations: {
 *  updateUser: updateOrSet('user'),
 *  updateDate: updateOrSet('date')
 * }
 *
 * this.$store.commit('updateUser', { name: 'changed' })
 * state is:
 * state: {
 *   user: {
 *    name: 'changed',
 *    lastName: 'Last'
 *   }
 * }
 *
 * this.$store.commit('updateDate', 'changed: 2020-04-04')
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'changed'
 *   },
 *   date: 'changed: 2020-04-04'
 * }
 * @param { String } path - The path to the property we want to set
 */
export const updateOrSet = path => (state, value) => {
  const valToSet = _get(state, path)
  if (isObject(valToSet)) Object.assign(valToSet, value)
  else _set(state, value)
}

/**
 * Creates a mutation for vuex
 * Used for pushing an item to array
 * Updates the property based on path if it is array it pushes the item
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: []
 *   }
 * }
 *
 * mutations: {
 *  addUserAddress: addToList('user.addresses')
 * }
 *
 * this.$store.commit('addUserAddress', { number: '123', country: 'Greece' })
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { number: '123', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const addToList = path => (state, value) => {
  const ar = _get(state, path)
  if (Array.isArray(ar)) ar.push(value)
}

/**
 * Creates a mutation for vuex
 * Used for pushing an item to array
 * Updates the property based on path if it is array it pushes the item
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: []
 *   }
 * }
 *
 * mutations: {
 *  addUserAddress: addToList('user.addresses')
 * }
 *
 * this.$store.commit('addUserAddress', { number: '123', country: 'Greece' })
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { number: '123', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */

/**
 * Creates a mutation for vuex
 * Used for removing an item from array
 * Updates the property based on path
 * if it is array it removes the item by supplied index
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { number: '123', country: 'Greece' } ]
 *   }
 * }
 *
 * mutations: {
 *  removeUserAddress: removeFromList('user.addresses')
 * }
 *
 * this.$store.commit('removeUserAddress', 0)
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const removeFromList = path => (state, index) => {
  const ar = _get(state, path)
  if (Array.isArray(ar)) ar.splice(index, 1)
}

/**
 * Creates a mutation for vuex
 * Used for removing an item from array
 * Updates the property based on path
 * if it is array it removes the item by supplied filter object
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'home', number: '123', country: 'Greece' }, { name: 'work', number: '33', country: 'Greece' }  ]
 *   }
 * }
 *
 * mutations: {
 *  removeUserAddressWhere: removeFromListWithFilter('user.addresses')
 * }
 *
 * this.$store.commit('removeUserAddressWhere', { name: 'home' })
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'work', number: '33', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const removeFromListWithFilter = path => (state, filter) => {
  _set(state, path, findMany(path)(state)(filter))
}

/**
 * Creates a mutation for vuex
 * Used for removing items from array
 * Updates the property based on path
 * if it is array it removes items from array based on the supplied object
 * it removes the items that all itemkeys are equal with supplied object[keys]
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'home', number: '123', country: 'Greece' }, { name: 'work', number: '33', country: 'Greece' }  ]
 *   }
 * }
 *
 * mutations: {
 *  removeUserAddressWhereName: removeFromListBy('user.addresses', 'name')
 * }
 *
 * now we can supply all the address object if we want
 * it will check only for the property 'name'
 * and will remove it from list if it is the same with the supplied object:
 *
 * this.$store.commit('removeUserAddressWhereName', { name: 'home', number: '123', country: 'Greece' })
 * state is:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'work', number: '33', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 * @param { [ String ] } keys - Object properties to check for filtering
 */
export const removeFromListBy = (path, keys) => (state, value) => {
  _set(state, path, findMany(path)(state)(createFilter(keys, value)))
}

/**
 * Creates a mutation for vuex
 * Used for updating an item from array
 * Updates the property based on path
 * if it is array or object it updates the item by supplied index
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'home', number: '123', country: 'Greece' }, { name: 'work', number: '33', country: 'Greece' }  ]
 *   }
 * }
 *
 * mutations: {
 *  updateAddresByIndex: removeFromListWithFilter('user.addresses')
 * }
 *
 * this.$store.commit('updateAddresByIndex', { index: 0, { number: 'changed' } })
 * state becomes:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'home', number: 'changed', country: 'Greece' }, { name: 'work', number: '33', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const updateOrCreateListItemByIndex = path => (state, payload) => {
  const { index, value } = payload
  const list = _get(state, path)
  const item = _get(list, index)
  if (isObject(item)) Object.assign(item, value)
  else if (isObject(list)) _set(list, index, value)
}

/**
 * Creates a mutation for vuex
 * Used for replacing an item from array
 * Updates the property based on path
 * if it is array or object it replaces the item by supplied index
 * else it does nothing
 *
 *
 * Usage:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { name: 'home', number: '123', country: 'Greece' }, { name: 'work', number: '33', country: 'Greece' }  ]
 *   }
 * }
 *
 * mutations: {
 *  replaceAddresByIndex: replaceOrCreateListItemByIndex('user.addresses')
 * }
 *
 * this.$store.commit('replaceAddresByIndex', { index: 0, { number: 'changed' } })
 * state becomes:
 * state: {
 *   user: {
 *    name: 'Test',
 *    lastName: 'Last',
 *    addresses: [ { number: 'changed' }, { name: 'work', number: '33', country: 'Greece' } ]
 *   }
 * }
 * @param { String } path - Path to state property we want to set
 */
export const replaceOrCreateListItemByIndex = path => (state, payload) => {
  const { index, value } = payload
  const list = _get(state, path)
  const item = _get(list, index)
  if (isObject(item)) Object.assign(item, value)
  else if (isObject(list)) _set(list, index, value)
}

export const replaceOrCreateListItemByFilter = path => (state, payload) => {
  const { filter, value } = payload
  const list = _get(state, path)
  const item = findOne(path)(state)(filter)
  if (isObject(item)) Object.assign(item, value)
  else list.push && list.push(value)
}

export const updateOrCreateListItemByFilter = path => (state, payload) => {
  const { filter, value } = payload
  const list = _get(state, path)
  const item = findOne(path)(state)(filter)
  if (isObject(item)) Object.assign(item, value)
  else list.push && list.push(value)
}

export const updateOrCreateListItemBy = (path, keys) => (state, value) => {
  const filter = createFilter(keys, value) // keys.reduce((_filter, key) => ({ ..._filter, [key]: value[key] }), {})
  const list = _get(state, path)
  const valToUpdate = findOne(path)(state)(filter)
  if (isObject(valToUpdate)) Object.assign(valToUpdate, value)
  else if (Array.isArray(list)) list.push(valToUpdate)
}

export const setListItemPath = (pathOfList, keys) => (state, { itemToUpdate, value, path }) => {
  const filter = createFilter(keys, itemToUpdate) // keys.reduce((_filter, key) => ({ ..._filter, [key]: value[key] }), {})
  // const list = _get(state, pathOfList)
  const valToUpdate = findOne(pathOfList)(state)(filter)
  if (isObject(valToUpdate)) _set(valToUpdate, path, value)
}

export const replaceOrCreateListItemBy = (path, keys) => (state, value) => {
  // const { filter, value } = payload
  const filter = createFilter(keys, value)
  const list = _get(state, path)
  const item = findOne(path)(state)(filter)
  if (isObject(item)) Object.assign(item, value)
  else list.push && list.push(value)
}

/**
 * Creates a mutation for vuex
 * Used for setting single items in an array by the index
 *
 * @param { String } path - The path where the array is
 *
 * Usage:
 * state: {
 *   users: [
 *      {
 *        name: 'example'
 *      }
 *   ]
 * }
 *
 * mutations: {
 *  setUserByIndex: setByIndex('users')
 * }
 *
 * this.$store.commit('setUserByIndex', { index: 0, value: { name: 'changed' } })
 */
// export const setByIndex = path => (state, payload) => {
//   const { index, value } = payload

//   const items = pathOr(null, [...path.split('.')], state)

//   if (items && items[index] && typeof items[index] === 'object') Object.assign(items[index], value)
//   else if (items && typeof items === 'object') items[index] = payload
// }

// push an item onto a list
// addItem: pushTo('items')
// export const pushTo = addToList

// remove item or object from list
export const removeByCallback = path => (state, callback) => {
  const list = _get(state, path)
  if (Array.isArray(list)) _set(state, path, list.filter(callback))
}

/**
 * -----------------
 * Other helpers
 * -----------------
 */

/**
 * Get the access token to use for api requests
 * @param { Object } rootGetters
 */
export const token = rootGetters => rootGetters['user/getAccessToken']
