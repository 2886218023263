export const priceWithComma = price =>
  parseFloat(price / 100)
    .toFixed(2)
    .replace('.', ',')

export const priceWithCommaThousand = price => {
  return priceWithComma(price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const timeFormatter = time =>
  parseFloat(time / 100)
    .toFixed(2)
    .replace('.', ':')


export const cmToM = cm => {
  return cm / 100
}